import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { NotifyUsers } from "../../store/actions/admin/settings";
import AppButton from "../button/Button";

const Notifications = ({ isActive, notify }) => {
  const [messageEn, setMessageEn] = useState("");
  const [messageRu, setMessageRu] = useState("");
  const { t } = useTranslation();
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!messageEn || !messageRu) {
      return;
    }
    notify(messageEn, messageRu);
  };
  return (
    <div className={`tab-pane ${isActive ? "active" : ""}`}>
      <form className="horizontal" onSubmit={handleSubmit}>
        <div className="row py-5">
          <div className="col-xl-12">
            <div className="col-xl-6 col-12 pl-0 pb-5">
              <label htmlFor="messageEn" className="labels">
                {t("notification.messageEn")}
              </label>
              <textarea
                id="messageEn"
                className="form-control"
                value={messageEn}
                onChange={(e) => setMessageEn(e.target.value)}
              />
            </div>
            <div className="col-xl-6 col-12 pl-0 pb-5">
              <label htmlFor="messageRu" className="labels">
                {t("notification.messageRu")}
              </label>
              <textarea
                id="messageRu"
                className="form-control"
                value={messageRu}
                onChange={(e) => setMessageRu(e.target.value)}
              />
            </div>
            <div className="col-xl-6 col-12 pl-0 pb-5">
              <AppButton type="submit" className="btn btn-primary">
                {t("notification.notify")}
              </AppButton>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    notify: (messageEn, messageRu) =>
      dispatch(NotifyUsers(messageEn, messageRu)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
