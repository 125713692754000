import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ZoneListFilter from "../../../components/filter/zoneListFilter";
import PageLoading from "../../../components/page-loading/PageLoading";
import { AdminZonesAction } from "../../../store/actions/admin/zones";

const AdminZones = ({
  data,
  current_page,
  per_page,
  total,
  isLoading,
  getZones,
}) => {
  const { t } = useTranslation();
  const [filterName, setFilterName] = useState("");
  const [filterEmail, setFilterEmail] = useState("");
  const [filterWebsite, setFilterWebsite] = useState("");
  const [filterStatus, setFilterStatus] = useState("");
  const [filterManager, setFilterManager] = useState(null);

  useEffect(() => {
    getZones(1);
  }, [getZones]);

  const zoneList = data.map((zone) => {
    return (
      <tr key={zone.id}>
        <td>
          <Link to={`/admin/zones/${zone.id}`}>{zone.id}</Link>
        </td>
        <td>{zone.created_at}</td>
        <td>
          <Link to={`/admin/zones/${zone.id}`}>{zone.name}</Link>
        </td>
        <td>
          <Link to={`/admin/websites/${zone.website_id}`}>{zone.website}</Link>
        </td>
        <td>{zone.fullname}</td>
        <td>{zone.manager}</td>
        <td>{zone.email}</td>
        <td>{zone.size}</td>
        <td>{zone.width}</td>
        <td>{zone.height}</td>
        <td>{t(`zone.statuses.${zone.status}`)}</td>
      </tr>
    );
  });
  let template;
  if (isLoading) {
    template = <PageLoading />;
  } else {
    template = (
      <>
        <div className="card">
          <div className="card-body table-responsive p-0">
            <table className="table table-striped table-valign-middle table-sm">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>{t("common.date")}</th>
                  <th>{t("zone.name")}</th>
                  <th>{t("common.website")}</th>
                  <th>{t("common.user")}</th>
                  <th>{t("website.label.manager")}</th>
                  <th>{t("common.email")}</th>
                  <th>{t("zone.size")}</th>
                  <th>{t("zone.width")}</th>
                  <th>{t("zone.height")}</th>
                  <th>{t("zone.status")}</th>
                </tr>
              </thead>
              <tbody>{zoneList}</tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
  return (
    <div className="container-fluid">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>{t("menusidebar.label.zones")}</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/admin">{t("header.label.home")}</Link>
                </li>
                <li className="breadcrumb-item active">
                  {t("menusidebar.label.zones")}
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <ZoneListFilter
        filterName={filterName}
        filterEmail={filterEmail}
        filterWebsite={filterWebsite}
        filterStatus={filterStatus}
        filterManager={filterManager}
        setFilterName={setFilterName}
        setFilterEmail={setFilterEmail}
        setFilterWebsite={setFilterWebsite}
        setFilterStatus={setFilterStatus}
        setFilterManager={setFilterManager}
        handleApply={getZones}
        isLoading={isLoading}
      />
      {template}
      {total > per_page && (
        <Pagination
          activePage={current_page ? current_page : 0}
          itemsCountPerPage={per_page ? per_page : 0}
          totalItemsCount={total ? total : 0}
          onChange={(pageNumber) => {
            getZones(
              pageNumber,
              filterName,
              filterEmail,
              filterWebsite,
              filterStatus,
              filterManager
            );
          }}
          pageRangeDisplayed={8}
          itemClass="page-item"
          linkClass="page-link"
          firstPageText={t("common.firstPage")}
          lastPageText={t("common.lastPage")}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  const { data, current_page, per_page, total, isLoading } = state.adminZones;
  return {
    data,
    current_page,
    per_page,
    total,
    isLoading,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getZones: (...filters) => dispatch(AdminZonesAction(...filters)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminZones);
