import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import AppButton from "../../../components/button/Button";
import { sourceTitles } from "../../../helpers";
import { currencies } from "../../../helpers/currency";
import { AdminSourcesAddAction } from "../../../store/actions/admin/sources";
import styles from "./index.module.scss";

const AddSource = ({ addSource }) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [type, setType] = useState("");
  const [percent, setPercent] = useState(0);
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [token, setToken] = useState("");
  const [cur, setCur] = useState("rub");
  const [error, setError] = useState(true);
  const [submited, setSubmited] = useState(false);
  const { t } = useTranslation();

  const handleChange = (event) => {
    let { value, min, max } = event.target;
    value = Math.max(Number(min), Math.min(Number(max), Number(value)));
    setPercent(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmited(true);
    if (!title || !type || percent < 0 || percent > 100) {
      setError(true);
      return;
    }
    addSource({
      title,
      description,
      type,
      percent,
      login,
      password,
      token,
      cur,
    });

    setTitle("");
    setDescription("");
    setType("");
    setPercent(0);
    setLogin("");
    setPassword("");
    setToken("");
    setCur("rub");
    setSubmited(false);
  };
  return (
    <div className="container">
      <div className="card m-3">
        <div className="card-header">
          <h5 className="card-title">{t("sources.add")}</h5>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label htmlFor="title">{t("sources.type")}</label>
              <select
                className={`form-control ${
                  error && submited && !type ? "is-invalid" : ""
                }`}
                id="title"
                value={type}
                onChange={(e) => setType(e.target.value)}
              >
                <option>{t("sources.type")}</option>
                {Object.keys(sourceTitles).map((key) => {
                  return (
                    <option key={key} value={key}>
                      {sourceTitles[key]}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="percent">{t("sources.percent")}</label>
              <input
                type="number"
                pattern="[0-9]*"
                className={`form-control ${
                  (error && submited && percent > 100) || percent < 0
                    ? "is-invalid"
                    : ""
                } ${styles}`}
                id="percent"
                placeholder={t("sources.percent")}
                value={percent}
                min="0"
                max="100"
                step="0.05"
                onChange={handleChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="title">{t("sources.title")}</label>
              <input
                type="text"
                className={`form-control ${
                  error && submited && !title ? "is-invalid" : ""
                }`}
                id="title"
                placeholder={t("sources.title")}
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="cur">{t("sources.cur")}</label>
              <select
                className="form-control"
                id="cur"
                value={cur}
                onChange={(e) => setCur(e.target.value)}
              >
                <option>{t("sources.cur")}</option>
                {Object.keys(currencies).map((key) => {
                  return (
                    <option key={key} value={key}>
                      {currencies[key]}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="form-group">
              <label htmlFor="description">{t("sources.description")}</label>
              <textarea
                className="form-control"
                id="description"
                rows="3"
                placeholder={t("sources.description")}
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="login">{t("sources.login")}</label>
              <input
                type="text"
                className="form-control"
                id="login"
                placeholder={t("sources.login")}
                value={login}
                onChange={(e) => setLogin(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">{t("sources.password")}</label>
              <input
                type="text"
                className="form-control"
                id="password"
                placeholder={t("sources.password")}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="token">{t("sources.token")}</label>
              <input
                type="text"
                className="form-control"
                id="token"
                placeholder={t("sources.token")}
                value={token}
                onChange={(e) => setToken(e.target.value)}
              />
            </div>
            <AppButton type="submit"> {t("sources.add")} </AppButton>
          </form>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {};
};
const mapDispatchToProps = (dispatch) => {
  return {
    addSource: (data) => dispatch(AdminSourcesAddAction(data)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddSource);
