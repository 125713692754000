import React, { useRef, useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import styles from "./index.module.scss";
import NotificationModal from "../../../../components/modals/notification";
import {
  MarkAsNotification,
  SetUserNotifications,
} from "../../../../store/actions/notification";
import { fuzzyTime } from "../../../../helpers";
import echo from "../../../../helpers/echo";
import AppButton from "../../../../components/button/Button";

const NotificationsDropdown = ({
  notifications,
  markAsReadNotification,
  userId,
}) => {
  const dropdownRef = useRef(null);
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [notification, setNotification] = useState({});
  const [showNotificationModal, setShowNotificationModal] = useState(false);
  const [dropdownState, updateDropdownState] = useState({
    isDropdownOpen: false,
  });

  const toggleDropdown = useCallback(() => {
    updateDropdownState({ isDropdownOpen: !dropdownState.isDropdownOpen });
  }, [dropdownState]);

  const handleClickOutside = useCallback(
    (event) => {
      if (
        !showNotificationModal &&
        dropdownRef &&
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target)
      ) {
        updateDropdownState({ isDropdownOpen: false });
      }
    },
    [showNotificationModal, dropdownRef]
  );

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, false);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, false);
    };
  });
  useEffect(() => {
    setTimeout(() => {
      if (localStorage.getItem("dc-token") !== null) {
        echo(localStorage.getItem("dc-token"))
          .private(`App.User.${userId}`)
          .notification((data) => {
            dispatch(SetUserNotifications(data));
            // TODO change tab favicon and count
          });
      }
    }, 4000);
    return () => {
      echo(localStorage.getItem("dc-token")).leave(`App.User.${userId}`);
    };
  }, [dispatch, userId]);

  let className = `dropdown-menu dropdown-menu-lg dropdown-menu-right notifications-dropdown ${styles.notificationsDropdown}`;

  if (dropdownState.isDropdownOpen) {
    className += " show";
  }
  const handleOpenNotificationModal = useCallback(
    (notification) => {
      setNotification(notification);
      updateDropdownState({ isDropdownOpen: false });
      setShowNotificationModal(true);
      if (!notification.read_at) {
        markAsReadNotification(notification.id);
      }
    },
    [setNotification, updateDropdownState, markAsReadNotification]
  );
  const getUnreadNotificationsCount = useCallback(() => {
    let count = 0;
    notifications.forEach((notification) => {
      if (!notification.read_at) {
        count++;
      }
    });
    return count;
  }, [notifications]);
  return (
    <>
      <li ref={dropdownRef} className="nav-item d-sm-inline-block dropdown">
        <button
          onClick={toggleDropdown}
          type="button"
          className="nav-link"
          data-toggle="dropdown"
        >
          <i className="far fa-bell" />
          <span className="badge badge-warning navbar-badge">
            {getUnreadNotificationsCount()}
          </span>
        </button>
        <div className={className}>
          <span className="dropdown-item dropdown-header">
            {t("header.notifications.count", {
              quantity: notifications?.length,
            })}
          </span>
          <div className="dropdown-divider" />
          <div className={`${styles["dropdown-list"]}`}>
            {notifications
              ?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
              .map((notification, key) => {
                const { data, created_at } = notification;
                return (
                  <div
                    key={key}
                    className={`dropdown-item ${styles["dropdown-item"]} ${
                      notification.read_at ? "text-muted" : ""
                    }`}
                    onClick={() => handleOpenNotificationModal(notification)}
                  >
                    <div>
                      <i className="fas fa-envelope mr-2" />
                      <span>{data[`message_${i18n.language}`]} </span>
                    </div>
                    <span className="float-right text-muted text-sm">
                      <i className="far fa-clock mr-2" />
                      {fuzzyTime(new Date(created_at) / 1000, i18n.language)}
                    </span>
                  </div>
                );
              })}
            {notifications && !!notifications?.length && (
              <>
                <div className="dropdown-divider" />
                <AppButton
                  theme="link"
                  className="dropdown-item dropdown-footer cursor-auto"
                  onClick={() => markAsReadNotification(null)}
                >
                  {t("header.notifications.markAllAsRead")}
                </AppButton>
              </>
            )}
          </div>
          <div className="dropdown-divider" />
        </div>
      </li>
      {showNotificationModal && (
        <NotificationModal
          title={t("header.notifications.title")}
          show={showNotificationModal}
          onHide={() => {
            setShowNotificationModal(false);
          }}
        >
          {notification?.data?.[`message_${i18n.language}`]}
          {notification?.data?.link && (
            <a rel="noreferrer" target="_blank" href={notification?.data?.link}>
              {t("notification.download")}
            </a>
          )}
        </NotificationModal>
      )}
    </>
  );
};
const mapStateToProps = (state) => ({
  notifications: state.auth.currentUser.notifications,
  userId: state.auth.currentUser.id,
});

const mapDispatchToProps = (dispatch) => ({
  markAsReadNotification: (notificationID) =>
    dispatch(MarkAsNotification(notificationID)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NotificationsDropdown);
