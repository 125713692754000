import { toast } from "react-toastify";
import { http } from "../../../helpers";
import actionTypes from "../../action-types";
import i18n from "i18next";
export const AdminUsersAction = (
  pageNumber,
  email = null,
  role,
  entity,
  activated,
  managerId
) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.ADMIN_USERS_LOADING, payload: true });
    try {
      const params = {
        page: pageNumber ? pageNumber : 1,
        email,
        role,
        entity,
        activated,
        managerId,
      };
      const response = await http.get("/users", { params });
      const { data } = response.data;
      dispatch({ type: actionTypes.SET_ADMIN_USERS, payload: data });
      dispatch({ type: actionTypes.ADMIN_USERS_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: actionTypes.ADMIN_USERS_LOADING, payload: false });
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};
export const AdminUserSingleAction = (id) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.ADMIN_SINGLE_USER_LOADING, payload: true });
    try {
      const response = await http.get(`/users/${id}`);
      const { data } = response.data;
      dispatch({ type: actionTypes.SET_ADMIN_SINGLE_USER, payload: data });
      dispatch({ type: actionTypes.ADMIN_SINGLE_USER_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: actionTypes.ADMIN_SINGLE_USER_LOADING, payload: false });
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const GetManagerByEmailAction = (email, cbFn) => {
  return async () => {
    try {
      const response = await http.get(`/users/managers/${email}`);
      const { data } = response.data;
      cbFn([{ value: data.id, label: data.fullname }]);
    } catch (error) {
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};
export const GetManagerListAction = () => {
  return async () => {
    try {
      const response = await http.get("/users/managers");
      const { data } = response;
      return data;
    } catch (error) {
      console.error(error);
    }
  };
};

export const AdminDeleteUsersAction = (id) => {
  return async (dispatch) => {
    try {
      await http.delete(`/users/${id}`);
      dispatch({ type: actionTypes.ADMIN_DELETE_USER, payload: id });
      toast.success(i18n.t("users.single.deleted"));
    } catch (error) {
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const ExportOrdAction = (user, date) => {
  return async () => {
    try {
      const params = {
        period: date,
      };
      await http.post(`/users/${user.id}/export`, params);
      toast.success(`${i18n.t("common.requestSendWaitNotify")}`, {
        autoClose: 5000,
      });
    } catch (error) {
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};
