import React from "react";

const HeadScript = ({ url }) => {
  return (
    <code>
      <pre>
        <span className="text-bold">
          &lt;script async
          src="https://yandex.ru/ads/system/header-bidding.js"&gt;
        </span>
        <span className="text-bold">&lt;/script&gt;</span>
        <br />
        <span className="text-bold">&lt;script&gt;</span>
        <br />
        <div>
          <span className="text-bold">
            const dcJs = document.createElement('script');{" "}
          </span>
          <br />
          <span className="text-bold">
            dcJs.src = "https://ads.digitalcaramel.com/js/{url}
            .js?ts="+new Date().getTime();
          </span>
          <br />
          <span className="text-bold">dcJs.async = true;</span>
          <br />
          <span className="text-bold">document.head.appendChild(dcJs);</span>
          <br />
        </div>
        <span className="text-bold">&lt;/script&gt;</span>
        <br />
        <span className="text-bold">
          &lt;script&gt;window.yaContextCb = window.yaContextCb || []
          &lt;/script&gt;{" "}
        </span>
        <br />
        <span className="text-bold">
          &lt;script async
          src="https://yandex.ru/ads/system/context.js"&gt;&lt;/script&gt;
        </span>
      </pre>
    </code>
  );
};
export default HeadScript;
