import AdminDashboard from "./dashboard";
import AdminZen from "./zen";
import AdminZenBySources from "./zen/bySources";
import AdminZenByZones from "./zen/byZones";
import AdminZenBySizes from "./zen/bySizes";
import AdminUsers from "./users";
import AdminActiveWebsites from "./websites/active";
import AdminWebsites from "./websites";
import AdminZones from "./zones";
import AdminSources from "./sources";
import AddSource from "./sources/add";
import AdminWebsiteEdit from "./websites/edit";
import AdminAddZone from "./websites/zones/add";
import AdminUserEdit from "./users/edit";
import AdminZonesEdit from "./zones/edit";
import AdminScripts from "./scripts";
import AdminSettings from "./settings";
import AdminExport from "./export";
import ScriptEdit from "./scripts/edit";
import ScriptsNew from "./scripts/new";
import Profile from "../profile/Profile";
import ZonesMatching from "./websites/zones/matching";
import SourceEdit from "./sources/edit";
import AdminAddQuestions from "./questions";
import AdminEditQuestions from "./questions/edit";

const routes = [
  {
    path: "/admin",
    name: "menusidebar.label.dashboard",
    icon: "fa-chart-line",
    roles: ["admin", "manager"],
    permissions: "",
    component: AdminDashboard,
    inSidebar: true,
  },
  {
    path: "/admin/zen",
    name: "menusidebar.label.zen",
    icon: "fa-chart-area",
    roles: ["admin", "manager"],
    permissions: "",
    children: [
      {
        path: "/admin/zen",
        name: "menusidebar.label.zen",
        icon: "fa-chart-area",
        component: AdminZen,
      },
      {
        path: "/admin/zen-by-sources",
        name: "menusidebar.label.zenBySources",
        icon: "fa-chart-pie",
        component: AdminZenBySources,
      },
      {
        path: "/admin/zen-by-zones",
        name: "menusidebar.label.zenByZones",
        icon: "fa-ad",
        component: AdminZenByZones,
      },
      {
        path: "/admin/zen-by-sizes",
        name: "menusidebar.label.zenBySizes",
        icon: "fa-ruler",
        component: AdminZenBySizes,
      },
    ],
    inSidebar: true,
  },
  {
    path: "/admin/users",
    name: "menusidebar.label.users",
    icon: "fa-users",
    roles: ["admin", "manager"],
    permissions: "",
    component: AdminUsers,
    inSidebar: true,
  },
  {
    path: "/admin/websites",
    name: "menusidebar.label.websites",
    icon: "fa-globe",
    roles: ["admin", "manager"],
    permissions: "",
    children: [
      {
        path: "/admin/websites",
        name: "menusidebar.label.allWebsites",
        icon: "fa-globe",
        component: AdminWebsites,
      },
      {
        path: "/admin/websites/active",
        name: "menusidebar.label.activeWebsites",
        icon: "fa-chart-line",
        component: AdminActiveWebsites,
      },
      {
        path: "/admin/websites/new",
        name: "menusidebar.label.newWebsites",
        icon: "fa-bell",
        component: AdminWebsites,
      },
    ],
    inSidebar: true,
  },
  {
    path: "/admin/zones",
    name: "menusidebar.label.zones",
    icon: "fa-ad",
    roles: ["admin", "manager"],
    permissions: "",
    component: AdminZones,
    inSidebar: true,
  },
  {
    path: "/admin/zones/:id",
    name: "menusidebar.label.zonesEdit",
    icon: "",
    roles: ["admin", "manager"],
    permissions: "",
    inSidebar: false,
    component: AdminZonesEdit,
  },
  {
    path: "/admin/scripts",
    name: "menusidebar.label.scripts",
    icon: "fa-laptop-code",
    roles: ["admin", "manager"],
    permissions: "",
    children: [
      {
        path: "/admin/scripts",
        name: "menusidebar.label.list",
        icon: "fa-list-alt",
        component: AdminScripts,
      },
      {
        path: "/admin/scripts/new",
        name: "menusidebar.label.addNew",
        icon: "fa-plus-square",
        component: ScriptsNew,
      },
    ],
    inSidebar: true,
  },
  {
    path: "/admin/scripts/:id",
    name: "",
    icon: "",
    roles: ["admin", "manager"],
    permissions: "",
    inSidebar: false,
    component: ScriptEdit,
  },
  {
    path: "/admin/export",
    name: "menusidebar.label.export",
    icon: "fa-file-export",
    roles: ["admin", "manager"],
    permissions: "",
    inSidebar: true,
    component: AdminExport,
  },
  {
    path: "/admin/settings",
    name: "menusidebar.label.settings",
    icon: "fa-cog",
    roles: ["admin", "manager"],
    permissions: "",
    inSidebar: true,
    component: AdminSettings,
  },
  {
    path: "/admin/sources",
    name: "menusidebar.label.sources",
    icon: "fa-bezier-curve",
    roles: ["admin"],
    permissions: "",
    children: [
      {
        path: "/admin/sources",
        name: "menusidebar.label.list",
        icon: "fa-list-alt",
        component: AdminSources,
      },
      {
        path: "/admin/sources/add",
        name: "menusidebar.label.addNew",
        icon: "fa-plus-square",
        component: AddSource,
      },
    ],
    inSidebar: true,
  },
  {
    path: "/admin/sources/:id",
    roles: ["admin"],
    permissions: "",
    inSidebar: false,
    component: SourceEdit,
  },

  {
    path: "/admin/websites/:id",
    name: "",
    icon: "",
    roles: ["admin", "manager"],
    permissions: "",
    inSidebar: false,
    component: AdminWebsiteEdit,
  },
  {
    path: "/admin/websites/:id/zones/add",
    name: "",
    icon: "",
    roles: ["admin", "manager"],
    permissions: "",
    inSidebar: false,
    component: AdminAddZone,
  },
  {
    path: "/admin/websites/:id/zones/matching",
    name: "",
    icon: "",
    roles: ["admin", "manager"],
    permissions: "",
    inSidebar: false,
    component: ZonesMatching,
  },
  {
    path: "/admin/users/:id",
    name: "",
    icon: "",
    roles: ["admin", "manager"],
    permissions: "",
    inSidebar: false,
    component: AdminUserEdit,
  },
  {
    path: "/admin/profile",
    name: "menusidebar.label.profile",
    icon: "fa-user",
    roles: ["admin", "manager"],
    permissions: "",
    inSidebar: true,
    component: Profile,
  },
  {
    path: "/admin/questions/add",
    name: "menusidebar.label.questions",
    roles: ["admin", "manager"],
    permissions: "",
    inSidebar: false,
    component: AdminAddQuestions,
  },
  {
    path: "/admin/questions/:id",
    name: "menusidebar.label.questions",
    roles: ["admin", "manager"],
    permissions: "",
    inSidebar: false,
    component: AdminEditQuestions,
  },
];

export default routes;
