import React, { useCallback, useEffect } from "react";
import { connect } from "react-redux";
import AppButton from "../../../components/button/Button";
import PageLoading from "../../../components/page-loading/PageLoading";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { SetPeriod, UserReferral } from "../../../store/actions/referral";
import { selectText, showAmountOfNumber } from "../../../helpers";
import { toast } from "react-toastify";
import styles from "./index.module.css";
import Filter from "../../../components/filter";
const Referral = ({
  isLoading,
  width,
  userReferral,
  list,
  period,
  setPeriod,
  sum,
  user,
}) => {
  useEffect(() => {
    userReferral(period);
  }, [userReferral, period]);
  const handleApply = useCallback(
    (_, picker) => {
      const pickerDate = `${picker.startDate.format(
        "YYYY-MM-DD"
      )} - ${picker.endDate.format("YYYY-MM-DD")}`;
      setPeriod(pickerDate);
    },
    [setPeriod]
  );

  const [t] = useTranslation();
  const referralList = list.map((item, index) => {
    return (
      <tr key={index}>
        <td>{item.date}</td>
        <td>{showAmountOfNumber(item.value)}</td>
      </tr>
    );
  });
  let template;
  if (isLoading) {
    template = <PageLoading />;
  } else {
    template = (
      <table className="table table-striped table-valign-middle">
        <thead>
          <tr>
            <th>{t("common.date")}</th>
            <th>{t("dashboard.website.profit")}</th>
          </tr>
        </thead>
        <tbody>{referralList}</tbody>
        <tfoot>
          <tr>
            <th>{t("common.total")}</th>
            <th>{showAmountOfNumber(sum)}</th>
          </tr>
        </tfoot>
      </table>
    );
  }
  return (
    <>
      <div className="container-fluid">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>{t("menusidebar.label.referral")}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/user">{t("header.label.home")}</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {t("menusidebar.label.referral")}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <div className="row">
          <div className="col-lg-12 col-12">
            <div className="card">
              <div className="card-header text-center">
                <h3 className="text-center">{t("referral.title")}</h3>
              </div>
              <div className="card-body">
                <ol>
                  <Trans i18nKey="referral.rules">
                    <li>
                      Any member of the Digital Caramel advertising network can
                      become a participant in the referral program, regardless
                      of whether he/she has his/her own sites in the system or
                      not.
                    </li>
                    <li>
                      Any Webmaster who registered using the participant's link
                      within 30 days after clicking on it and having a unique
                      device identifier (IP address) can become a referral of
                      the participant in the referral program.
                    </li>
                    <li>
                      The participant of the referral program receives a reward
                      of 6% of the earnings of his referral. The payment of the
                      reward is carried out next month, starting from the 20th,
                      to those requisites that are indicated in the webmaster's
                      account.
                    </li>
                    <li>
                      Referral reward will be charged only for those platforms
                      that have not previously been registered in the Digital
                      Caramel system.
                    </li>
                    <li>
                      In cases, when the referral’s account is debited due to
                      the disturbance of the rules of the system, referral
                      charges from this amount are also debited from the
                      participant’s account.{" "}
                    </li>
                    <li>
                      It is forbidden to attract referrals using email spam,
                      spam on social networks or other types. If spam is
                      detected, the amount of the reward will be reset to zero.
                    </li>
                    <li>
                      It is forbidden for one person to create two accounts for
                      the artificial linking of a referral. Upon detection of
                      such an attempt, the amount of the reward will be reset to
                      zero in all accounts.
                    </li>
                  </Trans>
                </ol>
                <div>
                  <h3>{t("referral.referralLink")}</h3>
                  <input
                    className={styles.refLink}
                    id="referralLink"
                    type="text"
                    style={{
                      width: (user.referral_link.length + 1) * 8,
                      maxWidth: "100%",
                    }}
                    readOnly
                    value={user.referral_link}
                  />
                  <AppButton
                    className={styles.refLinkBtn}
                    onClick={() =>
                      selectText("referralLink", true, () =>
                        toast.info(t("common.copied"))
                      )
                    }
                  >
                    {t("referral.copy")}
                  </AppButton>
                </div>
              </div>
            </div>
            <Filter
              period={period}
              setPeriod={setPeriod}
              isLoading={isLoading}
              handleApply={handleApply}
            ></Filter>
            {template}
          </div>
        </div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => ({
  isLoading: state.referral.isLoading,
  filterShow: state.referral.filterShow,
  period: state.referral.period,
  list: state.referral.list,
  sum: state.referral.sum,
  user: state.auth.currentUser,
  width: state.theme.width,
});
const mapDispatchToProps = (dispatch) => ({
  userReferral: (period) => dispatch(UserReferral(period)),
  setPeriod: (period) => dispatch(SetPeriod(period)),
});
export default connect(mapStateToProps, mapDispatchToProps)(Referral);
