import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ActivateUser from "../../store/actions/auth/activateUserAction";
import Cookies from "js-cookie";
import { history } from "../../helpers";
import PageLoading from "../../components/page-loading/PageLoading";
import logo from "../../assets/img/login-logo.png";

const Confirmed = (props) => {
  const { token } = props.match.params;
  const { isActivated, activateUser } = props;
  const [t] = useTranslation();
  useEffect(() => {
    if (!Cookies.get("confirm-token")) {
      history.push("/login");
    }
  }, []);
  useEffect(() => {
    activateUser(token);
  }, [token, activateUser]);

  if (!isActivated) {
    return <PageLoading />;
  }
  document.getElementById("root").classList = "hold-transition register-page";
  return (
    <div className="register-box">
      <div className="card card-outline card-primary">
        <div className="card-header text-center">
          <Link to="/" className="h1">
            <img src={logo} alt="Logo" className="w-100" />
          </Link>
        </div>
        <div className="card-body">
          <div className="mb-3 text-center">
            <i
              className="far fa-check-circle fa-5x"
              style={{ color: "green" }}
            ></i>
          </div>
          <div className="mb-3 text-center">{t("confirm.confirm-success")}</div>
          <div className="mb-3 text-center">
            <Link to="/login" className="btn btn-primary">
              {t("login.button.signIn.label")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  const { isActivated } = state.auth;
  return {
    isActivated,
  };
};
const mapDispatchToProps = (dispatch) => ({
  activateUser: (token) => dispatch(ActivateUser(token)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Confirmed);
