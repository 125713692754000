import React, { useContext, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { ThemeContext } from "../../contexts/ThemeContext";
import AppButton from "../button/Button";
import ManagerList from "../manager-list";
import styles from "./index.module.scss";

const WebsiteListFilter = ({
  filterEmail,
  filterWebsite,
  filterStatus,
  filterManager,
  filterPeriod,
  filterOrder,
  setFilterEmail,
  setFilterWebsite,
  setFilterStatus,
  setFilterManager,
  setFilterPeriod,
  handleApply,
  isLoading,
}) => {
  const [filterShow, setFilterShow] = useState(true);
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  const { width } = theme;
  let mobile = Math.floor(width) < 528;
  let caret = Math.floor(width) < 1168 ? "caretDown" : "caretRight";
  return (
    <div className="website-list-filter">
      <div className="row">
        <div
          className={`col-12 d-flex ${
            mobile ? "direction-column" : "direction-row"
          }`}
        >
          <AppButton
            icon={filterShow ? caret : "filter"}
            theme="default"
            className="filterButton mb-3"
            onClick={() => setFilterShow(!filterShow)}
          >
            {t("websites.button.filter")}
          </AppButton>
          {filterShow && (
            <ul
              className={`other-date-options list-unstyled d-flex mb-0 direction-`}
            >
              {setFilterWebsite && (
                <li>
                  <div className="input-group mb-3">
                    <input
                      value={filterWebsite}
                      className="form-control"
                      placeholder={t("common.website")}
                      onChange={(e) => setFilterWebsite(e.target.value)}
                    />
                  </div>
                </li>
              )}
              {setFilterEmail && (
                <li>
                  <div className="input-group mb-3">
                    <input
                      value={filterEmail}
                      className="form-control"
                      placeholder={t("common.email")}
                      onChange={(e) => setFilterEmail(e.target.value)}
                    />
                  </div>
                </li>
              )}
              {setFilterStatus && (
                <li>
                  <div className="input-group mb-3">
                    <select
                      className="custom-select"
                      id="entityGroupSelect"
                      onChange={(e) => setFilterStatus(e.target.value)}
                      value={filterStatus}
                    >
                      <option value="">{`${t("common.choose")} ${t(
                        "common.status"
                      )}`}</option>
                      <option value="approved">{t("website.approved")}</option>
                      <option value="pending">{t("website.pending")}</option>
                      <option value="canceled">{t("website.canceled")}</option>
                    </select>
                  </div>
                </li>
              )}
              {setFilterManager && (
                <li>
                  <div className="input-group mb-3">
                    <ManagerList
                      className="custom-select"
                      onChange={setFilterManager}
                    />
                  </div>
                </li>
              )}
              {setFilterPeriod && (
                <li>
                  <DateRangePicker
                    onApply={(e, picker) => {
                      const pickerDate = `${picker.startDate.format(
                        "YYYY-MM-DD"
                      )} - ${picker.endDate.format("YYYY-MM-DD")}`;
                      setFilterPeriod(pickerDate);
                    }}
                    initialSettings={{
                      alwaysShowCalendars: true,
                      maxDate: moment().toDate(),
                      locale: {
                        format: "YYYY-MM-DD",
                      },
                    }}
                  >
                    <div id="daterange" className={`${styles.selectbox}`}>
                      <i className="far fa-calendar-alt mr-1"></i>
                      <span>{t("ranges.selectRange")}</span>{" "}
                      <b className="caret"></b>
                    </div>
                  </DateRangePicker>
                </li>
              )}
              <li>
                <div>
                  <AppButton
                    icon="filter"
                    isLoading={isLoading}
                    theme="primary"
                    onClick={() => {
                      handleApply(
                        filterPeriod,
                        1,
                        filterEmail,
                        filterWebsite,
                        filterStatus,
                        filterManager,
                        filterOrder
                      );
                    }}
                  >
                    {t("websites.button.filter")}
                  </AppButton>
                </div>
              </li>
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};
export default WebsiteListFilter;
