import React from "react";
import maintenanceImage from "../../assets/img/maintenance.png";

const Maintenance = () => {
  document.getElementById("root").classList = "hold-transition login-page";
  return (
    <div className="maintenance">
      <div className="card card-outline mx-2">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="card-body">
                <img
                  className="w-100"
                  src={maintenanceImage}
                  alt="Maintenance"
                />
                <h2 className="text-center pt-5">Технические работы</h2>
                <p className="text-center py-2">Мы скоро вернемся</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Maintenance;
