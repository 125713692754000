import actionTypes from "../../../action-types";

const initialState = {
  websites: [],
  reports: {
    chartMultisite: {
      series: [],
      categories: new Set(),
    },
    total: {
      impressions: 0,
      sum: 0,
      cpm: 0,
    },
  },
  isLoading: false,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.WEBSITES_REPORTS:
      return {
        ...state,
        reports: payload,
      };
    case actionTypes.WEBSITES_REPORTS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default reducer;
