import React from "react";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";

const NoData = () => {
  const { t } = useTranslation();
  return (
    <div className={`${styles["no-data"]} text-center`}>
      <div className="no-data__icon">
        <i className="fas fa-exclamation-triangle"></i>
      </div>
      <div className="no-data__text">
        <p>{t("common.noData")}</p>
      </div>
    </div>
  );
};
export default NoData;
