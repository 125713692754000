import { toast } from "react-toastify";
import { http } from "../../../helpers";
import i18n from "i18next";
import actionTypes from "../../action-types";
export const UserReferral = (period) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.REFERRAL_LOADING, payload: true });
    try {
      const params = {
        period,
      };
      const response = await http.get(`/referrals`, { params });
      const { data } = response.data;
      dispatch({ type: actionTypes.SET_REFERRALS, payload: data });
      dispatch({ type: actionTypes.REFERRAL_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: actionTypes.REFERRAL_LOADING, payload: false });
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};
export const SetPeriod = (payload) => ({
  type: actionTypes.SET_REFERRAL_PERIOD,
  payload,
});
