import { toast } from "react-toastify";
import { http } from "../../../helpers";
import actionTypes from "../../action-types";
import i18n from "i18next";

export const AdminScriptsAction = () => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.ADMIN_SCRIPTS_LOADING, payload: true });
    try {
      const response = await http.get("/scripts");
      const { data } = response.data;
      dispatch({ type: actionTypes.SET_ADMIN_SCRIPTS, payload: data.list });
      dispatch({ type: actionTypes.ADMIN_SCRIPTS_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: actionTypes.ADMIN_SCRIPTS_LOADING, payload: false });
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const AdminGetWebsiteScriptAction = (id) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.ADMIN_WEBSITE_SCRIPT_LOADING, payload: true });
    try {
      const response = await http.get(`/scripts/${id}`);
      const { data } = response;
      const { css_code, code } = data;
      const payload = {
        cssCode: css_code ? css_code : "",
        jsCode: code,
        enableCss: !!css_code,
        adfoxIds: Array.from(code.matchAll(/"code":\s*"[a-z0-9]*"/g), (m) =>
          m[0].split(":")[1].trim().replace(/"/g, "")
        ).join(", "),
        enableAdfoxRefresh: code.search("Ad refresh") !== -1,
      };
      dispatch({ type: actionTypes.SET_ADMIN_WEBSITE_SCRIPT, payload });
      dispatch({
        type: actionTypes.ADMIN_WEBSITE_SCRIPT_LOADING,
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: actionTypes.ADMIN_WEBSITE_SCRIPT_LOADING,
        payload: false,
      });
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const SetEnableCss = (payload) => ({
  type: actionTypes.ADMIN_WEBSITE_SCRIPT_ENABLECSS,
  payload,
});
export const SetJs = (payload) => ({
  type: actionTypes.ADMIN_WEBSITE_CHANGE_SCRIPT,
  payload,
});
export const SetCss = (payload) => ({
  type: actionTypes.ADMIN_WEBSITE_CHANGE_CSS,
  payload,
});
export const SetAdfoxIds = (payload) => ({
  type: actionTypes.ADMIN_WEBSITE_SCRIPT_ADFOXIDS,
  payload,
});
export const SetAdfoxRefresh = (payload) => ({
  type: actionTypes.ADMIN_WEBSITE_SCRIPT_ENABLE_AD_REFRESH,
  payload,
});
export const SetAdsRefreshInterval = (payload) => ({
  type: actionTypes.ADMIN_WEBSITE_SCRIPT_AD_REFRESH_INTERVAL,
  payload,
});

export const AdminWebsiteScriptUpdateAction = (
  id,
  jsCode,
  encodedJsCode,
  cssCode,
  cb
) => {
  return async (dispatch) => {
    dispatch({
      type: actionTypes.ADMIN_WEBSITE_UPDATE_SCRIPT_LOADING,
      payload: true,
    });
    try {
      const params = {
        code: jsCode,
        css_code: cssCode,
        encodedCode: encodedJsCode,
      };
      await http.post(`/scripts/${id}`, params);
      toast.success(`${i18n.t("script.updated")}`);
      // dispatch({ type: actionTypes.ADMIN_WEBSITE_UPDATE_SCRIPT_LOADING, payload: false });
      if (cb) cb();
    } catch (error) {
      dispatch({
        type: actionTypes.ADMIN_WEBSITE_UPDATE_SCRIPT_LOADING,
        payload: false,
      });
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};
