import React, { createRef, useState } from "react";
import { useTranslation } from "react-i18next";
import AceEditor from "react-ace";
import { Range } from "ace-builds";
import JavaScriptObfuscator from "javascript-obfuscator";
import AppButton from "../button/Button";
import {
  accounts,
  cssLink,
  generateAutoUpdateScript,
  history,
  validateDomain,
} from "../../helpers";
const Editor = ({ updateScripts }) => {
  //   const { id } = props.match.params;
  // const isAddMode = !id;
  const { t } = useTranslation();
  const [website, setWebsite] = useState("");
  const [enableCss, setEnableCss] = useState(false);
  const [cssCode, setCssCode] = useState("");
  const [jsCode, setJsCode] = useState("");
  const [errors, setErrors] = useState(new Set());
  const [adfoxIds, setAdfoxIds] = useState("");
  const [autoUpdate, setAutoUpdate] = useState(false);
  const [adsRefreshInterval, setAdsRefreshInterval] = useState(30);
  const jsEditor = createRef(null);
  const cssEditor = createRef(null);
  const adfoxIdsEditor = createRef(null);

  const selectAccount = (e) => {
    const accountId = Number(e.target.value);
    if (accountId === 0) return;
    const editor = jsEditor.current.editor;
    const foundAccount = accounts.find(({ id }) => id === accountId);
    let script = foundAccount?.script;
    if (enableCss) {
      const createCssLink = cssLink(website, true);
      script = script.concat(createCssLink);
    }
    if (autoUpdate && adfoxIds.length) {
      const ids = adfoxIds
        .split(",")
        .filter((id) => id.length)
        .map((id) => `"${id}"`);
      const refreshScript = generateAutoUpdateScript(
        adsRefreshInterval,
        ids,
        true
      );
      script = script.concat(refreshScript);
    }
    editor.setValue(script);
  };
  const toggleCss = () => {
    window.jsEditor = jsEditor.current.editor;
    if (!website || errors.size) {
      return;
    }
    const editor = jsEditor.current.editor;
    const createCssLink = cssLink(website, true);
    if (!enableCss) {
      const existLink = editor.find("/*start-css loader*/");
      if (!existLink) {
        editor.session.insert(
          {
            row: editor.session.getLength(),
            column: 0,
          },
          createCssLink
        );
      }
    } else {
      const startCss = editor.find("/*start-css loader*/");
      const endCss = editor.find("/*end-css loader*/");
      if (startCss && endCss) {
        editor.session.replace(
          new Range(
            startCss.start.row,
            startCss.start.column,
            endCss.end.row,
            endCss.end.column
          ),
          ""
        );
      }
      setCssCode("");
    }
    setEnableCss(!enableCss);
  };
  const handleAddScript = () => {
    if (errors.size || !website || !jsCode || (enableCss && !cssCode)) {
      return;
    }
    const { obfuscate } = JavaScriptObfuscator;
    const encodedJs = obfuscate(jsCode);
    const encodeJsCode = encodedJs.getObfuscatedCode();
    updateScripts(website, jsCode, encodeJsCode, cssCode, () =>
      history.push(`/admin/scripts/${website}`)
    );
  };
  const handleAddDomain = (domain) => {
    if (!validateDomain(domain)) {
      hanldeAddError("domain");
    } else {
      handleRemoveError("domain");
    }
    setWebsite(domain);
  };
  const hanldeAddError = (error) => {
    let err = new Set(errors);
    err.add(error);
    setErrors(err);
  };
  const handleRemoveError = (error) => {
    let err = new Set(errors);
    err.delete(error);
    setErrors(err);
  };
  const toggleAudoUpdate = () => {
    if (autoUpdate) {
      const editor = jsEditor.current.editor;
      const startAutoScript = editor.find("/*start Ad refresh*/");
      const endAutoScript = editor.find("/*end Ad refresh*/");
      if (startAutoScript && endAutoScript) {
        editor.session.replace(
          new Range(
            startAutoScript.start.row,
            startAutoScript.start.column,
            endAutoScript.end.row,
            endAutoScript.end.column
          ),
          ""
        );
      }
    }

    setAutoUpdate(!autoUpdate);
  };
  const handleIncludeAdsRefreshScript = () => {
    if (!adfoxIds.length) {
      hanldeAddError("adfoxIds");
      return;
    }
    const ids = adfoxIdsEditor.current.value
      .split(",")
      .filter((id) => id.length)
      .map((id) => `"${id}"`);
    const refreshScript = generateAutoUpdateScript(
      adsRefreshInterval,
      ids,
      true
    );
    const editor = jsEditor.current.editor;
    if (autoUpdate) {
      const existAutoUpdate = editor.find("/*start Ad refresh*/");
      if (!existAutoUpdate) {
        editor.session.insert(
          {
            row: editor.session.getLength(),
            column: 0,
          },
          refreshScript
        );
      } else {
        const startAutoScript = editor.find("/*start Ad refresh*/");
        const endAutoScript = editor.find("/*end Ad refresh*/");
        if (startAutoScript && endAutoScript) {
          editor.session.replace(
            new Range(
              startAutoScript.start.row,
              startAutoScript.start.column,
              endAutoScript.end.row,
              endAutoScript.end.column
            ),
            refreshScript
          );
        }
      }
    }
  };

  const handleChangeJs = (code) => {
    const adfoxIds = Array.from(code.matchAll(/"code":\s*"[a-z0-9_]*"/g), (m) =>
      m[0].split(":")[1].trim().replace(/"/g, "")
    ).join(",");
    setAdfoxIds(adfoxIds);
    setJsCode(code);
  };
  return (
    <>
      <div className="card rounded mt-5 p-5">
        <div className="row">
          <div className="col-12">
            <input
              type="text"
              id="domain"
              placeholder={t("script.domain")}
              className={`form-control mb-2 ${
                errors.has("domain") ? "is-invalid" : ""
              }`} //${errors.find(error => error.domain === true) ? 'danger': ''}
              style={{ maxWidth: "300px" }}
              onChange={({ target }) =>
                handleAddDomain(target.value.toLocaleLowerCase())
              }
              value={website}
            />
          </div>
          <div className="col-12">
            <select
              className="custom-select"
              style={{ width: "300px" }}
              onChange={selectAccount}
            >
              <option value={0}>{t("script.chooseAccount")}</option>
              {accounts.map((account) => {
                return (
                  <option value={account.id} key={account.id}>
                    {account.name}
                  </option>
                );
              })}
            </select>
            <div className="mt-2">
              <input
                onChange={toggleCss}
                checked={enableCss}
                type="checkbox"
                id="inludeCss"
              />
              <label htmlFor="inludeCss" className="ml-2">
                {t("script.enableCss")}
              </label>
            </div>
          </div>
          <div className="col-12 col-xl-6">
            <h3>JS</h3>
            <AceEditor
              ref={jsEditor}
              mode="javascript"
              theme="monokai"
              onChange={(code) => handleChangeJs(code)}
              fontSize={12}
              name="editor"
              value={jsCode}
              height="700px"
              width="100%"
              editorProps={{ $blockScrolling: true }}
              setOptions={{
                enableBasicAutocompletion: true,
                enableLiveAutocompletion: true,
                enableSnippets: true,
              }}
            />
          </div>
          <div className="col-12 col-xl-6">
            {enableCss && (
              <>
                <h3>CSS</h3>
                <AceEditor
                  ref={cssEditor}
                  mode="css"
                  theme="monokai"
                  onChange={(code) => setCssCode(code)}
                  fontSize={12}
                  name="css-editor"
                  height="700px"
                  width="100%"
                  value={cssCode}
                  editorProps={{ $blockScrolling: true }}
                  setOptions={{
                    enableBasicAutocompletion: false,
                    enableLiveAutocompletion: false,
                    enableSnippets: false,
                  }}
                />
              </>
            )}
          </div>
          <div className="col-12 mt-3">
            <input
              onChange={toggleAudoUpdate}
              checked={autoUpdate}
              type="checkbox"
              id="inludeAdRefresh"
            />
            <label htmlFor="inludeAdRefresh" className="ml-2">
              {t("script.adRefresh")}
            </label>
            {autoUpdate && (
              <div className="col-xl-6 col-12">
                <div className="mb-2">
                  <label htmlFor="adfoxIds" className="form-label">
                    {t("script.adfoxIds")}
                  </label>
                  <textarea
                    ref={adfoxIdsEditor}
                    id="adfoxIds"
                    className={`form-control ${
                      errors.has("adfoxIds") ? "is-invalid" : ""
                    }`}
                    rows="4"
                    cols="50"
                    defaultValue={adfoxIds}
                  />
                </div>
                <div className="mb-2">
                  <label htmlFor="refreshInterval" className="form-label">
                    {t("script.interval")}
                  </label>
                  <input
                    id="refreshInterval"
                    className="form-control"
                    type="number"
                    min={1}
                    onChange={(e) => setAdsRefreshInterval(e.target.value)}
                    value={adsRefreshInterval}
                  />
                </div>
                <div>
                  <AppButton onClick={handleIncludeAdsRefreshScript}>
                    {t("script.includeAdRefresh")}
                  </AppButton>
                </div>
              </div>
            )}
          </div>
          <div className="col-12 mt-3 text-right">
            <AppButton onClick={handleAddScript}>{t("script.add")}</AppButton>
          </div>
        </div>
      </div>
    </>
  );
};

export default Editor;
