import Echo from "laravel-echo";

window.Pusher = require("pusher-js");

const echo = (token) => {
  const options = {
    broadcaster: "pusher",
    key: process.env.REACT_APP_PUSHER_KEY,
    cluster: process.env.REACT_APP_PUSHER_CLUSTER,
    encrypted: true,
    authEndpoint: process.env.REACT_APP_API_URL + "/broadcasting/auth",
    auth: {
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "application/json",
      },
    },
  };

  const echo = new Echo(options);
  return echo;
};
export default echo;
