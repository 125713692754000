import actionTypes from "../../action-types/";
import { getUserPath, history } from "../../../helpers";
import { http } from "../../../helpers/http";
import i18n from "i18next";
import { toast } from "react-toastify";

export const loginWithJWT = (user) => (dispatch) => {
  dispatch({ type: actionTypes.AUTH_LOADING, loading: true });
  http
    .post("/auth/login", {
      email: user.email,
      password: user.password,
    })
    .then((response) => {
      dispatch({ type: actionTypes.AUTH_LOADING, loading: false });
      if (response.data) {
        dispatch({
          type: actionTypes.LOGIN_USER,
          currentUser: response.data.user,
          token: response.data.access_token,
        });

        history.push(getUserPath(response.data.user.role_id, user?.redirect));
      }
    })
    .catch((err) => {
      if (err.response.status === 429) {
        toast.error(`${i18n.t("login.error.manyRequests")}`);
      } else {
        toast.error(`${i18n.t("login.error.incorrect")}`);
      }
      dispatch({ type: actionTypes.AUTH_LOADING, loading: false });
    });
};
export const loginWithSocial = (token, origin) => (dispatch) => {
  http
    .post("/auth/login", {
      token: token,
    })
    .then((response) => {
      dispatch({ type: actionTypes.AUTH_LOADING, loading: false });
      if (response.data) {
        dispatch({
          type: actionTypes.LOGIN_USER,
          currentUser: response.data.user,
          token: response.data.access_token,
        });

        history.push("/");
      }
    })
    .catch((err) => {
      //toast.error(`${i18n.t('error.somethingWrong')}`);
      dispatch({ type: actionTypes.AUTH_LOADING, loading: false });
      history.push(`/${origin}`);
    });
};
