import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AppButton from "../button/Button";

const CodeCheck = ({
  show,
  title,
  onHide,
  onSubmit,
  resendCode,
  theme = "danger",
}) => {
  const [t] = useTranslation();
  const [code, setCode] = useState("");
  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
        <button type="button" className="close" onClick={onHide}>
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>
      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <div className="form-group col-md-6">
                  <label htmlFor="code" className="labels px-1">
                    {t("users.single.code")}
                  </label>
                  <input
                    id="code"
                    type="code"
                    className="form-control"
                    placeholder={t("users.single.code")}
                    onChange={(e) => setCode(e.target.value)}
                  />
                </div>
                <div>
                  <span className="align-middle">
                    {t("users.single.receiveCodeFirstPart")}
                  </span>
                  <AppButton
                    theme="link"
                    className="px-1 py-0"
                    onClick={resendCode}
                  >
                    {t("users.single.receive")}
                  </AppButton>
                  <span className="align-middle">
                    {t("users.single.receiveCodeSecondPart")}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <AppButton type="submit" onClick={onHide}>
          {t("common.cancel")}
        </AppButton>
        <AppButton
          type="submit"
          theme={theme}
          onClick={() => onSubmit(code.trim())}
        >
          {t("common.confirm")}
        </AppButton>
      </Modal.Footer>
    </Modal>
  );
};

export default CodeCheck;
