import { toast } from "react-toastify";
import { http } from "../../../helpers";
import actionTypes from "../../action-types";
import i18n from "i18next";

export const AdminDashboardAction = () => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.ADMIN_DASHBOARD_LOADING, payload: true });
    try {
      const response = await http.get("/admin/dashboard");
      const { data } = response.data;
      dispatch({ type: actionTypes.SET_ADMIN_DASHBOARD, payload: data });
      dispatch({ type: actionTypes.ADMIN_DASHBOARD_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: actionTypes.ADMIN_DASHBOARD_LOADING, payload: false });
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};
