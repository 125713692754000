import actionTypes from "../../../../action-types";


const initialState = {
    website: [],
    list: [],
    total: 0.00,
    chart: {
        series: [],
        sumDays: [],
        categories: new Set()
    },
    isLoading: false,
};

const reducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case actionTypes.SET_ZONES:
            const { website, data } = payload;
            return {
                ...state,
                website,
                ...data
            };
        case actionTypes.ZONES_REPORTS_LOADING:
            return {
                ...state,
                isLoading: payload
            };
        default:
            return {
                ...state
            };
    }
}
export default reducer;