import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import * as Yup from "yup";
import { history, zoneSize } from "../../../../helpers";
import AppButton from "../../../../components/button/Button";
import { connect } from "react-redux";
import {
  AddWebsiteZonesAction,
  GetWebsiteByIDAction,
} from "../../../../store/actions/websites";

const AddZone = ({ match, getWebsite, addZone, website }) => {
  const { id } = match.params;
  const [t] = useTranslation();
  const handleAddZone = (values, setValues) => {
    const zones = [...values.zones];
    zones.push({ name: "", size: "", script: "", status: "" });
    setValues({ ...values, zones });
  };
  const handleDeleteZone = (index, values, setValues) => {
    const zones = [...values.zones];
    zones.splice(index, 1);
    setValues({ ...values, zones });
  };
  useEffect(() => {
    getWebsite(id);
  }, [id, getWebsite]);
  const initialValues = {
    domain: "",
    zones: [{ name: "", size: "", script: "", status: "" }],
  };
  const onSubmit = (fields, resetForm) => {
    const zones = [];
    fields.zones.forEach((element) => {
      const zoneSizeList = zoneSize.find(
        (item) => item.id === Number(element.size)
      );
      let elemSize = { ...element };
      elemSize.size = zoneSizeList;
      zones.push(elemSize);
    });
    addZone(id, zones, () => history.push(`/admin/websites/${id}`));
  };
  const zoneSizeList = zoneSize.map((item) => {
    return (
      <option key={item.id} label={item.name} value={item.id}>
        {item.name}
      </option>
    );
  });
  const validationSchema = Yup.object().shape({
    zones: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(t("zone.validations.name")),
        size: Yup.string().required(t("zone.validations.size")),
        // x: Yup.string().when("size", {
        //   is: (value) => value && value === "1",
        //   then: Yup.string().required("Width is required"),
        //   otherwise: Yup.string(),
        // }),
        // y: Yup.string().when("size", {
        //   is: (value) => value && value === "1",
        //   then: Yup.string().required("height is required"),
        //   otherwise: Yup.string(),
        // }),
        script: Yup.string().required(t("zone.validations.script")),
        status: Yup.mixed().oneOf(["approved", "pending", "canceled"]),
      })
    ),
  });
  return (
    <div className="container-fluid">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>{t("zone.addZones")}</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/admin">{t("header.label.home")}</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/admin/websites">
                    {t("dashboard.website.list")}
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to={`/admin/websites/${id}`}>{website.url}</Link>
                </li>
                <li className="breadcrumb-item active">{t("zone.addZones")}</li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <div className="container-fluid ml-0">
        <div className="card m-3">
          <div className="card-body">
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={(fields, { resetForm }) => onSubmit(fields, resetForm)}
            >
              {({ errors, values, touched, setValues }) => {
                return (
                  <Form>
                    <div className="list-group list-group-flush">
                      <FieldArray name="zones">
                        {() =>
                          values.zones.map((zone, i) => {
                            const zonesErrors =
                              errors.zones?.length && errors.zones[i]
                                ? errors.zones[i]
                                : {};
                            const zonesTouched =
                              touched.zones?.length && touched.zones[i]
                                ? touched.zones[i]
                                : {};
                            return (
                              <div
                                key={i}
                                className={`row justify-content-between border-bottom mb-2 zone-block`}
                              >
                                <div className="col-md-2 col-sm-12 form-group">
                                  <label>{t("zone.name")}</label>
                                  <Field
                                    name={`zones.${i}.name`}
                                    type="text"
                                    placeholder={t("zone.name")}
                                    className={
                                      "form-control" +
                                      (zonesErrors.name && zonesTouched.name
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name={`zones.${i}.name`}
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                                <div className="col-md-2 col-sm-12 form-group">
                                  <label>{t("zone.size")}</label>
                                  <Field name="zones">
                                    {({ field }) => {
                                      return (
                                        <select
                                          {...field}
                                          name={`zones.${i}.size`}
                                          value={zone.size}
                                          type="text"
                                          className={
                                            "form-control" +
                                            (zonesErrors.size &&
                                            zonesTouched.size
                                              ? " is-invalid"
                                              : "")
                                          }
                                        >
                                          <option value="">
                                            {t("zone.size")}
                                          </option>
                                          {zoneSizeList}
                                        </select>
                                      );
                                    }}
                                  </Field>
                                  <ErrorMessage
                                    name={`zones.${i}.size`}
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                                <div className="col-md-3 col-sm-12 form-group">
                                  <label>{t("zone.script")}</label>
                                  <Field
                                    name={`zones.${i}.script`}
                                    as="textarea"
                                    placeholder={t("zone.script")}
                                    className={
                                      "form-control" +
                                      (zonesErrors.script && zonesTouched.script
                                        ? " is-invalid"
                                        : "")
                                    }
                                  />
                                  <ErrorMessage
                                    name={`zones.${i}.script`}
                                    component="div"
                                    className="invalid-feedback"
                                  />
                                </div>
                                <div className="form-group col-md-2">
                                  <label>{t("zone.status")}</label>
                                  <div className="form-check">
                                    <Field
                                      name={`zones.${i}.status`}
                                      id={`approved-${i}`}
                                      className="form-check-input"
                                      type="radio"
                                      value="approved"
                                    />

                                    <label
                                      htmlFor={`approved-${i}`}
                                      className="form-check-label"
                                    >
                                      {t("zone.statuses.approved")}
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <Field
                                      name={`zones.${i}.status`}
                                      type="radio"
                                      id={`pending-${i}`}
                                      className="form-check-input"
                                      value="pending"
                                    />
                                    <label
                                      htmlFor={`pending-${i}`}
                                      className="form-check-label"
                                    >
                                      {t("zone.statuses.pending")}
                                    </label>
                                  </div>
                                  <div className="form-check">
                                    <Field
                                      name={`zones.${i}.status`}
                                      type="radio"
                                      id={`canceled-${i}`}
                                      className="form-check-input"
                                      value="canceled"
                                    />
                                    <label
                                      htmlFor={`canceled-${i}`}
                                      className="form-check-label"
                                    >
                                      {t("zone.statuses.canceled")}
                                    </label>
                                  </div>
                                </div>
                                <div className="col-md-2 col-sm-12 form-group d-flex align-items-center pt-2">
                                  <AppButton
                                    disabled={i === 0}
                                    icon="close"
                                    theme="danger"
                                    onClick={() =>
                                      handleDeleteZone(i, values, setValues)
                                    }
                                  >
                                    {t("zone.delete")}
                                  </AppButton>
                                </div>
                              </div>
                            );
                          })
                        }
                      </FieldArray>
                      <div className="form-group">
                        <div className="col p-0">
                          <AppButton
                            onClick={() => handleAddZone(values, setValues)}
                          >
                            <i className="bx bx-plus"></i>
                            {t("zone.add")}
                          </AppButton>
                        </div>
                      </div>
                      <div className="form-group text-right">
                        <div className="col p-0">
                          <AppButton
                            type="submit"
                            theme="primary"
                            disabled={
                              !values.zones.length || Object.keys(errors).length
                            }
                          >
                            {t("zone.save")}
                          </AppButton>
                        </div>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  website: state.website,
});
const mapDispatchToProps = (dispatch) => ({
  addZone: (id, data, cb) => dispatch(AddWebsiteZonesAction(id, data, cb)),
  getWebsite: (id) => dispatch(GetWebsiteByIDAction(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(AddZone);
