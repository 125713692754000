import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import authReducer from "./reducers/auth";
import reportReducer from "./reducers/reports";
import websitesReportReducer from "./reducers/reports/websites";
import websiteReportReducer from "./reducers/reports/websites/single";
import zonesReportReducer from "./reducers/reports/websites/zones";
import websitesReducer from "./reducers/websites";
import thunk from "redux-thunk";
import websiteReducer from "./reducers/websites/single";
import referralReducer from "./reducers/referral";
import themeReducer from "./reducers/theme";
import adminUsersReducer from "./reducers/admin/users";
import adminSingleUserReducer from "./reducers/admin/adminSingleUser";
import userSingleReducer from "./reducers/user";
import adminSingleZoneReducer from "./reducers/admin/adminSingleZone";
import adminSingleSourceReducer from "./reducers/admin/adminSingleSource";
import profileReducer from "./reducers/profile";
import questionReducer from "./reducers/questions";
import adminScriptsReducer from "./reducers/admin/script/scripts";
import adminBonusReducer from "./reducers/admin/bonus";
import adminWebsiteScriptReducer from "./reducers/admin/script/get";
import adminZonesReducer from "./reducers/admin/zones";
import adminSourcesReducer from "./reducers/admin/sources";
import adminDashboardReducer from "./reducers/admin/dashboard";
import adminZendReducer from "./reducers/admin/zen";
import adminExportReducer from "./reducers/admin/export";
import adminActiveWebsitesReducer from "./reducers/admin/websites/active";
import adminWebsitesReducer from "./reducers/admin/websites";

const middlewares = [thunk];
const rootReducer = combineReducers({
  auth: authReducer,
  reports: reportReducer,
  websitesReports: websitesReportReducer,
  websiteReport: websiteReportReducer,
  zonesReports: zonesReportReducer,
  websites: websitesReducer,
  website: websiteReducer,
  referral: referralReducer,
  theme: themeReducer,
  adminUsers: adminUsersReducer,
  adminSingleUser: adminSingleUserReducer,
  userSingle: userSingleReducer,
  adminSingleZone: adminSingleZoneReducer,
  adminSingleSource: adminSingleSourceReducer,
  adminActiveWebsites: adminActiveWebsitesReducer,
  adminWebsites: adminWebsitesReducer,
  profile: profileReducer,
  questions: questionReducer,
  adminScripts: adminScriptsReducer,
  adminBonus: adminBonusReducer,
  adminWebsiteScript: adminWebsiteScriptReducer,
  adminZones: adminZonesReducer,
  adminSources: adminSourcesReducer,
  adminDashboard: adminDashboardReducer,
  adminZen: adminZendReducer,
  adminExport: adminExportReducer,
  //adminWebsites: adminWebsitesReducer
});
const composeEnhancers =
  process.env.NODE_ENV === "development" &&
  typeof window === "object" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : compose;
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middlewares))
);

export default store;
