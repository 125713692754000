import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../../contexts/ThemeContext";
import AppImportFile from "../importFile";

const Additional = ({ isActive, refreshCurrency }) => {
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  const { darkMode } = theme;

  return (
    <div className={`tab-pane ${isActive ? "active" : ""}`}>
      <div className={`row mt-4 pt-4 ${darkMode ? "border-secondary" : ""}`}>
        <div className="col-xl-12">
          <h4>Import DC xlsx</h4>
          <AppImportFile
            url="/sources/dc-import-xlsx"
            fileNameRegex={/\.xlsx$/i}
            fileTypeError={t("import.message.typeError")}
          />
        </div>
      </div>
    </div>
  );
};

export default Additional;
