import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Pagination from "react-js-pagination";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import AppButton from "../../../components/button/Button";
import DateFilter from "../../../components/filter/dateFilter";
import NoData from "../../../components/noData";
import PageLoading from "../../../components/page-loading/PageLoading";
import {
  impressionFormat,
  showAmountOfNumber,
  zoneSize,
} from "../../../helpers";
import {
  AdminZenBySizeGetAction,
  ExportZoneSizeXlsxAction,
  SetZenBySizePeriodAction,
  SetZenByZoneSizeAction,
} from "../../../store/actions/admin/zen";
import echo from "../../../helpers/echo";

const AdminZenBySize = ({
  getZenBySize,
  isLoading,
  zenBySize,
  current_page,
  per_page,
  total,
  period,
  zoneSizeId,
  userId,
  exportXlsx,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [date, setDate] = useState(period);

  const setDatePeriod = (date) => {
    dispatch(SetZenBySizePeriodAction(date));
    setDate(date);
  };

  useEffect(() => {
    echo(localStorage.getItem("dc-token")).private(`Export.Loaded.${userId}`);
    return () => {
      echo(localStorage.getItem("dc-token")).leave(`Export.Loaded.${userId}`);
    };
  }, [userId]);

  const handleExport = (zoneSizeId) => {
    if (zoneSizeId && period) {
      exportXlsx(zoneSizeId, period);
    }
  };

  const loadZen = useCallback(
    (sizeId) => {
      if (!sizeId) {
        setError(true);
      }
      if (period && sizeId) {
        getZenBySize(period, sizeId);
      }
    },
    [getZenBySize, period]
  );

  const zoneSizeList = zoneSize.map((item) => {
    return (
      <option key={item.id} label={item.name} value={item.id}>
        {item.name}
      </option>
    );
  });
  const { websites, range } = zenBySize;
  let template;
  if (isLoading) {
    template = <PageLoading />;
  } else {
    template = (
      <>
        {Object.keys(websites).length > 0 && (
          <div className="row">
            <div className="col-12">
              <div className="card table-responsive">
                <div className="card-header">
                  <h4>
                    {t("menusidebar.label.zenBySizes")}{" "}
                    {range.start ? (
                      <span>{`${range.start} - ${range.end}`}</span>
                    ) : null}
                  </h4>
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>{t("dashboard.website.name")}</th>
                        <th>{t("dashboard.website.impressions")}</th>
                        <th>{t("dashboard.website.profit")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {websites.data.map((website, index) => {
                        return (
                          <tr key={index}>
                            <td>{website.website_id}</td>
                            <td>{website.url}</td>
                            <td>{impressionFormat(website.impressions)}</td>
                            <td>{showAmountOfNumber(website.sum_rub)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }

  return (
    <>
      <div className="container-fluid">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>{t("menusidebar.label.zenBySizes")}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/admin">{t("header.label.home")}</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {t("menusidebar.label.zen")}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <DateFilter handleApply={(date) => setDatePeriod(date)} period={date} />
        <div className="row pt-1">
          <div className="col-12 d-flex direction-row">
            <div className="pl-0 col-md-4 col-sm-8 form-group">
              <select
                name={`zone.size`}
                type="text"
                className={"form-control" + (error ? " is-invalid" : "")}
                value={zoneSize.find((item) => item.id === zoneSizeId)?.id}
                onChange={(e) =>
                  dispatch(SetZenByZoneSizeAction(+e.target.value))
                }
              >
                <option value="">{t("zone.size")}</option>
                {zoneSizeList}
              </select>
            </div>
            <div className="pl-0 col-md-4 col-sm-8 form-group">
              <AppButton
                icon="filter"
                theme="primary"
                onClick={() => loadZen(zoneSizeId)}
              >
                {t("websites.button.filter")}
              </AppButton>
              <AppButton
                icon="export"
                className="ml-3"
                theme="primary"
                onClick={() => handleExport(zoneSizeId)}
              >
                {t("export.label.export")}
              </AppButton>
            </div>
          </div>
        </div>
        {template}
        {!Object.keys(websites).length && !isLoading && <NoData />}
        {total > per_page && (
          <Pagination
            activePage={current_page ? current_page : 0}
            itemsCountPerPage={per_page ? per_page : 0}
            totalItemsCount={total ? total : 0}
            onChange={(pageNumber) =>
              getZenBySize(period, zoneSizeId, pageNumber)
            }
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText={t("common.firstPage")}
            lastPageText={t("common.lastPage")}
          />
        )}
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  const zenBySize = state.adminZen.zenBySize;
  const { current_page, per_page, total } = zenBySize.websites;
  const { isLoading } = zenBySize;
  return {
    isLoading,
    darkMode: state.theme.darkMode,
    period: state.adminZen.period,
    zoneSizeId: state.adminZen.zoneSizeId,
    userId: state.auth.currentUser.id,
    zenBySize,
    current_page,
    per_page,
    total,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getZenBySize: (period, zoneSizeId, pageNumber = 1) =>
      dispatch(AdminZenBySizeGetAction(period, zoneSizeId, pageNumber)),
    exportXlsx: (zoneSizeId, period) =>
      dispatch(ExportZoneSizeXlsxAction(zoneSizeId, period)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminZenBySize);
