import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AppButton from "../button/Button";

const ReconfirmModal = ({
  show,
  title,
  onHide,
  onSubmit,
  theme = "danger",
}) => {
  const [t] = useTranslation();
  return (
    <Modal show={show} onHide={onHide} size="lg">
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
        <button type="button" className="close" onClick={onHide}>
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>

      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="form-group">
                <label>{t("common.confirmMessage")}</label>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <AppButton type="submit" onClick={onHide}>
          {t("common.cancel")}
        </AppButton>
        <AppButton type="submit" theme={theme} onClick={onSubmit}>
          {t("common.confirm")}
        </AppButton>
      </Modal.Footer>
    </Modal>
  );
};
export default ReconfirmModal;
