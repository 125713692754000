import actionTypes from '../../action-types';
import { http } from '../../../helpers';
import i18n from 'i18next';
import { toast } from 'react-toastify';
const recoverPassworAction = (token, password) => (dispatch) => {
  dispatch({ type: actionTypes.AUTH_LOADING, loading: true });
  http
    .post(`/change-pass/${token}`, {
      password: password
    })
    .then(response => {
      dispatch({ type: actionTypes.AUTH_LOADING, loading: false });
      toast.success(`${i18n.t('recover.recoverSuccess')}`);
    })
    .catch(err => {
      toast.error(`${i18n.t('confirm.error.incorrect-token')}`);
      dispatch({ type: actionTypes.AUTH_LOADING, loading: false });
    })

}

export default recoverPassworAction;