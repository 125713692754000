import { Formik, Form, Field, FieldArray, ErrorMessage } from "formik";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import AppButton from "../../../components/button/Button";
import CongratulationsModal from "../../../components/modals/congratulationsModal";
import { zoneSize } from "../../../helpers";
import Yup from "../../../helpers/yup";
import {
  AddWebsiteAction,
  SetShowCongradulationsModalAction,
} from "../../../store/actions/websites";

const AddWebsite = ({ addWebsite, showCongratulationsModal }) => {
  const [t] = useTranslation();
  const dispatch = useDispatch();
  const [firstClick, setFirstClick] = useState(false);

  const handleAddZone = (values, setValues) => {
    const zones = [...values.zones];
    zones.push({ name: "", size: "" });
    setValues({ ...values, zones });
  };
  const handleDeleteZone = (index, values, setValues) => {
    const zones = [...values.zones];
    zones.splice(index, 1);
    setValues({ ...values, zones });
  };

  const onSubmit = (fields, resetForm) => {
    const zones = [];
    fields.zones.forEach((element) => {
      const zoneSizeList = zoneSize.find(
        (item) => item.id === Number(element.size)
      );
      let elemSize = { ...element };
      elemSize.size = zoneSizeList;
      zones.push(elemSize);
    });

    addWebsite(fields.domain.trim(), zones, resetForm);
    setFirstClick(true);
  };
  useEffect(() => {
    if (showCongratulationsModal && !firstClick) {
      dispatch(SetShowCongradulationsModalAction(false));
    }
  }, [dispatch, showCongratulationsModal, firstClick]);
  const zoneSizeList = zoneSize.map((item) => {
    return (
      <option key={item.id} label={item.name} value={item.id}>
        {item.name}
      </option>
    );
  });
  const initialValues = {
    domain: "",
    zones: [{ name: "", size: "" }],
  };

  const validationSchema = Yup.object().shape({
    domain: Yup.string()
      .required(t("login.validation.required"))
      .domain("domain", t("website.validation.domain")),
    zones: Yup.array().of(
      Yup.object().shape({
        name: Yup.string().required(t("website.validation.zoneNameRequired")),
        size: Yup.string().required(t("website.validation.zoneSizeRequired")),
        /*x: Yup.string().when("size", {
                    is: value => value && value === '1',
                    then: Yup.string()
                        .required('Width is required'),
                    otherwise: Yup.string()
                }),
                y: Yup.string().when("size", {
                    is: value => value && value === '1',
                    then: Yup.string()
                        .required('height is required'),
                    otherwise: Yup.string()
                })*/
      })
    ),
  });
  return (
    <div className="container-fluid">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>{t("website.addNew")}</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/user">{t("header.label.home")}</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/user/websites">
                    {" "}
                    {t("dashboard.website.list")}
                  </Link>
                </li>
                <li className="breadcrumb-item active">
                  {t("website.addNew")}
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <div className="container ml-0">
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          validateOnBlur={false}
          validateOnChange={false}
          onSubmit={(fields, { resetForm }) => onSubmit(fields, resetForm)}
        >
          {({ errors, values, touched, setValues }) => {
            return (
              <Form>
                <div className="card m-3">
                  <div className="card-body border-bottom">
                    <div className="row">
                      <div className="col-md-4">
                        <h5 className="card-header border-0">
                          {t("website.label.domain")}
                        </h5>
                        <div className="list-group list-group-flush">
                          <div className="list-group-item">
                            <div className="form-row">
                              <div className="form-group">
                                <label className="form-label">
                                  {t("website.label.domain")}
                                </label>
                                <Field
                                  name="domain"
                                  type="text"
                                  className={
                                    "form-control" +
                                    (errors.domain && touched.domain
                                      ? " is-invalid"
                                      : "")
                                  }
                                />
                                <ErrorMessage
                                  name="domain"
                                  component="div"
                                  className="invalid-feedback"
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-8">
                        <h5 className="card-header border-0">
                          {t("zones.title")}
                        </h5>
                        <FieldArray name="zones">
                          {() =>
                            values.zones.map((zone, i) => {
                              const zonesErrors =
                                errors.zones?.length && errors.zones[i]
                                  ? errors.zones[i]
                                  : {};
                              const zonesTouched =
                                touched.zones?.length && touched.zones[i]
                                  ? touched.zones[i]
                                  : {};
                              return (
                                <div
                                  key={i}
                                  className="list-group list-group-flush border-bottom"
                                >
                                  <div className="list-group-item">
                                    <div className="form-row">
                                      <div className="form-group col-md-6 col-12">
                                        <label>Name</label>
                                        <Field
                                          name={`zones.${i}.name`}
                                          type="text"
                                          className={
                                            "form-control" +
                                            (zonesErrors.name &&
                                            zonesTouched.name
                                              ? " is-invalid"
                                              : "")
                                          }
                                        />
                                        <ErrorMessage
                                          name={`zones.${i}.name`}
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                      </div>
                                      <div className="form-group col-md-6 col-12">
                                        <label>Size</label>
                                        <Field name="zoness">
                                          {({ field }) => {
                                            return (
                                              <select
                                                {...field}
                                                name={`zones.${i}.size`}
                                                value={zone.size}
                                                type="text"
                                                className={
                                                  "form-control" +
                                                  (zonesErrors.size &&
                                                  zonesTouched.size
                                                    ? " is-invalid"
                                                    : "")
                                                }
                                              >
                                                <option value=""></option>
                                                {zoneSizeList}
                                              </select>
                                            );
                                          }}
                                        </Field>
                                        <ErrorMessage
                                          name={`zones.${i}.size`}
                                          component="div"
                                          className="invalid-feedback"
                                        />
                                        {/* Custom Size {
                                            <>
                                                <div className="d-flex mt-1 align-items-center">
                                                    <div>
                                                        <Field name={`zones.${i}.x`} type="text" className={'form-control' + (zonesErrors.x && zonesTouched.x ? ' is-invalid' : '')} />
                                                        <ErrorMessage name={`zones.${i}.x`} component="div" className="invalid-feedback" style={{ position: 'absolute', bottom: '-20px' }} />
                                                    </div>
                                                    <div className="ml-2 mr-2">-</div>
                                                    <div>
                                                        <Field name={`zones.${i}.y`} type="text" className={'form-control' + (zonesErrors.y && zonesTouched.y ? ' is-invalid' : '')} />
                                                        <ErrorMessage name={`zones.${i}.y`} component="div" className="invalid-feedback" style={{ position: 'absolute', bottom: '-20px' }} />
                                                    </div>
                                                </div>

                                            </>
                                        } */}
                                      </div>
                                    </div>
                                    <div className=" form-group mt-4 text-right">
                                      {i !== 0 && (
                                        <AppButton
                                          className="btn btn-danger btn-sm"
                                          onClick={() =>
                                            handleDeleteZone(
                                              i,
                                              values,
                                              setValues
                                            )
                                          }
                                        >
                                          {t("zone.delete")}
                                        </AppButton>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              );
                            })
                          }
                        </FieldArray>
                        <div className="col-12 form-group mt-4 text-right">
                          <AppButton
                            icon="plus"
                            onClick={() => handleAddZone(values, setValues)}
                          >
                            {t("website.label.addZone")}
                          </AppButton>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-footer text-center border-top-0">
                    <button type="submit" className="btn btn-primary mr-1">
                      {t("website.add")}
                    </button>
                    <button className="btn btn-secondary mr-1" type="reset">
                      {t("website.reset")}
                    </button>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
      <CongratulationsModal
        show={showCongratulationsModal}
        message={t("bonus.winMessage")}
        onHide={() => !showCongratulationsModal}
        imageLink={"/img/bonus.jpg"}
        link={"/user/bonus"}
        linkTitle={t("bonus.spin")}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  showCongratulationsModal: state.userSingle.showModal,
});
const mapDispatchToProps = (dispatch) => {
  return {
    addWebsite: (domain, zones, reset) =>
      dispatch(AddWebsiteAction(domain, zones, reset)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AddWebsite);
