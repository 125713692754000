import React, { createContext } from "react";
import { connect } from "react-redux";
import { ToggleDarkmode, ToggleMenuSidebar } from "../store/actions/theme";
export const ThemeContext = createContext();

const ThemeProviderConatainer = ({ children, darkMode, toggleDarkMode, isMenuSidebarCollapsed, toggleMenuSidebar, width }) => {
    return <ThemeContext.Provider
        value={{ darkMode, toggleDarkMode, isMenuSidebarCollapsed, toggleMenuSidebar, width }}
    >{children}
    </ThemeContext.Provider>;
}
const mapStateToProps = (state) => {
    return {
        user: state.auth.currentUser,
        darkMode: state.theme.darkMode,
        width: state.theme.width,
        isMenuSidebarCollapsed: state.theme.isMenuSidebarCollapsed
    }
};

const mapDispatchToProps = (dispatch) => ({
    toggleDarkMode: () => dispatch(ToggleDarkmode()),
    toggleMenuSidebar: () => dispatch(ToggleMenuSidebar())

});
export const ThemProvider = connect(mapStateToProps, mapDispatchToProps)(ThemeProviderConatainer);
