import React, { useEffect, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import AppButton from "../button/Button";
import ReconfirmModal from "../modals/reconfirm";
import {
  ChangeQuestionStatusAction,
  DeleteQuestionsAction,
  GetQuestionsForAdminAction,
} from "../../store/actions/admin/questions";

const Questions = ({
  isActive,
  questions,
  getQuestions,
  deleteQuestion,
  changeQuestionStatus,
}) => {
  const [t, i18n] = useTranslation();
  const [inEditMode, setInEditMode] = useState(null);
  const [unActiveQuestions, setUnActiveQuestions] = useState([]);
  const lang = i18n.language;

  const [reconfirmModal, setReconfirmModal] = useState({
    show: false,
    title: null,
    onHide: null,
    onSubmit: null,
    theme: "",
  });
  const handleCloseReconfirmModal = () => {
    setReconfirmModal({
      title: "",
      show: false,
      onSubmit: () => {},
      onHide: () => {},
    });
  };

  useEffect(() => {
    getQuestions();
  }, [getQuestions]);

  const handleEditQuestion = useCallback((e, id) => {
    e.stopPropagation();
    setInEditMode(id);
  }, []);

  const handleDeleteQuestion = useCallback(
    (e, id) => {
      e.stopPropagation();
      setReconfirmModal({
        title: t("common.delete"),
        show: true,
        onSubmit: () => {
          deleteQuestion(id);
          handleCloseReconfirmModal();
        },
        onHide: () => handleCloseReconfirmModal(),
        theme: "success",
      });
    },
    [t, deleteQuestion]
  );
  const checkQuestionStatus = (questionId, isActive) => {
    let status = !!isActive;
    const questionInUnActive = unActiveQuestions.findIndex(
      (item) => item?.questionId === questionId
    );
    if (unActiveQuestions[questionInUnActive]) {
      status = unActiveQuestions[questionInUnActive].isActive;
    }
    return status;
  };
  const handleChangeQuestionStatus = (questionId, isActive) => {
    let status = isActive;
    const issetQuestion = unActiveQuestions.findIndex(
      (item) => item?.questionId === questionId
    );
    if (unActiveQuestions[issetQuestion]) {
      unActiveQuestions[issetQuestion].isActive =
        !unActiveQuestions[issetQuestion].isActive;
      setUnActiveQuestions([...unActiveQuestions]);
      status = !!unActiveQuestions[issetQuestion].isActive;
    } else {
      const unActiveUser = {
        questionId: questionId,
        isActive: isActive,
      };
      setUnActiveQuestions([...unActiveQuestions, unActiveUser]);
    }
    changeQuestionStatus(questionId, { status });
  };

  const questionsList = questions.map((question, index) => {
    return (
      <tr key={index}>
        <td>{question.id}</td>
        <td>{question.text[lang] || ""}</td>
        <td>
          <div className="custom-control custom-switch">
            <input
              id={`activate-${question.id}`}
              type="checkbox"
              className="custom-control-input"
              checked={checkQuestionStatus(question.id, question.status)}
              onChange={() =>
                handleChangeQuestionStatus(question.id, !!!question.status)
              }
            />
            <label
              className="custom-control-label"
              htmlFor={`activate-${question.id}`}
            ></label>
          </div>
        </td>
        <td>
          <div className="dropdown">
            <AppButton
              theme=""
              onClick={(e) => handleEditQuestion(e, question.id)}
            >
              <i className="fas fa-ellipsis-v"></i>
            </AppButton>
            <div
              className={`dropdown-menu dropdown-menu-right ${
                inEditMode === question.id ? "show" : ""
              }`}
              aria-labelledby="dropdownMenuButton"
            >
              <Link
                className="dropdown-item"
                to={`/admin/questions/${question.id}`}
              >
                <i className="fas fa-edit mr-2"></i>
                {t("common.edit")}
              </Link>
              <AppButton
                theme=""
                className="dropdown-item"
                onClick={(e) => handleDeleteQuestion(e, question.id)}
              >
                <i className="fas fa-trash-alt mr-2"></i>
                {t("common.delete")}
              </AppButton>
            </div>
          </div>
        </td>
      </tr>
    );
  });

  return (
    <>
      <div className={`tab-pane ${isActive ? "active" : ""}`}>
        <div className="horizontal">
          <div className="col-xl-12 col-sm-12 col-12">
            <div className="card">
              <div className="card-body table-responsive p-0">
                <table className="table table-striped table-valign-middle table-sm">
                  <thead>
                    <tr>
                      <th>{t("questions.id")}</th>
                      <th>{t("questions.text")}</th>
                      <th>{t("questions.status")}</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>{questionsList}</tbody>
                </table>
              </div>
            </div>
          </div>
          <Link to={`/admin/questions/add`} className="btn btn-primary">
            <i className="fa fa-plus-circle mr-2" />
            {t("questions.add")}
          </Link>
        </div>
      </div>
      {reconfirmModal.show && (
        <ReconfirmModal
          title={reconfirmModal.title}
          show={reconfirmModal.show}
          onHide={reconfirmModal.onHide}
          onSubmit={reconfirmModal.onSubmit}
          theme={reconfirmModal.theme}
        />
      )}
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    questions: state.questions.list,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getQuestions: () => dispatch(GetQuestionsForAdminAction()),
    deleteQuestion: (id) => dispatch(DeleteQuestionsAction(id)),
    changeQuestionStatus: (id, status) =>
      dispatch(ChangeQuestionStatusAction(id, status)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Questions);
