export const entities = {
    //juridical: "users.entity.juridical",
    legal: "users.entity.legal",
    individual: "users.entity.individual",
    physical: "users.entity.physical"
}
export const userRoles = {
    1: "users.roles.admin",
    2: "users.roles.manager",
    3: "users.roles.user",
    4: "users.roles.owner",
}

export const countries = [
    {
        "name": "ru",
        "full_name": "Russia",
        "flags": "https://flagcdn.com/ru.svg"
    },
    {
        "name": "uz",
        "full_name": "Uzbekistan",
        "flags": "https://flagcdn.com/uz.svg"
    },
    {
        "name": "by",
        "full_name": "Belarus",
        "flags": "https://flagcdn.com/by.svg"
    },
    {
        "name": "kz",
        "full_name": "Kazakhstan",
        "flags": "https://flagcdn.com/kz.svg"
    },
    {
        "name": "kg",
        "full_name": "Kyrgyzstan",
        "flags": "https://flagcdn.com/kg.svg"
    },
    {
        "name": "tj",
        "full_name": "Tajikistan",
        "flags": "https://flagcdn.com/tj.svg"
    },
    {
        "name": "am",
        "full_name": "Armenia",
        "flags": "https://flagcdn.com/am.svg"
    },
    {
        "name": "md",
        "full_name": "Moldova",
        "flags": "https://flagcdn.com/md.svg"
    },
    {
        "name": "gs",
        "full_name": "South Georgia",
        "flags": "https://flagcdn.com/gs.svg"
    },
    {
        "name": "ua",
        "full_name": "Ukraine",
        "flags": "https://flagcdn.com/ua.svg"
    },
    {
        "name": "az",
        "full_name": "Azerbaijan",
        "flags": "https://flagcdn.com/az.svg"
    },
    {
        "name": "af",
        "full_name": "Afghanistan",
        "flags": "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_the_Taliban.svg"
    },
    {
        "name": "sd",
        "full_name": "Sudan",
        "flags": "https://flagcdn.com/sd.svg"
    },
    {
        "name": "bi",
        "full_name": "Burundi",
        "flags": "https://flagcdn.com/bi.svg"
    },
    {
        "name": "mx",
        "full_name": "Mexico",
        "flags": "https://flagcdn.com/mx.svg"
    },
    {
        "name": "cu",
        "full_name": "Cuba",
        "flags": "https://flagcdn.com/cu.svg"
    },
    {
        "name": "rs",
        "full_name": "Serbia",
        "flags": "https://flagcdn.com/rs.svg"
    },
    {
        "name": "mc",
        "full_name": "Monaco",
        "flags": "https://flagcdn.com/mc.svg"
    },
    {
        "name": "bt",
        "full_name": "Bhutan",
        "flags": "https://flagcdn.com/bt.svg"
    },
    {
        "name": "gy",
        "full_name": "Guyana",
        "flags": "https://flagcdn.com/gy.svg"
    },
    {
        "name": "ba",
        "full_name": "Bosnia and Herzegovina",
        "flags": "https://flagcdn.com/ba.svg"
    },
    {
        "name": "bn",
        "full_name": "Brunei",
        "flags": "https://flagcdn.com/bn.svg"
    },
    {
        "name": "pk",
        "full_name": "Pakistan",
        "flags": "https://flagcdn.com/pk.svg"
    },
    {
        "name": "ke",
        "full_name": "Kenya",
        "flags": "https://flagcdn.com/ke.svg"
    },
    {
        "name": "pr",
        "full_name": "Puerto Rico",
        "flags": "https://flagcdn.com/pr.svg"
    },
    {
        "name": "so",
        "full_name": "Somalia",
        "flags": "https://flagcdn.com/so.svg"
    },
    {
        "name": "sj",
        "full_name": "Svalbard and Jan Mayen",
        "flags": "https://flagcdn.com/sj.svg"
    },
    {
        "name": "sl",
        "full_name": "Sierra Leone",
        "flags": "https://flagcdn.com/sl.svg"
    },
    {
        "name": "pf",
        "full_name": "French Polynesia",
        "flags": "https://flagcdn.com/pf.svg"
    },
    {
        "name": "ck",
        "full_name": "Cook Islands",
        "flags": "https://flagcdn.com/ck.svg"
    },
    {
        "name": "pe",
        "full_name": "Peru",
        "flags": "https://flagcdn.com/pe.svg"
    },
    {
        "name": "bv",
        "full_name": "Bouvet Island",
        "flags": "https://flagcdn.com/bv.svg"
    },
    {
        "name": "mp",
        "full_name": "Northern Mariana Islands",
        "flags": "https://flagcdn.com/mp.svg"
    },
    {
        "name": "ao",
        "full_name": "Angola",
        "flags": "https://flagcdn.com/ao.svg"
    },
    {
        "name": "cg",
        "full_name": "Republic of the Congo",
        "flags": "https://flagcdn.com/cg.svg"
    },
    {
        "name": "ss",
        "full_name": "South Sudan",
        "flags": "https://flagcdn.com/ss.svg"
    },
    {
        "name": "mf",
        "full_name": "Saint Martin",
        "flags": "https://flagcdn.com/mf.svg"
    },
    {
        "name": "tr",
        "full_name": "Turkey",
        "flags": "https://flagcdn.com/tr.svg"
    },
    {
        "name": "ai",
        "full_name": "Anguilla",
        "flags": "https://flagcdn.com/ai.svg"
    },
    {
        "name": "kn",
        "full_name": "Saint Kitts and Nevis",
        "flags": "https://flagcdn.com/kn.svg"
    },
    {
        "name": "aw",
        "full_name": "Aruba",
        "flags": "https://flagcdn.com/aw.svg"
    },
    {
        "name": "tc",
        "full_name": "Turks and Caicos Islands",
        "flags": "https://flagcdn.com/tc.svg"
    },
    {
        "name": "tw",
        "full_name": "Taiwan",
        "flags": "https://flagcdn.com/tw.svg"
    },
    {
        "name": "se",
        "full_name": "Sweden",
        "flags": "https://flagcdn.com/se.svg"
    },
    {
        "name": "lr",
        "full_name": "Liberia",
        "flags": "https://flagcdn.com/lr.svg"
    },
    {
        "name": "ve",
        "full_name": "Venezuela",
        "flags": "https://flagcdn.com/ve.svg"
    },
    {
        "name": "vi",
        "full_name": "United States Virgin Islands",
        "flags": "https://flagcdn.com/vi.svg"
    },
    {
        "name": "bm",
        "full_name": "Bermuda",
        "flags": "https://flagcdn.com/bm.svg"
    },
    {
        "name": "al",
        "full_name": "Albania",
        "flags": "https://flagcdn.com/al.svg"
    },
    {
        "name": "hk",
        "full_name": "Hong Kong",
        "flags": "https://flagcdn.com/hk.svg"
    },
    {
        "name": "lu",
        "full_name": "Luxembourg",
        "flags": "https://flagcdn.com/lu.svg"
    },
    {
        "name": "er",
        "full_name": "Eritrea",
        "flags": "https://flagcdn.com/er.svg"
    },
    {
        "name": "co",
        "full_name": "Colombia",
        "flags": "https://flagcdn.com/co.svg"
    },
    {
        "name": "bq",
        "full_name": "Caribbean Netherlands",
        "flags": "https://flagcdn.com/bq.svg"
    },
    {
        "name": "mn",
        "full_name": "Mongolia",
        "flags": "https://flagcdn.com/mn.svg"
    },
    {
        "name": "ye",
        "full_name": "Yemen",
        "flags": "https://flagcdn.com/ye.svg"
    },
    {
        "name": "lb",
        "full_name": "Lebanon",
        "flags": "https://flagcdn.com/lb.svg"
    },
    {
        "name": "ag",
        "full_name": "Antigua and Barbuda",
        "flags": "https://flagcdn.com/ag.svg"
    },
    {
        "name": "vn",
        "full_name": "Vietnam",
        "flags": "https://flagcdn.com/vn.svg"
    },
    {
        "name": "pw",
        "full_name": "Palau",
        "flags": "https://flagcdn.com/pw.svg"
    },
    {
        "name": "je",
        "full_name": "Jersey",
        "flags": "https://flagcdn.com/je.svg"
    },
    {
        "name": "tt",
        "full_name": "Trinidad and Tobago",
        "flags": "https://flagcdn.com/tt.svg"
    },
    {
        "name": "il",
        "full_name": "Israel",
        "flags": "https://flagcdn.com/il.svg"
    },
    {
        "name": "bg",
        "full_name": "Bulgaria",
        "flags": "https://flagcdn.com/bg.svg"
    },
    {
        "name": "pt",
        "full_name": "Portugal",
        "flags": "https://flagcdn.com/pt.svg"
    },
    {
        "name": "gi",
        "full_name": "Gibraltar",
        "flags": "https://flagcdn.com/gi.svg"
    },
    {
        "name": "sm",
        "full_name": "San Marino",
        "flags": "https://flagcdn.com/sm.svg"
    },
    {
        "name": "sg",
        "full_name": "Singapore",
        "flags": "https://flagcdn.com/sg.svg"
    },
    {
        "name": "sx",
        "full_name": "Sint Maarten",
        "flags": "https://flagcdn.com/sx.svg"
    },
    {
        "name": "sa",
        "full_name": "Saudi Arabia",
        "flags": "https://flagcdn.com/sa.svg"
    },
    {
        "name": "gh",
        "full_name": "Ghana",
        "flags": "https://flagcdn.com/gh.svg"
    },
    {
        "name": "td",
        "full_name": "Chad",
        "flags": "https://flagcdn.com/td.svg"
    },
    {
        "name": "aq",
        "full_name": "Antarctica",
        "flags": "https://flagcdn.com/aq.svg"
    },
    {
        "name": "gb",
        "full_name": "United Kingdom",
        "flags": "https://flagcdn.com/gb.svg"
    },
    {
        "name": "pg",
        "full_name": "Papua New Guinea",
        "flags": "https://flagcdn.com/pg.svg"
    },
    {
        "name": "tf",
        "full_name": "French Southern and Antarctic Lands",
        "flags": "https://flagcdn.com/tf.svg"
    },
    {
        "name": "um",
        "full_name": "United States Minor Outlying Islands",
        "flags": "https://flagcdn.com/um.svg"
    },
    {
        "name": "bz",
        "full_name": "Belize",
        "flags": "https://flagcdn.com/bz.svg"
    },
    {
        "name": "km",
        "full_name": "Comoros",
        "flags": "https://flagcdn.com/km.svg"
    },
    {
        "name": "bf",
        "full_name": "Burkina Faso",
        "flags": "https://flagcdn.com/bf.svg"
    },
    {
        "name": "fo",
        "full_name": "Faroe Islands",
        "flags": "https://flagcdn.com/fo.svg"
    },
    {
        "name": "gn",
        "full_name": "Guinea",
        "flags": "https://flagcdn.com/gn.svg"
    },
    {
        "name": "in",
        "full_name": "India",
        "flags": "https://flagcdn.com/in.svg"
    },
    {
        "name": "cw",
        "full_name": "Curaçao",
        "flags": "https://flagcdn.com/cw.svg"
    },
    {
        "name": "tg",
        "full_name": "Togo",
        "flags": "https://flagcdn.com/tg.svg"
    },
    {
        "name": "tn",
        "full_name": "Tunisia",
        "flags": "https://flagcdn.com/tn.svg"
    },
    {
        "name": "bl",
        "full_name": "Saint Barthélemy",
        "flags": "https://flagcdn.com/bl.svg"
    },
    {
        "name": "id",
        "full_name": "Indonesia",
        "flags": "https://flagcdn.com/id.svg"
    },
    {
        "name": "fm",
        "full_name": "Micronesia",
        "flags": "https://flagcdn.com/fm.svg"
    },
    {
        "name": "at",
        "full_name": "Austria",
        "flags": "https://flagcdn.com/at.svg"
    },
    {
        "name": "cd",
        "full_name": "DR Congo",
        "flags": "https://flagcdn.com/cd.svg"
    },
    {
        "name": "yt",
        "full_name": "Mayotte",
        "flags": "https://flagcdn.com/yt.svg"
    },
    {
        "name": "re",
        "full_name": "Réunion",
        "flags": "https://flagcdn.com/re.svg"
    },
    {
        "name": "ro",
        "full_name": "Romania",
        "flags": "https://flagcdn.com/ro.svg"
    },
    {
        "name": "qa",
        "full_name": "Qatar",
        "flags": "https://flagcdn.com/qa.svg"
    },
    {
        "name": "lt",
        "full_name": "Lithuania",
        "flags": "https://flagcdn.com/lt.svg"
    },
    {
        "name": "cn",
        "full_name": "China",
        "flags": "https://flagcdn.com/cn.svg"
    },
    {
        "name": "nz",
        "full_name": "New Zealand",
        "flags": "https://flagcdn.com/nz.svg"
    },
    {
        "name": "nf",
        "full_name": "Norfolk Island",
        "flags": "https://flagcdn.com/nf.svg"
    },
    {
        "name": "mr",
        "full_name": "Mauritania",
        "flags": "https://flagcdn.com/mr.svg"
    },
    {
        "name": "fi",
        "full_name": "Finland",
        "flags": "https://flagcdn.com/fi.svg"
    },
    {
        "name": "cm",
        "full_name": "Cameroon",
        "flags": "https://flagcdn.com/cm.svg"
    },
    {
        "name": "hm",
        "full_name": "Heard Island and McDonald Islands",
        "flags": "https://flagcdn.com/hm.svg"
    },
    {
        "name": "dm",
        "full_name": "Dominica",
        "flags": "https://flagcdn.com/dm.svg"
    },
    {
        "name": "is",
        "full_name": "Iceland",
        "flags": "https://flagcdn.com/is.svg"
    },
    {
        "name": "om",
        "full_name": "Oman",
        "flags": "https://flagcdn.com/om.svg"
    },
    {
        "name": "mk",
        "full_name": "North Macedonia",
        "flags": "https://flagcdn.com/mk.svg"
    },
    {
        "name": "li",
        "full_name": "Liechtenstein",
        "flags": "https://flagcdn.com/li.svg"
    },
    {
        "name": "es",
        "full_name": "Spain",
        "flags": "https://flagcdn.com/es.svg"
    },
    {
        "name": "gr",
        "full_name": "Greece",
        "flags": "https://flagcdn.com/gr.svg"
    },
    {
        "name": "py",
        "full_name": "Paraguay",
        "flags": "https://flagcdn.com/py.svg"
    },
    {
        "name": "bh",
        "full_name": "Bahrain",
        "flags": "https://flagcdn.com/bh.svg"
    },
    {
        "name": "nu",
        "full_name": "Niue",
        "flags": "https://flagcdn.com/nu.svg"
    },
    {
        "name": "sn",
        "full_name": "Senegal",
        "flags": "https://flagcdn.com/sn.svg"
    },
    {
        "name": "ms",
        "full_name": "Montserrat",
        "flags": "https://flagcdn.com/ms.svg"
    },
    {
        "name": "lv",
        "full_name": "Latvia",
        "flags": "https://flagcdn.com/lv.svg"
    },
    {
        "name": "tk",
        "full_name": "Tokelau",
        "flags": "https://flagcdn.com/tk.svg"
    },
    {
        "name": "jp",
        "full_name": "Japan",
        "flags": "https://flagcdn.com/jp.svg"
    },
    {
        "name": "cf",
        "full_name": "Central African Republic",
        "flags": "https://flagcdn.com/cf.svg"
    },
    {
        "name": "ga",
        "full_name": "Gabon",
        "flags": "https://flagcdn.com/ga.svg"
    },
    {
        "name": "iq",
        "full_name": "Iraq",
        "flags": "https://flagcdn.com/iq.svg"
    },
    {
        "name": "im",
        "full_name": "Isle of Man",
        "flags": "https://flagcdn.com/im.svg"
    },
    {
        "name": "mm",
        "full_name": "Myanmar",
        "flags": "https://flagcdn.com/mm.svg"
    },
    {
        "name": "me",
        "full_name": "Montenegro",
        "flags": "https://flagcdn.com/me.svg"
    },
    {
        "name": "nr",
        "full_name": "Nauru",
        "flags": "https://flagcdn.com/nr.svg"
    },
    {
        "name": "vu",
        "full_name": "Vanuatu",
        "flags": "https://flagcdn.com/vu.svg"
    },
    {
        "name": "fr",
        "full_name": "France",
        "flags": "https://flagcdn.com/fr.svg"
    },
    {
        "name": "zw",
        "full_name": "Zimbabwe",
        "flags": "https://flagcdn.com/zw.svg"
    },
    {
        "name": "ph",
        "full_name": "Philippines",
        "flags": "https://flagcdn.com/ph.svg"
    },
    {
        "name": "sk",
        "full_name": "Slovakia",
        "flags": "https://flagcdn.com/sk.svg"
    },
    {
        "name": "au",
        "full_name": "Australia",
        "flags": "https://flagcdn.com/au.svg"
    },
    {
        "name": "ci",
        "full_name": "Ivory Coast",
        "flags": "https://flagcdn.com/ci.svg"
    },
    {
        "name": "io",
        "full_name": "British Indian Ocean Territory",
        "flags": "https://flagcdn.com/io.svg"
    },
    {
        "name": "sz",
        "full_name": "Eswatini",
        "flags": "https://flagcdn.com/sz.svg"
    },
    {
        "name": "rw",
        "full_name": "Rwanda",
        "flags": "https://flagcdn.com/rw.svg"
    },
    {
        "name": "bj",
        "full_name": "Benin",
        "flags": "https://flagcdn.com/bj.svg"
    },
    {
        "name": "vg",
        "full_name": "British Virgin Islands",
        "flags": "https://flagcdn.com/vg.svg"
    },
    {
        "name": "ug",
        "full_name": "Uganda",
        "flags": "https://flagcdn.com/ug.svg"
    },
    {
        "name": "ie",
        "full_name": "Ireland",
        "flags": "https://flagcdn.com/ie.svg"
    },
    {
        "name": "ir",
        "full_name": "Iran",
        "flags": "https://flagcdn.com/ir.svg"
    },
    {
        "name": "si",
        "full_name": "Slovenia",
        "flags": "https://flagcdn.com/si.svg"
    },
    {
        "name": "ml",
        "full_name": "Mali",
        "flags": "https://flagcdn.com/ml.svg"
    },
    {
        "name": "ch",
        "full_name": "Switzerland",
        "flags": "https://flagcdn.com/ch.svg"
    },
    {
        "name": "as",
        "full_name": "American Samoa",
        "flags": "https://flagcdn.com/as.svg"
    },
    {
        "name": "uy",
        "full_name": "Uruguay",
        "flags": "https://flagcdn.com/uy.svg"
    },
    {
        "name": "gu",
        "full_name": "Guam",
        "flags": "https://flagcdn.com/gu.svg"
    },
    {
        "name": "sr",
        "full_name": "Suriname",
        "flags": "https://flagcdn.com/sr.svg"
    },
    {
        "name": "cz",
        "full_name": "Czechia",
        "flags": "https://flagcdn.com/cz.svg"
    },
    {
        "name": "hn",
        "full_name": "Honduras",
        "flags": "https://flagcdn.com/hn.svg"
    },
    {
        "name": "ws",
        "full_name": "Samoa",
        "flags": "https://flagcdn.com/ws.svg"
    },
    {
        "name": "la",
        "full_name": "Laos",
        "flags": "https://flagcdn.com/la.svg"
    },
    {
        "name": "cv",
        "full_name": "Cape Verde",
        "flags": "https://flagcdn.com/cv.svg"
    },
    {
        "name": "et",
        "full_name": "Ethiopia",
        "flags": "https://flagcdn.com/et.svg"
    },
    {
        "name": "bd",
        "full_name": "Bangladesh",
        "flags": "https://flagcdn.com/bd.svg"
    },
    {
        "name": "sh",
        "full_name": "Saint Helena, Ascension and Tristan da Cunha",
        "flags": "https://flagcdn.com/sh.svg"
    },
    {
        "name": "hr",
        "full_name": "Croatia",
        "flags": "https://flagcdn.com/hr.svg"
    },
    {
        "name": "kw",
        "full_name": "Kuwait",
        "flags": "https://flagcdn.com/kw.svg"
    },
    {
        "name": "gf",
        "full_name": "French Guiana",
        "flags": "https://flagcdn.com/gf.svg"
    },
    {
        "name": "ma",
        "full_name": "Morocco",
        "flags": "https://flagcdn.com/ma.svg"
    },
    {
        "name": "ee",
        "full_name": "Estonia",
        "flags": "https://flagcdn.com/ee.svg"
    },
    {
        "name": "lk",
        "full_name": "Sri Lanka",
        "flags": "https://flagcdn.com/lk.svg"
    },
    {
        "name": "nc",
        "full_name": "New Caledonia",
        "flags": "https://flagcdn.com/nc.svg"
    },
    {
        "name": "pl",
        "full_name": "Poland",
        "flags": "https://flagcdn.com/pl.svg"
    },
    {
        "name": "mg",
        "full_name": "Madagascar",
        "flags": "https://flagcdn.com/mg.svg"
    },
    {
        "name": "cr",
        "full_name": "Costa Rica",
        "flags": "https://flagcdn.com/cr.svg"
    },
    {
        "name": "sv",
        "full_name": "El Salvador",
        "flags": "https://flagcdn.com/sv.svg"
    },
    {
        "name": "mo",
        "full_name": "Macau",
        "flags": "https://flagcdn.com/mo.svg"
    },
    {
        "name": "ad",
        "full_name": "Andorra",
        "flags": "https://flagcdn.com/ad.svg"
    },
    {
        "name": "it",
        "full_name": "Italy",
        "flags": "https://flagcdn.com/it.svg"
    },
    {
        "name": "na",
        "full_name": "Namibia",
        "flags": "https://flagcdn.com/na.svg"
    },
    {
        "name": "sc",
        "full_name": "Seychelles",
        "flags": "https://flagcdn.com/sc.svg"
    },
    {
        "name": "pa",
        "full_name": "Panama",
        "flags": "https://flagcdn.com/pa.svg"
    },
    {
        "name": "ht",
        "full_name": "Haiti",
        "flags": "https://flagcdn.com/ht.svg"
    },
    {
        "name": "ar",
        "full_name": "Argentina",
        "flags": "https://flagcdn.com/ar.svg"
    },
    {
        "name": "ne",
        "full_name": "Niger",
        "flags": "https://flagcdn.com/ne.svg"
    },
    {
        "name": "mw",
        "full_name": "Malawi",
        "flags": "https://flagcdn.com/mw.svg"
    },
    {
        "name": "pn",
        "full_name": "Pitcairn Islands",
        "flags": "https://flagcdn.com/pn.svg"
    },
    {
        "name": "de",
        "full_name": "Germany",
        "flags": "https://flagcdn.com/de.svg"
    },
    {
        "name": "ki",
        "full_name": "Kiribati",
        "flags": "https://flagcdn.com/ki.svg"
    },
    {
        "name": "sy",
        "full_name": "Syria",
        "flags": "https://flagcdn.com/sy.svg"
    },
    {
        "name": "mh",
        "full_name": "Marshall Islands",
        "flags": "https://flagcdn.com/mh.svg"
    },
    {
        "name": "hu",
        "full_name": "Hungary",
        "flags": "https://flagcdn.com/hu.svg"
    },
    {
        "name": "ky",
        "full_name": "Cayman Islands",
        "flags": "https://flagcdn.com/ky.svg"
    },
    {
        "name": "dk",
        "full_name": "Denmark",
        "flags": "https://flagcdn.com/dk.svg"
    },
    {
        "name": "lc",
        "full_name": "Saint Lucia",
        "flags": "https://flagcdn.com/lc.svg"
    },
    {
        "name": "bo",
        "full_name": "Bolivia",
        "flags": "https://flagcdn.com/bo.svg"
    },
    {
        "name": "dj",
        "full_name": "Djibouti",
        "flags": "https://flagcdn.com/dj.svg"
    },
    {
        "name": "za",
        "full_name": "South Africa",
        "flags": "https://flagcdn.com/za.svg"
    },
    {
        "name": "ng",
        "full_name": "Nigeria",
        "flags": "https://flagcdn.com/ng.svg"
    },
    {
        "name": "st",
        "full_name": "São Tomé and Príncipe",
        "flags": "https://flagcdn.com/st.svg"
    },
    {
        "name": "ni",
        "full_name": "Nicaragua",
        "flags": "https://flagcdn.com/ni.svg"
    },
    {
        "name": "gp",
        "full_name": "Guadeloupe",
        "flags": "https://flagcdn.com/gp.svg"
    },
    {
        "name": "pm",
        "full_name": "Saint Pierre and Miquelon",
        "flags": "https://flagcdn.com/pm.svg"
    },
    {
        "name": "ec",
        "full_name": "Ecuador",
        "flags": "https://flagcdn.com/ec.svg"
    },
    {
        "name": "gl",
        "full_name": "Greenland",
        "flags": "https://flagcdn.com/gl.svg"
    },
    {
        "name": "gd",
        "full_name": "Grenada",
        "flags": "https://flagcdn.com/gd.svg"
    },
    {
        "name": "bs",
        "full_name": "Bahamas",
        "flags": "https://flagcdn.com/bs.svg"
    },
    {
        "name": "cl",
        "full_name": "Chile",
        "flags": "https://flagcdn.com/cl.svg"
    },
    {
        "name": "my",
        "full_name": "Malaysia",
        "flags": "https://flagcdn.com/my.svg"
    },
    {
        "name": "tv",
        "full_name": "Tuvalu",
        "flags": "https://flagcdn.com/tv.svg"
    },
    {
        "name": "cx",
        "full_name": "Christmas Island",
        "flags": "https://flagcdn.com/cx.svg"
    },
    {
        "name": "sb",
        "full_name": "Solomon Islands",
        "flags": "https://flagcdn.com/sb.svg"
    },
    {
        "name": "tz",
        "full_name": "Tanzania",
        "flags": "https://flagcdn.com/tz.svg"
    },
    {
        "name": "kp",
        "full_name": "North Korea",
        "flags": "https://flagcdn.com/kp.svg"
    },
    {
        "name": "gw",
        "full_name": "Guinea-Bissau",
        "flags": "https://flagcdn.com/gw.svg"
    },
    {
        "name": "xk",
        "full_name": "Kosovo",
        "flags": "https://flagcdn.com/xk.svg"
    },
    {
        "name": "va",
        "full_name": "Vatican City",
        "flags": "https://flagcdn.com/va.svg"
    },
    {
        "name": "no",
        "full_name": "Norway",
        "flags": "https://flagcdn.com/no.svg"
    },
    {
        "name": "ps",
        "full_name": "Palestine",
        "flags": "https://flagcdn.com/ps.svg"
    },
    {
        "name": "cc",
        "full_name": "Cocos (Keeling) Islands",
        "flags": "https://flagcdn.com/cc.svg"
    },
    {
        "name": "jm",
        "full_name": "Jamaica",
        "flags": "https://flagcdn.com/jm.svg"
    },
    {
        "name": "eg",
        "full_name": "Egypt",
        "flags": "https://flagcdn.com/eg.svg"
    },
    {
        "name": "kh",
        "full_name": "Cambodia",
        "flags": "https://flagcdn.com/kh.svg"
    },
    {
        "name": "mu",
        "full_name": "Mauritius",
        "flags": "https://flagcdn.com/mu.svg"
    },
    {
        "name": "gm",
        "full_name": "Gambia",
        "flags": "https://flagcdn.com/gm.svg"
    },
    {
        "name": "gq",
        "full_name": "Equatorial Guinea",
        "flags": "https://flagcdn.com/gq.svg"
    },
    {
        "name": "ls",
        "full_name": "Lesotho",
        "flags": "https://flagcdn.com/ls.svg"
    },
    {
        "name": "mq",
        "full_name": "Martinique",
        "flags": "https://flagcdn.com/mq.svg"
    },
    {
        "name": "us",
        "full_name": "United States",
        "flags": "https://flagcdn.com/us.svg"
    },
    {
        "name": "eh",
        "full_name": "Western Sahara",
        "flags": "https://flagcdn.com/eh.svg"
    },
    {
        "name": "ae",
        "full_name": "United Arab Emirates",
        "flags": "https://flagcdn.com/ae.svg"
    },
    {
        "name": "mz",
        "full_name": "Mozambique",
        "flags": "https://flagcdn.com/mz.svg"
    },
    {
        "name": "dz",
        "full_name": "Algeria",
        "flags": "https://flagcdn.com/dz.svg"
    },
    {
        "name": "zm",
        "full_name": "Zambia",
        "flags": "https://flagcdn.com/zm.svg"
    },
    {
        "name": "gt",
        "full_name": "Guatemala",
        "flags": "https://flagcdn.com/gt.svg"
    },
    {
        "name": "kr",
        "full_name": "South Korea",
        "flags": "https://flagcdn.com/kr.svg"
    },
    {
        "name": "tl",
        "full_name": "Timor-Leste",
        "flags": "https://flagcdn.com/tl.svg"
    },
    {
        "name": "ax",
        "full_name": "Åland Islands",
        "flags": "https://flagcdn.com/ax.svg"
    },
    {
        "name": "jo",
        "full_name": "Jordan",
        "flags": "https://flagcdn.com/jo.svg"
    },
    {
        "name": "mt",
        "full_name": "Malta",
        "flags": "https://flagcdn.com/mt.svg"
    },
    {
        "name": "cy",
        "full_name": "Cyprus",
        "flags": "https://flagcdn.com/cy.svg"
    },
    {
        "name": "fk",
        "full_name": "Falkland Islands",
        "flags": "https://flagcdn.com/fk.svg"
    },
    {
        "name": "bw",
        "full_name": "Botswana",
        "flags": "https://flagcdn.com/bw.svg"
    },
    {
        "name": "vc",
        "full_name": "Saint Vincent and the Grenadines",
        "flags": "https://flagcdn.com/vc.svg"
    },
    {
        "name": "bb",
        "full_name": "Barbados",
        "flags": "https://flagcdn.com/bb.svg"
    },
    {
        "name": "to",
        "full_name": "Tonga",
        "flags": "https://flagcdn.com/to.svg"
    },
    {
        "name": "th",
        "full_name": "Thailand",
        "flags": "https://flagcdn.com/th.svg"
    },
    {
        "name": "be",
        "full_name": "Belgium",
        "flags": "https://flagcdn.com/be.svg"
    },
    {
        "name": "ca",
        "full_name": "Canada",
        "flags": "https://flagcdn.com/ca.svg"
    },
    {
        "name": "ge",
        "full_name": "Georgia",
        "flags": "https://flagcdn.com/ge.svg"
    },
    {
        "name": "wf",
        "full_name": "Wallis and Futuna",
        "flags": "https://flagcdn.com/wf.svg"
    },
    {
        "name": "fj",
        "full_name": "Fiji",
        "flags": "https://flagcdn.com/fj.svg"
    },
    {
        "name": "nl",
        "full_name": "Netherlands",
        "flags": "https://flagcdn.com/nl.svg"
    },
    {
        "name": "do",
        "full_name": "Dominican Republic",
        "flags": "https://flagcdn.com/do.svg"
    },
    {
        "name": "gg",
        "full_name": "Guernsey",
        "flags": "https://flagcdn.com/gg.svg"
    },
    {
        "name": "tm",
        "full_name": "Turkmenistan",
        "flags": "https://flagcdn.com/tm.svg"
    },
    {
        "name": "np",
        "full_name": "Nepal",
        "flags": "https://flagcdn.com/np.svg"
    },
    {
        "name": "mv",
        "full_name": "Maldives",
        "flags": "https://flagcdn.com/mv.svg"
    },
    {
        "name": "ly",
        "full_name": "Libya",
        "flags": "https://flagcdn.com/ly.svg"
    },
    {
        "name": "br",
        "full_name": "Brazil",
        "flags": "https://flagcdn.com/br.svg"
    }
]