import React from "react";
import { Redirect, Switch } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import routes from "./routes";

const Routes = ({ redirectUrl }) => (
  <Switch>
    <Redirect exact from="/" to={redirectUrl} />
    {routes.map((route) => {
      const { children, roles, path, component } = route;
      return children?.length ? (
        children.map((child) => {
          const { path, component } = child;
          return (
            <PrivateRoute
              exact
              key={path}
              roles={roles}
              path={path}
              component={component}
            />
          );
        })
      ) : (
        <PrivateRoute
          exact
          path={path}
          roles={roles}
          component={component}
          key={path}
        />
      );
    })}
    <Redirect to="/404" />
  </Switch>
);
export default Routes;
