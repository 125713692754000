import React from "react";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import AppButton from "../button/Button";
import { Link } from "react-router-dom";

const CongratulationsModal = ({
  show,
  message,
  onHide,
  imageLink = "",
  link = "",
  linkTitle = "",
}) => {
  const [t] = useTranslation();

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Body className="p-2">
        <div className="text-right cross">
          <i className="fa fa-times" role="button" onClick={onHide}></i>
        </div>
        <div className="card-body text-center p-2">
          {imageLink && (
            <img
              className="img-fluid  mx-auto"
              src="/img/bonus-with-logo.jpg"
              alt="#"
            />
          )}
          <h5 className="mt-4">{t("common.congratulations")}</h5>
          <p>{message}</p>
          {!link && (
            <AppButton variant="secondary" onClick={onHide}>
              {t("common.close")}
            </AppButton>
          )}
          {link && (
            <Link to={link} className="btn btn-primary">
              {linkTitle}
            </Link>
          )}
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default CongratulationsModal;
