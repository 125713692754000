import React, { memo } from "react";
import ReactApexChart from "react-apexcharts";
import Spinner from "../spinner";

const MultiChart = (props) => {
  const { series, categories = [], dataLabels = {}, darkMode, loading } = props;
  const seriesYaxis = series
    .map(({ yaxis }) => {
      return yaxis ? yaxis : null;
    })
    .filter((s) => s);
  const defaultYaxis = [
    {
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        color: "#008FFB",
      },
      labels: {
        style: {
          colors: "#008FFB",
        },
        formatter: function (val) {
          return isFinite(val)
            ? val.toFixed().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : val;
        },
      },
      title: {
        text: "",
        style: {
          color: "#008FFB",
        },
      },
      tooltip: {
        enabled: true,
      },
    },
    {
      seriesName: "Income",
      opposite: true,
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        color: "#00E396",
      },
      labels: {
        style: {
          colors: "#00E396",
        },
        formatter: function (val) {
          return isFinite(val)
            ? val.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : val;
        },
      },
      title: {
        text: "",
        style: {
          color: "#00E396",
        },
      },
    },
    {
      seriesName: "Revenue",
      opposite: true,
      axisTicks: {
        show: true,
      },
      axisBorder: {
        show: true,
        color: "#FEB019",
      },
      labels: {
        style: {
          colors: "#FEB019",
        },
        formatter: function (val) {
          return isFinite(val)
            ? val.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            : val;
        },
      },
      title: {
        text: "",
        style: {
          color: "#FEB019",
        },
      },
    },
  ];
  const yaxis = seriesYaxis.length ? seriesYaxis : defaultYaxis;
  const settings = {
    series: series,
    options: {
      chart: {
        height: 450,
        type: "line",
        stacked: false,
      },
      theme: {
        mode: darkMode ? "dark" : "light",
        palette: "palette1",
      },
      dataLabels: dataLabels,
      stroke: {
        width: [1, 1, 4],
      },
      title: {
        text: "",
        align: "left",
        offsetX: 110,
      },
      xaxis: {
        categories: Array.from(categories),
      },
      yaxis: [...yaxis],
      tooltip: {
        fixed: {
          enabled: false,
          position: "topLeft", // topRight, topLeft, bottomRight, bottomLeft
          offsetY: 30,
          offsetX: 60,
        },
      },
      legend: {
        horizontalAlign: "left",
        offsetX: 40,
      },
    },
  };
  return (
    <div className="multiChart">
      {loading && <Spinner />}
      <ReactApexChart
        options={settings.options}
        series={settings.series}
        type="line"
        height={450}
      />
    </div>
  );
};

export default memo(MultiChart);
