import actionTypes from "../../action-types";

const initialState = {
  list: [],
  currentQuestion: {},
  showBlock: true,
  isLoading: false,
};
const questionReducer = (state = initialState, action) => {
  const { type, payload } = action;
  const {
    QUESTIONS_LOAD,
    TOGGLE_QUESTIONS_BLOCK,
    QUESTION_DELETE,
    SINGLE_QUESTIONS,
    SINGLE_QUESTIONS_LOADING,
  } = actionTypes;
  switch (type) {
    case QUESTIONS_LOAD:
      return {
        ...state,
        list: payload,
      };
    case SINGLE_QUESTIONS:
      return {
        ...state,
        currentQuestion: payload,
      };
    case SINGLE_QUESTIONS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case TOGGLE_QUESTIONS_BLOCK:
      return {
        ...state,
        showBlock: payload,
      };
    case QUESTION_DELETE:
      const questionId = payload;
      let questions = [...state.list];
      questions = questions.filter((question) => {
        return question.id !== questionId;
      });
      return {
        ...state,
        list: questions,
      };
    default:
      return {
        ...state,
      };
  }
};
export default questionReducer;
