import i18n from "i18next";
import { toast } from "react-toastify";
import { http } from "../../../helpers";
import actionTypes from "../../action-types";

export const AdminZenAction = (period) => {
  return async (dispatch) => {
    try {
      const params = {
        period,
      };
      await http.get("/zen", { params });
      toast.success(`${i18n.t("common.requestSendWaitNotify")}`, {
        autoClose: 5000,
      });
    } catch (error) {
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const AdminZenBySourceAction = (period) => {
  return async (dispatch) => {
    try {
      const params = {
        period,
      };
      await http.get("/zen-by-sources-loading", { params });
      toast.success(`${i18n.t("common.requestSendWaitNotify")}`, {
        autoClose: 5000,
      });
    } catch (error) {
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const AdminZenBySourceGetAction = (startDate, endDate) => {
  return async (dispatch) => {
    try {
      const params = {
        startDate,
        endDate,
      };
      const response = await http.get("/zen-by-sources", { params });
      const { data } = response;
      dispatch(SetZenBySourcesAction(data));
    } catch (error) {
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const AdminZenByZoneAction = (period) => {
  return async () => {
    try {
      const params = {
        period,
      };
      await http.get("/zen-by-zones-loading", { params });
      toast.success(`${i18n.t("common.requestSendWaitNotify")}`, {
        autoClose: 5000,
      });
    } catch (error) {
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const AdminZenByZoneGetAction = (startDate, endDate) => {
  return async (dispatch) => {
    try {
      const params = {
        startDate,
        endDate,
      };
      const response = await http.get("/zen-by-zones", { params });
      const { data } = response;
      dispatch(SetZenByZonesAction(data));
    } catch (error) {
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const AdminZenBySizeGetAction = (period, zoneSizeId, pageNumber) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.ADMIN_ZEN_BY_SIZE_LOADING, payload: true });
    try {
      const params = {
        period,
        zoneSizeId,
        page: pageNumber ?? 1,
      };
      const response = await http.get("/zen-by-size", { params });
      const { data } = response;
      dispatch({ type: actionTypes.SET_ADMIN_ZEN_BY_SIZE, payload: data });
      dispatch({ type: actionTypes.ADMIN_ZEN_BY_SIZE_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: actionTypes.ADMIN_ZEN_BY_SIZE_LOADING, payload: false });
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const ExportZoneSizeXlsxAction = (id, date) => {
  return async (dispatch) => {
    try {
      const params = {
        period: date,
      };
      await http.post(`/reports/export/zones/${id}`, params);
      toast.success(`${i18n.t("common.requestSendWaitNotify")}`, {
        autoClose: 5000,
      });
    } catch (error) {
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const SetZenAction = (zen) => {
  return {
    type: actionTypes.SET_ADMIN_ZEN,
    payload: zen,
  };
};
export const SetZenBySourcesAction = (zenBySources) => {
  return {
    type: actionTypes.SET_ADMIN_ZEN_BY_SOURCES,
    payload: zenBySources,
  };
};

export const SetZenByZonesAction = (zenByZones) => {
  return {
    type: actionTypes.SET_ADMIN_ZEN_BY_ZONES,
    payload: zenByZones,
  };
};

export const SetZenBySizeAction = (zenBySize) => {
  return {
    type: actionTypes.SET_ADMIN_ZEN_BY_SIZE,
    payload: zenBySize,
  };
};

export const SetZenBySizePeriodAction = (date) => {
  return {
    type: actionTypes.ADMIN_ZEN_BY_SIZE_PERIOD,
    payload: date,
  };
};
export const SetZenByZoneSizeAction = (zoneSizeId) => {
  return {
    type: actionTypes.ADMIN_ZEN_BY_ZONE_SIZE,
    payload: zoneSizeId,
  };
};
