import actionTypes from "../../action-types";
const initialState = {
    isLoading: false,
    user: []
};
const adminSingleUserReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case actionTypes.SET_ADMIN_SINGLE_USER:
            return {
                ...state,
                user: payload
            };
        
        case actionTypes.ADMIN_SINGLE_USER_LOADING:
            return {
                ...state,
                isLoading: payload
            };
        default:
            return {
                ...state
            };
    }
}
export default adminSingleUserReducer;