import React, { memo } from "react";
const ManagerLinksTab = ({ isActive, user }) => {
  return (
    <div className={`tab-pane ${isActive ? "active" : ""}`}>
      <div className="container">
        <span className="row">{user.mglink}</span>
        <span className="row">
          https://digitalcaramel.com?{user.mglink?.split("?")[1]}
        </span>
      </div>
    </div>
  );
};

export default memo(ManagerLinksTab);
