import { toast } from "react-toastify";
import i18n from "i18next";
import { http } from "../../../helpers";
import actionTypes from "../../action-types";

export const UserUpdateAction = (id, userData) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.PROFILE_LOADING, payload: true });
    try {
      let paymentMethod = null;
      if (userData.entity === "physical") {
        paymentMethod = userData.primaryPaymentMethod;
      } else if (
        userData.entity === "legal" ||
        userData.entity === "individual"
      ) {
        paymentMethod = "bank_transfer";
      }
      const params = {
        fullname: userData.fullName,
        country: userData.country,
        phone: userData.phone,
        telegram: userData.telegram,
        skype: userData.skype,
        whatsapp: userData.whatsapp,
        vk: userData.vk,
        entity: userData.entity,
        primary_payment_method: paymentMethod,
        yandex_wallet_number: userData.yandex,
        ruble_card_number: userData.card,
        ruble_card_name: userData.cardName,
        webmoney_wmr_wallet_number: userData.wmr,
        webmoney_wmz_wallet_number: userData.wmz,
        company_name: userData.companyName,
        ITN: userData.ITN,
        IEC: userData.IEC,
        PSRNSP: userData.PSRNSP,
        company_address: userData.companyAddress,
        company_phone: userData.companyPhone,
        email_fq: userData.emailFAQ,
        general_director_name: userData.generalDirectorName,
        bank_requisites: userData.bankRequisites,
        own_website_ids: userData.own_website_ids,
        password: userData.password,
        is_activated: userData.is_activated,
        yandex_referred: userData.yandex_referred,
        manager_id: userData.managerId,
        usdt: userData.usdt,
        solar_staff: userData.solarStaff,
        bitcoin: userData.bitcoin,
        bitrix_id: userData.bitrix_id,
      };
      const response = await http.post(`/users/${id}`, params);
      const { data } = response;
      if (data.user?.id) {
        dispatch({ type: actionTypes.LOAD_USER, currentUser: data.user });
      }
      dispatch({ type: actionTypes.PROFILE_LOADING, payload: false });
      if (data.user?.role_id > 2 && !data.user?.requisites_permission) {
        toast.success(`${i18n.t("users.single.requestForUpdate")}`);
      } else {
        toast.success(`${i18n.t("users.single.updated")}`);
      }
    } catch (error) {
      dispatch({ type: actionTypes.PROFILE_LOADING, payload: false });
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const UserPasswordUpdateAction = (id, userData) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.PROFILE_LOADING, payload: true });
    try {
      const params = {
        old_password: userData.currentPassword,
        password: userData.password,
        password_confirmation: userData.passwordRetype,
      };
      await http.post(`/users/${id}/update-password`, params);
      toast.success(`${i18n.t("users.single.passwordUpdated")}`);
      dispatch({
        type: actionTypes.CHANGE_USER_PASSWORD_UPDATED,
        payload: true,
      });
      dispatch({ type: actionTypes.PROFILE_LOADING, payload: false });
    } catch (error) {
      const { data } = error.response;
      toast.error(data.error_message);
      dispatch({ type: actionTypes.PROFILE_LOADING, payload: false });
    }
  };
};

export const SearchWebsitesAction = (query, callback) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.PROFILE_LOADING, payload: true });
    try {
      const params = {
        search: query,
      };
      const response = await http.get("admin/websites/search", { params });
      const { data } = response;
      callback(data.list.map((item) => ({ value: item.id, label: item.url })));
    } catch (error) {
      const { data } = error.response;
      toast.error(data.error_message);
    }
  };
};

export const GetQuestionsAction = () => {
  return async (dispatch) => {
    try {
      const response = await http.get("/questions/list");
      const { data } = response;
      if (data.data) {
        dispatch({ type: actionTypes.QUESTIONS_LOAD, payload: data.data });
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export const ShowQuestionsModal = (payload) => {
  return async (dispatch) => {
    try {
      dispatch({ type: actionTypes.TOGGLE_QUESTIONS_BLOCK, payload });
    } catch (error) {
      console.error(error);
    }
  };
};

export const SetUserAnswerAction = (params) => {
  return async (dispatch) => {
    try {
      await http.post("/user-answer", params);
      dispatch({ type: actionTypes.TOGGLE_QUESTIONS_BLOCK, payload: false });
      toast.success(`${i18n.t("questions.thanksMessage")}`);
    } catch (error) {
      console.error(error);
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const GetUserBonusAction = () => {
  return async (dispatch) => {
    try {
      const response = await http.get("/bonus");
      const { data } = response;
      dispatch({ type: actionTypes.SET_BONUS, payload: data.data });
    } catch (error) {
      console.error(error);
    }
  };
};

export const SetUserBonusAction = (bonus_id, userBonusPercent = false) => {
  return async (dispatch) => {
    try {
      const params = {
        bonus_id,
      };
      await http.post("/bonus", params);
      dispatch({
        type: actionTypes.USER_BONUS_PERCENT,
        payload: userBonusPercent,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const SendCodeToMail = () => {
  return async () => {
    try {
      toast.success(`${i18n.t("code.sent")}`);
      await http.post("code/billing-details");
    } catch (error) {
      console.error(error);
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const CheckAndUpdateUser = (code, id, values) => {
  return async (dispatch) => {
    try {
      const params = {
        code,
      };
      const response = await http.post(
        "code/check-billing-confirmation",
        params
      );
      const { status } = response;
      if (status === 200) {
        dispatch(UserUpdateAction(id, values));
      }
    } catch (error) {
      toast.error(`${i18n.t("error.somethingWrong")}`);
      console.error(error);
    }
  };
};
