import { sourceTitles } from "../../../helpers";
import actionTypes from "../../action-types";

const initialState = {
  zen: {
    convertedDataChart: {
      convertedCategories: [],
      convertedSumRub: [],
      convertedSumUsd: [],
      convertedRates: [],
    },
    dataChart: {
      sumRub: [],
      sumRubWithoutCommission: [],
      categories: [],
      avg: [],
    },
    lastUsdRate: 0,
    range: {
      start: "",
      end: "",
    },
    totalSumRub: 0,
    totalSumRubGen: 0,
    isLoading: false,
  },
  zenBySources: {
    range: {
      start: "",
      end: "",
    },
    sources: {},
    totalSourceData: {},
    sourcesDataChart: {
      series: [],
      categories: [],
    },
  },
  zenByZones: {
    range: {
      start: "",
      end: "",
    },
    zones: {},
    totalZones: [],
    zonesDataChart: {
      series: [],
      categories: [],
    },
  },
  zenBySize: {
    range: {
      start: "",
      end: "",
    },
    websites: {},
    isLoading: false,
  },
  period: "7days",
  zoneSizeId: 0,
};

const adminZendReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_ADMIN_ZEN:
      const { data: zenData } = payload;
      const {
        data,
        convertedData,
        usd_rates: usdRates,
        range,
        last_usd_rate: lastUsdRate,
        sum_rub: totalSumRub,
      } = zenData;
      const {
        rub: sumRub = [],
        rub_without_commission: sumRubWithoutCommission = [],
      } = data;
      const sumRubArr = sumRub.map(({ value: sumRub }) => sumRub);
      const sumRubWithoutCommissionArr = sumRubWithoutCommission.map(
        ({ value: sumRubWithoutCommission }) => sumRubWithoutCommission
      );
      const avgArr = sumRubArr.map(
        (sumRub, index) => (sumRub / sumRubWithoutCommissionArr[index]) * 100
      );
      const categories = sumRub.map(({ date }) => date);

      const convertedSumRub = Object.values(convertedData).map(({ rub }) => {
        return rub;
      });
      const convertedSumUsd = Object.values(convertedData).map(({ usd }) => {
        return usd;
      });
      const convertedCategories = Object.keys(convertedData);
      const convertedRates = Object.values(usdRates);
      return {
        ...state,
        zen: {
          convertedDataChart: {
            ...state.zen.convertedDataChart,
            convertedCategories,
            convertedSumRub,
            convertedSumUsd,
            convertedRates,
          },
          dataChart: {
            ...state.zen.dataChart,
            sumRub: sumRubArr,
            sumRubWithoutCommission: sumRubWithoutCommissionArr,
            categories,
            avg: avgArr,
          },
          lastUsdRate,
          range,
          totalSumRub,
          totalSumRubGen: sumRubArr.reduce((acc, curr) => acc + curr, 0),
        },
      };
    case actionTypes.ADMIN_ZEN_LOADING:
      return {
        ...state,
        ...state.zen,
        zen: {
          ...state.zen,
          isLoading: payload,
        },
      };
    case actionTypes.SET_ADMIN_ZEN_BY_SOURCES:
      const { data: sourceData } = payload;
      const { sources, range: bsRange } = sourceData;
      const sourcesDataChart = [];
      const totalSourceData = [];
      const sourcesDataChartCategories = new Set();
      for (const source in sources) {
        if (Object.hasOwnProperty.call(sources, source)) {
          let _line = {
            name: sourceTitles[source],
            source: source,
            type: "area",
            stack: sourceTitles[source],
            data: [],
          };
          let totalSum = 0;
          let totalImpressions = 0;
          sources[source].forEach((item) => {
            totalSum += item.value;
            totalImpressions += item.impressions;
            sourcesDataChartCategories.add(item.date);
            _line.data.push(item.value);
          });
          let totalData = {
            name: sourceTitles[source],
            sum: totalSum,
            impressions: totalImpressions,
          };
          totalSourceData.push(totalData);
          sourcesDataChart.push(_line);
        }
      }
      return {
        ...state,
        zenBySources: {
          ...state.zenBySources,
          range: bsRange,
          sources,
          totalSourceData,
          sourcesDataChart: {
            series: sourcesDataChart,
            categories: [...sourcesDataChartCategories],
          },
        },
      };
    case actionTypes.SET_ADMIN_ZEN_BY_ZONES:
      const { data: zoneData } = payload;
      const { zones, zoneRange: bsRangeZone } = zoneData;
      const zonesDataChart = [];
      const totalZones = [];
      const zonesDataChartCategories = new Set();
      for (const zone in zones) {
        if (Object.hasOwnProperty.call(zones, zone)) {
          let _line = {
            name: zone,
            zone: zone,
            type: "area",
            stack: zone,
            data: [],
          };
          let totalSum = 0;
          let totalImpressions = 0;
          zones[zone].forEach((item) => {
            totalSum += item.value;
            totalImpressions += item.impressions;
            zonesDataChartCategories.add(item.date);
            _line.data.push(item.value);
          });
          let totalData = {
            size: zone,
            sum: totalSum,
            impressions: totalImpressions,
          };
          totalZones.push(totalData);
          zonesDataChart.push(_line);
        }
      }
      return {
        ...state,
        zenByZones: {
          ...state.zenByZones,
          zoneRange: bsRangeZone,
          totalZones,
          zones,
          zonesDataChart: {
            series: zonesDataChart,
            categories: [...zonesDataChartCategories],
          },
        },
      };
    case actionTypes.SET_ADMIN_ZEN_BY_SIZE:
      const { websites, range: bsRangeSize } = payload;
      return {
        ...state,
        zenBySize: {
          ...state.zenBySize,
          range: bsRangeSize,
          websites,
        },
      };
    case actionTypes.ADMIN_ZEN_BY_SIZE_LOADING:
      return {
        ...state,
        zenBySize: {
          ...state.zenBySize,
          isLoading: payload,
        },
      };
    case actionTypes.ADMIN_ZEN_BY_SIZE_PERIOD:
      return {
        ...state,
        period: payload,
      };
    case actionTypes.ADMIN_ZEN_BY_ZONE_SIZE:
      return {
        ...state,
        zoneSizeId: payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default adminZendReducer;
