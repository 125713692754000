import React from "react";
import { Modal } from "react-bootstrap";

const NotificationModal = ({ show, title, onHide, children }) => {
  return (
    <Modal show={show} onHide={onHide} size="md">
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
        <button type="button" className="close" onClick={onHide}>
          <span aria-hidden="true">&times;</span>
        </button>
      </Modal.Header>

      <Modal.Body>{children}</Modal.Body>
    </Modal>
  );
};
export default NotificationModal;
