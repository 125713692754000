import React from 'react';
import styles from './styles.module.css';
const StatCard = ({
    title,
    color = '#111',
    icon,
    total,
    period,
    footerIconColor,
    footerIcon = 'calendar'
}) => {
    const footIc = footerIconColor ? footerIconColor : color;
    return (
        <>
            <div className="card-stats card">
                <div className={styles['card-body']}>
                    <div className="row">
                        <div className="col-5 col-md-4">
                            <div className={`${styles['icon-big']} text-center`}>
                                <i style={{ color: color }} className={`fas fa-${icon}`}></i>
                            </div>
                        </div>
                        <div className="col-7 col-md-8">
                            <div className={styles.numbers}>
                                <p className={styles['card-category']}>{title}</p>
                                <p className="title">{total}</p>
                                <p></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles['card-footer']}>
                    <hr />
                    <div className={styles.stats}>
                        <i style={{ color: footIc }} className={`fas fa-${footerIcon}`}></i>
                        {period}
                    </div>
                </div>
            </div>
        </>
    );
};

export default StatCard;
