import React, { memo, useEffect, useState } from "react";
import AppButton from "../button/Button";
import { useTranslation } from "react-i18next";
import echo from "../../helpers/echo";
import AppDateRangePicker from "../daterangepicker";

const Export = ({ user, adminUser, isActive, exportWebsiteXlsx }) => {
  const [t] = useTranslation();
  const [period, setPeriod] = useState("month");

  useEffect(() => {
    let userId = adminUser ? adminUser.id : user.id;
    echo(localStorage.getItem("dc-token")).private(`Export.Loaded.${userId}`);
    return () => {
      echo(localStorage.getItem("dc-token")).leave(`Export.Loaded.${userId}`);
    };
  }, [adminUser, user.id]);

  const handleExport = () => {
    exportWebsiteXlsx(user, period);
  };
  return (
    <div className={`tab-pane ${isActive ? "active" : ""}`}>
      <div className="row">
        <div className="col-12">
          <div className="card">
            <form className="card-body">
              <AppDateRangePicker
                handlePickerDate={setPeriod}
                className="col-12 col-md-3"
              />
              <AppButton onClick={handleExport} icon="export" className="my-2">
                {t("export.label.export")}
              </AppButton>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};
export default memo(Export);
