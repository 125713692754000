import React, { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import AppButton from "../../../components/button/Button";
import PageLoading from "../../../components/page-loading/PageLoading";
import {
  appSearch,
  selectText,
  status as staticStatus,
} from "../../../helpers";
import {
  GetWebsiteByIDAction,
  UpdateZoneAction,
} from "../../../store/actions/websites";
import styles from "./index.module.css";
import { Card, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import moment from "moment";
import HeadScript from "../../../components/scripts/headScript";

const SingleWebsite = ({
  match,
  cname,
  zones,
  url,
  isLoading,
  getWebsiteWithZones,
  updateZone,
}) => {
  const { id } = match.params;
  const [showCode, setShowCode] = useState(false);
  const [script, setScript] = useState(null);
  const [modalHeader, setModalHeader] = useState(null);
  const [queryString, setQueryString] = useState(null);
  const [zoneTitle, setZoneTitle] = useState({
    title: "",
    id: null,
  });

  const handleShowCodeModal = (title, script) => {
    //const zone = zones.find(item => item.id === id);
    //const script = zone?.script;
    setModalHeader(title);
    setScript(script);
    setShowCode(true);
  };
  const handleChangeZoneName = useCallback((title, id) => {
    setZoneTitle({
      id,
      title,
    });
  }, []);
  const handleUpdateZoneName = useCallback(() => {
    const { id: zoneId, title: zoneName } = zoneTitle;
    const details = {
      zoneId,
      zoneName,
    };
    updateZone(id, details);
  }, [id, zoneTitle, updateZone]);
  const handleCloseCodeModal = () => setShowCode(false);
  const handleSearchZone = (e) => {
    const searchkey = e.target.value;
    setQueryString(searchkey);
  };
  const handleCopied = () => {
    toast.info(t("common.copied"));
  };
  const [t] = useTranslation();
  useEffect(() => {
    getWebsiteWithZones(id);
  }, [id, getWebsiteWithZones]);

  const zonesList = appSearch(zones, queryString, "name").map((item) => {
    return (
      <div className={`col-xl-3 col-lg-4 col-md-6 col-12 banner`} key={item.id}>
        <Card className="text-center">
          <Card.Header className="d-flex justify-content-center align-items-center">
            <input
              className="text-center"
              defaultValue={item.name}
              onChange={({ target }) =>
                handleChangeZoneName(target.value, item.id)
              }
            />
            {zoneTitle.id === item.id && (
              <AppButton
                className="ml-2"
                style={{ padding: "2px 5px" }}
                onClick={handleUpdateZoneName}
              >
                <i className="fas fa-save "></i>
              </AppButton>
            )}
          </Card.Header>
          <Card.Body>
            <Card.Text>{item.size}</Card.Text>
            <AppButton
              disabled={item.status !== "approved"}
              variant="primary"
              onClick={() => handleShowCodeModal(item.name, item.script)}
            >
              {t("zones.getCode")}
            </AppButton>
          </Card.Body>
          <Card.Footer>
            <div className="row">
              <div className="col-6 text-left">
                <i
                  className={`fas fa-${
                    staticStatus[item?.status]?.icon
                  } mr-1 d-block d-sm-inline text-center`}
                />
                {t(staticStatus[item?.status]?.text)}
              </div>
              <div className="col-6 text-right">
                <i className="fas fa-calendar-alt mr-0 mr-sm-1 d-block d-sm-inline text-center" />
                {moment(item.date).format("YYYY-MM-DD")}
              </div>
            </div>
          </Card.Footer>
        </Card>
      </div>
    );
  });
  let template;
  if (isLoading) {
    template = <PageLoading />;
  } else {
    template = (
      <>
        <div className="container-fluid">
          <section className="content-header">
            <div className="container-fluid">
              <div className="row mb-2">
                <div className="col-sm-6">
                  <h1>
                    {t("menusidebar.label.codes")} [{cname}]
                  </h1>
                </div>
                <div className="col-sm-6">
                  <ol className="breadcrumb float-sm-right">
                    <li className="breadcrumb-item">
                      <Link to="/">{t("header.label.home")}</Link>
                    </li>
                    <li className="breadcrumb-item">
                      <Link to="/user/websites">
                        {t("menusidebar.label.websites")}
                      </Link>
                    </li>
                    <li className="breadcrumb-item active">{cname}</li>
                  </ol>
                </div>
              </div>
            </div>
          </section>
          <div className="row">
            <div className="col-lg-6 col-12">
              <div className="card">
                <div className="card-header">
                  <Trans i18nKey="script.loaderJsNote">
                    In the <code> head </code> of the site, add the loader code
                    once
                  </Trans>
                </div>
                <div className="card-body table-responsive p-0">
                  <div id="header-links" className={styles["code-context"]}>
                    <HeadScript url={url} />
                  </div>
                </div>
                <AppButton
                  onClick={() => selectText("header-links", true, handleCopied)}
                >
                  {t("common.copy")}
                </AppButton>
              </div>
            </div>
            <div className="col-lg-6 col-12">
              <div className="card">
                <div className="card-body">
                  <Link
                    to={`/user/websites/${id}/zones/add`}
                    style={{ minHeight: "177px", width: "100%" }}
                  >
                    {t("zone.addZones")}
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-12">
              <div className="card">
                <div className="card-header">
                  {t("zones.title")}
                  <input
                    className="ml-2"
                    type="text"
                    placeholder={t("zones.search")}
                    onChange={(e) => handleSearchZone(e)}
                  ></input>
                </div>
                <div className="card-body table-responsive">
                  <div className="container-fluid">
                    <div className={`row`}>{zonesList}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal show={showCode} onHide={handleCloseCodeModal} size="lg">
            <Modal.Header>
              <Modal.Title>{modalHeader}</Modal.Title>
              <button
                type="button"
                className="close"
                onClick={handleCloseCodeModal}
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </Modal.Header>
            <Modal.Body id="zone-script">
              <pre>
                <code
                  onClick={() => selectText("zone-script", true, handleCopied)}
                >
                  {script}
                </code>
              </pre>
            </Modal.Body>
            <Modal.Footer>
              <AppButton variant="secondary" onClick={handleCloseCodeModal}>
                {t("common.close")}
              </AppButton>
              <AppButton
                variant="primary"
                onClick={() => selectText("zone-script", true, handleCopied)}
              >
                {t("common.copy")}
              </AppButton>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
  return template;
};

const mapStateToProps = (state) => {
  const { url, cname, status, zones, isLoading } = state.website;
  return {
    url,
    cname,
    status,
    zones,
    isLoading,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getWebsiteWithZones: (id) => dispatch(GetWebsiteByIDAction(id)),
  updateZone: (id, details) => dispatch(UpdateZoneAction(id, details)),
});
export default connect(mapStateToProps, mapDispatchToProps)(SingleWebsite);
