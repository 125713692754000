import { http } from "../../../helpers";
import actionTypes from "../../action-types";

export const MarkAsNotification = (notificationID) => {
  return async (dispatch) => {
    try {
      const params = {
        id: notificationID,
      };
      await http.post(`/notifications/mark-as-read`, notificationID && params);
      dispatch({
        type: actionTypes.MARK_AS_NOTIFY,
        payload: notificationID,
      });
    } catch (error) {}
  };
};

export const SetUserNotifications = (notification) => {
  return {
    type: actionTypes.SET_USER_NOTIFICATIONS,
    payload: notification,
  };
};
