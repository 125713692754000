import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Button from '../../components/button/Button';
import confirmEmail from '../../store/actions/auth/confirmAction';
import Cookies from 'js-cookie';
import { history } from '../../helpers';
import logo from '../../assets/img/login-logo.png';
const Confirm = (props) => {
    const {
        isAuthLoading,
        confirmEmail
    } = props;
    const [t] = useTranslation();
    useEffect(() => {
        if (!Cookies.get('confirm-token')) {
            history.push("/login")
        }
    }, []);
    
    document.getElementById('root').classList = 'hold-transition register-page';
    return (
        <div className="register-box">
            <div className="card card-outline card-primary">
                <div className="card-header text-center">
                    <Link to="/" className="h1">
                        <img src={logo} alt="Logo" className="w-100" />
                    </Link>
                </div>
                <div className="card-body">
                    <div className="mb-3 text-center">
                        {t('confirm.text')}
                    </div>
                    <div className="mb-3 text-center">
                        {
                            <Button
                                onClick={() => confirmEmail(Cookies.get('confirm-token'))}
                                isLoading={isAuthLoading}
                            >
                                {t('confirm.button')}
                            </Button>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => {
    const {
        isAuthLoading
    } = state.auth;
    return {
        isAuthLoading
    }
}
const mapDispatchToProps = (dispatch) => ({
    confirmEmail: (token) => dispatch(confirmEmail(token))
});

export default connect(mapStateToProps, mapDispatchToProps)(Confirm)