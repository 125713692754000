import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import DateRangeCard from "../../../components/card/DateRangeCard";
import PageLoading from "../../../components/page-loading/PageLoading";
import { impressionFormat, showAmountOfNumber } from "../../../helpers";
import { AdminDashboardAction } from "../../../store/actions/admin/dashboard";
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const AdminDashboard = ({
  getDashboard,
  yesterdaySum,
  monthEarnings,
  monthAverage,
  yesterdayWebsitesCount,
  topWebsites,
  isLoading,
}) => {
  const { t } = useTranslation();
  useEffect(() => {
    getDashboard();
  }, [getDashboard]);

  const topWebsitesList = topWebsites.map((website) => (
    <tr key={website.id}>
      <td>
        <span className="ml-2">
          <Link to={`/admin/websites/${website.id}`}>{website.id}</Link>
        </span>
      </td>
      <td>
        <span className="ml-2">
          <Link to={`/admin/websites/${website.id}`}>{website.url}</Link>
        </span>
      </td>
      <td>{showAmountOfNumber(website.sum)}</td>
      <td>{impressionFormat(website.impressions)}</td>
      <td>{showAmountOfNumber(website.cpm)}</td>
    </tr>
  ));
  let template = null;
  if (isLoading) {
    template = <PageLoading />;
  } else {
    template = (
      <>
        <div className="row">
          <DateRangeCard
            value={showAmountOfNumber(yesterdaySum)}
            percentage={17}
            title={t("dashboard.yesterdaySum")}
            icon="fas fa-money-bill"
          />
          <DateRangeCard
            value={showAmountOfNumber(monthEarnings)}
            percentage={-15}
            title={t("dashboard.monthEarnings")}
            icon="fas fa-money-bill-alt"
          />
          <DateRangeCard
            value={showAmountOfNumber(monthAverage)}
            percentage={59}
            title={t("dashboard.monthAverage")}
            icon="fas fa-money-check-alt"
          />
          <DateRangeCard
            value={yesterdayWebsitesCount}
            percentage={59}
            title={t("dashboard.yesterdayWebsitesCount")}
            icon="fas fa-globe"
          />
        </div>
        <div className="row">
          <div className="col-12">
            <div className="card table-responsive">
              <div className="card-header">
                <h4>{t("dashboard.topWebsites")}</h4>
                <table className="table table-striped">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>{t("dashboard.website.name")}</th>
                      <th>{t("dashboard.website.profit")}</th>
                      <th>{t("dashboard.website.impressions")}</th>
                      <th>{t("dashboard.website.cpm")}</th>
                    </tr>
                  </thead>
                  <tbody>{topWebsitesList}</tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  return <div className="container-fluid">{template}</div>;
};
const mapStateToProps = (state) => {
  const {
    yesterdaySum,
    monthEarnings,
    monthAverage,
    yesterdayWebsitesCount,
    topWebsites,
    isLoading,
  } = state.adminDashboard;
  return {
    yesterdaySum,
    monthEarnings,
    monthAverage,
    yesterdayWebsitesCount,
    topWebsites,
    isLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getDashboard: () => dispatch(AdminDashboardAction()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminDashboard);
