import actionTypes from "../../action-types";

const initialState = {
  link: "",
};

const adminExportReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_EXPORT_LINK:
      return {
        ...state,
        link: payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default adminExportReducer;
