import actionTypes from "../../action-types";

const initialState = {
    isLoading: false
};
const profileReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case actionTypes.PROFILE_LOADING:
            return {
                ...state,
                isLoading: payload
            };
        default:
            return {
                ...state
            };
    }
}
export default profileReducer;