import React, { useCallback, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { useTranslation } from "react-i18next";
import styles from "./index.module.scss";

const AppDateRangePicker = ({
  handlePickerDate,
  singleDatePicker = false,
  selectedPickerDate = null,
  maxDate = moment().toDate(),
  className,
  ...rest
}) => {
  const { t } = useTranslation();
  const [date, setDate] = useState(selectedPickerDate);
  const handleApply = useCallback(
    (_, picker) => {
      const pickerDate = `${picker.startDate.format(
        "YYYY-MM-DD"
      )} - ${picker.endDate.format("YYYY-MM-DD")}`;
      const selectedDate = singleDatePicker
        ? picker.startDate.format("YYYY-MM-DD")
        : pickerDate;
      setDate(selectedDate);
      handlePickerDate(selectedDate);
    },
    [handlePickerDate, singleDatePicker]
  );
  return (
    <DateRangePicker
      onApply={handleApply}
      initialSettings={{
        singleDatePicker,
        alwaysShowCalendars: true,
        maxDate,
        locale: {
          format: "YYYY-MM-DD",
        },
      }}
    >
      <div
        id="daterange"
        className={`${styles.selectbox} mb-2 ${className}`}
        {...rest}
      >
        <i className="far fa-calendar-alt mr-1"></i>
        {!date && <span>{t("ranges.selectRange")}</span>}
        {date && <b className="caret">{date}</b>}
      </div>
    </DateRangePicker>
  );
};

export default AppDateRangePicker;
