import React, { memo, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import {
  StrengthChecker,
  generateRandomString,
  printFormError,
} from "../../helpers";
import AppButton from "../button/Button";
import "./index.scss";
const PasswordChangeTab = ({
  isActive,
  user,
  updatePassword,
  isAdmin,
  darkMode,
}) => {
  const [t] = useTranslation();
  const [color, setColor] = useState("red");
  const [passwordLevelText, setPasswordLevelText] = useState("");
  const [randomPassword, setRandomPassword] = useState("");
  let validationShape = {
    password: Yup.string()
      .min(8, t("register.validation.passMin"))
      .max(30, t("register.validation.passMax"))
      .required(t("register.validation.required")),
    passwordRetype: Yup.string()
      .min(8, t("register.validation.passMin"))
      .max(30, t("register.validation.passMax"))
      .required(t("register.validation.required"))
      .when("password", {
        is: (val) => !!(val && val.length > 0),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          t("register.validation.passRetype")
        ),
      }),
  };
  if (!isAdmin) {
    validationShape.currentPassword = Yup.string()
      .min(6, t("register.validation.passMinOld"))
      .max(30, t("register.validation.passMax"))
      .required(t("register.validation.required"));
  }
  const validationSchema = Yup.object().shape(validationShape);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      password: "",
      passwordRetype: "",
      currentPassword: "",
    },
    validationSchema,
    onSubmit: (values) => {
      updatePassword(user.id, values);
      formik.resetForm();
    },
  });

  const handleStrengthChecker = (password) => {
    const strengthLevel = StrengthChecker(password);
    const color =
      strengthLevel === "strong"
        ? "green"
        : strengthLevel === "medium"
        ? "mediumaquamarine"
        : "red";
    setColor(color);
    setPasswordLevelText(strengthLevel);
  };

  const handleGenerateRandomPassword = () => {
    const randomPassword = generateRandomString();
    setRandomPassword(randomPassword);
    formik.setFieldValue("password", randomPassword);
    handleStrengthChecker(randomPassword);
  };

  return (
    <div className={`tab-pane ${isActive ? "active" : ""}`}>
      <form className="horizontal" onSubmit={formik.handleSubmit}>
        <div className="row py-5">
          {!isAdmin && (
            <div className="col-12">
              <label htmlFor="currentPassword" className="labels">
                {t("users.single.changePassword.currentPassword")}
              </label>
              <input
                id="currentPassword"
                type="password"
                className="form-control"
                placeholder={t("users.single.changePassword.currentPassword")}
                {...formik.getFieldProps("currentPassword")}
              />
              {printFormError(formik, "currentPassword")}
            </div>
          )}

          <div className="col-12">
            <label htmlFor="newPassword" className="labels">
              {t("users.single.changePassword.newPassword")}
            </label>
            <div className="d-flex password-input-group">
              {passwordLevelText.length > 0 && (
                <span
                  className={`strength-checker ${
                    darkMode ? " checker-bg-dark" : " checker-bg-light"
                  }`}
                  style={{ color: color }}
                >
                  {t(`password.${passwordLevelText}`)}
                </span>
              )}
              <input
                id="newPassword"
                type={randomPassword.length ? "text" : "password"}
                className="form-control"
                placeholder={t("users.single.changePassword.newPassword")}
                style={{
                  border:
                    passwordLevelText.length > 0 ? `1px solid ${color}` : "",
                }}
                {...formik.getFieldProps("password")}
                onChange={(e) => {
                  handleStrengthChecker(e.target.value);
                  formik.setFieldValue("password", e.target.value);
                }}
              />
              <i
                className="fa fa-key password-generator-icon"
                title={t("password.generate")}
                onClick={() => handleGenerateRandomPassword()}
              ></i>
            </div>
            {printFormError(formik, "password")}
          </div>
          <div className="col-12">
            <label htmlFor="confirmPassword" className="labels">
              {t("users.single.changePassword.confirmPassword")}
            </label>
            <input
              id="confirmPassword"
              type="password"
              className="form-control"
              placeholder={t("users.single.changePassword.confirmPassword")}
              {...formik.getFieldProps("passwordRetype")}
            />
            {printFormError(formik, "passwordRetype")}
          </div>
        </div>
        <div className="mt-2 text-right">
          <AppButton className="btn btn-primary profile-button" type="submit">
            {t("users.single.save")}
          </AppButton>
        </div>
      </form>
    </div>
  );
};

export default memo(PasswordChangeTab);
