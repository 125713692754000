import actionTypes from "../../action-types";
const initialState = {
    isLoading: false,
    zone: {}
};
const adminSingleZoneReducer = (state = initialState, action) => {
    const {type, payload} = action;
    switch (type) {
        case actionTypes.SET_ADMIN_SINGLE_ZONE:
            return {
                ...state,
                zone: payload
            };
        
        case actionTypes.ADMIN_SINGLE_ZONE_LOADING:
            return {
                ...state,
                isLoading: payload
            };
        default:
            return {
                ...state
            };
    }
}
export default adminSingleZoneReducer;