import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import AsyncSelect from "react-select/async";
import AppButton from "../button/Button";
const WebsitesAssigned = ({ user, isActive, updateUser, searchWebsites }) => {
  const defaultOptions = user.own_websites
    ? user.own_websites.map((item) => ({ value: item.id, label: item.url }))
    : [];
  const [websites, setWebsites] = useState(defaultOptions);
  const handleInputChange = (newValue, actionMeta) => {};
  const handleSubmit = (e) => {
    e.preventDefault();
    const data = {
      own_website_ids: websites.map((item) => item.value),
    };
    updateUser(user.id, data);
  };
  const loadOptions = (inputValue, callback) => {
    if (inputValue.length > 2) {
      searchWebsites(inputValue, callback);
    }
  };

  const [t] = useTranslation();

  return (
    <div className={`tab-pane ${isActive ? "active" : ""}`}>
      <form className="horizontal" onSubmit={handleSubmit}>
        <div className="row py-5">
          <AsyncSelect
            defaultValue={defaultOptions}
            isMulti
            defaultOptions={defaultOptions}
            onInputChange={handleInputChange}
            loadOptions={loadOptions}
            onChange={setWebsites}
          />
        </div>
        <div className="mt-5 text-right">
          <AppButton
            //isLoading={isLoading}
            className="btn btn-primary profile-button"
            type="submit"
          >
            {t("users.single.save")}
          </AppButton>
        </div>
      </form>
    </div>
  );
};
export default memo(WebsitesAssigned);
