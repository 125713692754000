import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import Pagination from "react-js-pagination";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PageLoading from "../../../components/page-loading/PageLoading";
import { UserWebsites } from "../../../store/actions/websites";
import { status } from "../../../helpers";
const Websites = ({
  getWebsites,
  data,
  current_page,
  per_page,
  total,
  isLoading,
}) => {
  const [t] = useTranslation();
  useEffect(() => {
    getWebsites();
  }, [getWebsites]);
  const websitesList = data.map((item) => {
    return (
      <tr key={item.id}>
        <td>
          <Link
            title={t("menusidebar.label.codes")}
            to={`/user/websites/${item.id}`}
            className="ml-2 text-bold"
          >
            <i className="fas fa-code"></i>
          </Link>
          <Link
            title={t("common.bannerStat")}
            to={`/user/websites/${item.id}/zones/reports`}
            className="ml-2"
          >
            <i className="far fa-window-maximize"></i>
          </Link>
          <Link
            title={t("menusidebar.label.reports")}
            to={`/user/websites/${item.id}/reports`}
            className="ml-2"
          >
            <i className="fas fa-chart-line"></i>
          </Link>
        </td>
        <td>{item.id}</td>
        <td>
          <span className="ml-2">{item.cname}</span>
        </td>
        <td>{t(status[item?.status]?.text)}</td>
        <td>{item.created_at}</td>
      </tr>
    );
  });
  let template;
  if (isLoading) {
    template = <PageLoading />;
  } else {
    template = (
      <>
        <div className="card">
          <div className="card-body table-responsive p-0">
            <table className="table table-striped table-valign-middle">
              <thead>
                <tr>
                  <th></th>
                  <th>ID</th>
                  <th>{t("dashboard.website.name")}</th>
                  <th>{t("common.status")}</th>
                  <th>{t("common.date")}</th>
                </tr>
              </thead>
              <tbody>{websitesList}</tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="container-fluid">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>{t("dashboard.website.list")}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/user">{t("header.label.home")}</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {t("dashboard.website.list")}
                  </li>
                </ol>
              </div>
              <div className="col-12 text-right">
                <Link to="/user/websites/new" className="btn btn-primary">
                  <i className="fa fa-plus-circle mr-2"></i>
                  {t("websites.button.add")}
                </Link>
              </div>
            </div>
          </div>
        </section>
        {template}
        {total > per_page && (
          <Pagination
            activePage={current_page ? current_page : 0}
            itemsCountPerPage={per_page ? per_page : 0}
            totalItemsCount={total ? total : 0}
            onChange={(pageNumber) => {
              getWebsites(pageNumber);
            }}
            pageRangeDisplayed={8}
            itemClass="page-item"
            linkClass="page-link"
            firstPageText={t("common.firstPage")}
            lastPageText={t("common.lastPage")}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  const { data, current_page, per_page, total, isLoading } = state.websites;
  return {
    data,
    current_page,
    per_page,
    total,
    isLoading,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getWebsites: (pageNumber) => dispatch(UserWebsites(pageNumber)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Websites);
