import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PageLoading from "../../../components/page-loading/PageLoading";
import { appSearch } from "../../../helpers";
import { AdminScriptsAction } from "../../../store/actions/admin/scripts";

const AdminScripts = ({ getScripts, list, isLoading }) => {
  const [queryString, setQueryString] = useState("");
  const [t] = useTranslation();
  useEffect(() => {
    getScripts();
  }, [getScripts]);

  const scriptList = appSearch(list, queryString, "domain").map((website) => {
    return (
      <tr key={website.id}>
        <td>
          <Link to={`/admin/scripts/${website.id}`}>{website.domain}</Link>
        </td>
      </tr>
    );
  });
  let template;
  if (isLoading) {
    template = <PageLoading />;
  } else {
    template = (
      <>
        <div className="card">
          <div className="card-body table-responsive p-0">
            <table className="table table-striped table-valign-middle">
              <thead>
                <tr>
                  <th>
                    {t("common.websites")}
                    <input
                      type="text"
                      placeholder={t("common.search")}
                      className="ml-2"
                      onChange={(e) => setQueryString(e.target.value)}
                    />
                  </th>
                </tr>
              </thead>
              <tbody>{scriptList}</tbody>
            </table>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="container-fluid">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>{t("menusidebar.label.scripts")}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/admin">{t("header.label.home")}</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {t("menusidebar.label.scripts")}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <div className={`website-list-filter`}>{template}</div>
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    isLoading: state.adminScripts.isLoading,
    list: state.adminScripts.list,
  };
};
const mapDispatchToProps = (dispatch) => ({
  getScripts: () => dispatch(AdminScriptsAction()),
});
export default connect(mapStateToProps, mapDispatchToProps)(AdminScripts);
