import actionTypes from "../../../action-types";

const initialState = {
  website: [],
  period: "month",
  reports: {
    sum: 0,
    cpm: 0,
    impressions: 0,
    chartMultiSources: {
      series: [],
      categories: new Set(),
    },
    chartMultiParams: {
      sumDays: [],
      impsDays: [],
      cpmDays: [],
    },
    sourceData: [],
    sumEarnings: 0,
    sumCommission: 0,
  },
  isLoading: false,
  filterShow: false,
};

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.WEBSITE_REPORTS:
      return {
        ...state,
        website: payload.website,
        reports: payload.data,
      };
    case actionTypes.WEBSITE_REPORTS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case actionTypes.SET_WEBSITE_PERIOD:
      return {
        ...state,
        period: payload,
      };

    default:
      return {
        ...state,
      };
  }
};
export default reducer;
