import { toast } from "react-toastify";
import { http } from "../../../helpers";
import i18n from "i18next";
import actionTypes from "../../action-types";

export const GetQuestionsForAdminAction = () => {
  return async (dispatch) => {
    try {
      const response = await http.get("/questions");
      const { data } = response;
      dispatch({ type: actionTypes.QUESTIONS_LOAD, payload: data.data });
    } catch (error) {
      console.error(error);
    }
  };
};

export const GetQuestionAction = (id) => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.SINGLE_QUESTIONS_LOADING, payload: true });
    try {
      const response = await http.get(`/questions/${id}`);
      const { data } = response;
      dispatch({ type: actionTypes.SINGLE_QUESTIONS, payload: data.data });
      dispatch({
        type: actionTypes.SINGLE_QUESTIONS_LOADING,
        payload: false,
      });
    } catch (error) {
      console.error(error);
    }
  };
};

export const SetQuestionAction = (data) => {
  return async () => {
    try {
      await http.post("/questions", data);
      toast.success(`${i18n.t("questions.submitted")}`);
    } catch (error) {
      console.error(error);
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const DeleteQuestionsAction = (id) => {
  return async (dispatch) => {
    try {
      await http.delete(`/questions/${id}`);
      dispatch({ type: actionTypes.QUESTION_DELETE, payload: id });
      toast.success(i18n.t("questions.deleted"));
    } catch (error) {
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const EditQuestionAction = (id, params) => {
  return async (dispatch) => {
    try {
      const response = await http.put(`/questions/${id}`, params);
      const { data } = response;
      dispatch({ type: actionTypes.SINGLE_QUESTIONS, payload: data.data });
      toast.success(i18n.t("questions.updated"));
    } catch (error) {
      console.error(error);
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const ChangeQuestionStatusAction = (id, status) => {
  return async (dispatch) => {
    try {
      await http.patch(`/questions/${id}`, status);
      dispatch({ type: actionTypes.QUESTION_CHANGED, payload: id });
      toast.success(i18n.t("questions.statusChanged"));
    } catch (error) {
      console.error(error);
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};
