import React from 'react';
import classes from './PageLoading.module.css';

const PageLoading = () => {
    return (
        <div className={classes.loading}>
            <span>D</span>
            <span>I</span>
            <span>G</span>
            <span>I</span>
            <span>T</span>
            <span>A</span>
            <span>L</span>
            <span>C</span>
            <span>A</span>
            <span>R</span>
            <span>A</span>
            <span>M</span>
            <span>E</span>
            <span>L</span>
        </div>
    );
};

export default PageLoading;
