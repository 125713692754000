import React, { memo, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PageLoading from "../../../components/page-loading/PageLoading";
import {
  AdminUserSingleAction,
  ExportOrdAction,
  GetManagerByEmailAction,
} from "../../../store/actions/admin/users";
import PrimaryTab from "../../../components/tabs/PrimaryTab";
import BillingTab from "../../../components/tabs/BillingTab";
import PasswordChangeTab from "../../../components/tabs/PasswordChangeTab";
import {
  SearchWebsitesAction,
  UserUpdateAction,
} from "../../../store/actions/users";
import WebsitesAssigned from "../../../components/tabs/WebsitesAssigned";
import { history } from "../../../helpers";
import Answers from "../../../components/tabs/Answers";
import Export from "../../../components/tabs/Export";

const EditUser = ({
  match,
  isLoading,
  user,
  getUser,
  updateUser,
  editable,
  searchWebsites,
  getManager,
  darkMode,
  exportWebsiteXlsx,
  currentUser,
}) => {
  const { url, params } = match;
  const { id } = params;
  const [t] = useTranslation();
  const tabUrl = history.location.search;
  let tab = "PRIMARY";
  if (tabUrl.includes("?tab=")) {
    tab = tabUrl.split("?tab=")[1];
  }
  const [activeTab, setActiveTab] = useState(tab.toLocaleUpperCase());
  const toggle = useCallback(
    (tab) => {
      history.push({
        pathname: url,
        search: `?tab=${tab.toLowerCase()}`,
      });
      if (activeTab !== tab) setActiveTab(tab);
    },
    [activeTab, url]
  );
  useEffect(() => {
    getUser(id);
  }, [id, getUser]);
  let template;
  if (isLoading) {
    template = <PageLoading />;
  } else {
    template = (
      <>
        <div className="card rounded mt-5 mb-5">
          <div className="card-header">
            <ul className="nav nav-tabs mb-2" role="tablist">
              <li className="nav-item">
                <button
                  type="button"
                  className={`nav-link ${
                    activeTab === "PRIMARY" ? "active" : ""
                  }`}
                  onClick={() => toggle("PRIMARY")}
                >
                  {t("common.primarySettings")}
                </button>
              </li>
              <li className="nav-item">
                <button
                  type="button"
                  className={`nav-link ${
                    activeTab === "BILLING" ? "active" : ""
                  }`}
                  onClick={() => toggle("BILLING")}
                >
                  {t("common.billingDetails")}
                </button>
              </li>
              <li className="nav-item">
                <button
                  type="button"
                  className={`nav-link ${
                    activeTab === "WEBSITES" ? "active" : ""
                  }`}
                  onClick={() => toggle("WEBSITES")}
                >
                  {t("users.single.websitesAssigned")}
                </button>
              </li>
              <li className="nav-item">
                <button
                  type="button"
                  className={`nav-link ${
                    activeTab === "PASSWORD" ? "active" : ""
                  }`}
                  onClick={() => toggle("PASSWORD")}
                >
                  {t("users.single.password")}
                </button>
              </li>
              <li className="nav-item">
                <button
                  type="button"
                  className={`nav-link ${
                    activeTab === "ANSWERS" ? "active" : ""
                  }`}
                  onClick={() => toggle("ANSWERS")}
                >
                  {t("users.single.answers")}
                </button>
              </li>
              {(user.entity === "individual" || user.entity === "legal") && (
                <li className="nav-item">
                  <button
                    type="button"
                    className={`nav-link ${
                      activeTab === "EXPORT" ? "active" : ""
                    }`}
                    onClick={() => toggle("EXPORT")}
                  >
                    {t("export.label.export")}
                  </button>
                </li>
              )}
            </ul>
          </div>
          <div className="card-body">
            <div className="tab-content">
              <PrimaryTab
                updateUser={updateUser}
                user={user}
                isActive={activeTab === "PRIMARY"}
                isLoading={isLoading}
                getManager={getManager}
                editable={editable}
              />
              <BillingTab
                editable={true}
                updateUser={updateUser}
                user={user}
                isActive={activeTab === "BILLING"}
                isLoading={isLoading}
              />
              <PasswordChangeTab
                updatePassword={updateUser}
                isAdmin={true}
                user={user}
                isActive={activeTab === "PASSWORD"}
                isLoading={isLoading}
                darkMode={darkMode}
              />
              <WebsitesAssigned
                user={user}
                isActive={activeTab === "WEBSITES"}
                isLoading={isLoading}
                updateUser={updateUser}
                searchWebsites={searchWebsites}
              />
              <Answers
                isLoading={isLoading}
                userAnswers={user.answers}
                isActive={activeTab === "ANSWERS"}
              />
              {(user.entity === "individual" || user.entity === "legal") && (
                <Export
                  isLoading={isLoading}
                  user={user}
                  isActive={activeTab === "EXPORT"}
                  exportWebsiteXlsx={exportWebsiteXlsx}
                  adminUser={currentUser.role_id < 3 ? currentUser : null}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="container-fluid">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                {<h1>{t("users.single.pageTitle")}</h1>}
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/">{t("header.label.home")}</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/users">
                      {t("menusidebar.label.users")}
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">{id}</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        {template}
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  const { isLoading, user, websites } = state.adminSingleUser;
  const { currentUser } = state.auth;
  const { role_id } = currentUser;
  const editable =
    (role_id === 1 || role_id === 2) &&
    user.role_id !== 1 &&
    user.role_id !== 2;
  return {
    isLoading,
    user,
    websites,
    editable,
    darkMode: state.theme.darkMode,
    currentUser,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateUser: (id, data) => dispatch(UserUpdateAction(id, data)),
    getUser: (id) => dispatch(AdminUserSingleAction(id)),
    searchWebsites: (name, callback) =>
      dispatch(SearchWebsitesAction(name, callback)),
    getManager: (email, cbFn) => dispatch(GetManagerByEmailAction(email, cbFn)),
    exportWebsiteXlsx: (user, date) => {
      dispatch(ExportOrdAction(user, date));
    },
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(memo(EditUser));
