export const sourceTitles = {
  yandex: "Yandex",
  adsense: "Google",
  admixer: "AdMixer",
  adriver: "AdRiver",
  targetmy: "MyTarget",
  admanager: "AdManager",
  between: "Between",
  adlive: "Adlive",
  rtbhouse: "RTB House",
  soloway: "Soloway",
  getintent: "GetIntent",
  criteo: "Criteo",
  criteond: "Criteo",
  alfasense: "Alfasense",
  moneytizer: "Prebid",
  moevideo: "Moevideo",
  buzzoola: "Buzzoola",
  bidvol: "Bidvol",
  otm: "OTM",
  rtbsape: "RTB Sape",
  adtelligent: "Adtelligent",
  wmg: "WMG",
  relap: "Relap",
  hybrid: "Hybrid",
  gnezdo: "Gnezdo",
  kadam: "Kadam",
  dc: "Digital Caramel",
  luckyads: "Luckyads",
  videonow: "Videonow",
  adwile: "Adwile",
  astralab: "Astralab",
  solta: "Solta",
  mediasniper: "Mediasniper",
  clickscloud: "ClicksCloud",
  roxot: "Roxot",
  dcvideo: "DC Video",
};

export const matchSources = [
  "yandex",
  "adsense",
  "admanager",
  "otm",
  "between",
  "buzzoola",
  "criteo",
  "targetmy",
  "bidvol",
  "moevideo",
  "alfasense",
  "rtbsape",
  "hybrid",
  "gnezdo",
  "kadam",
  "dc",
  "luckyads",
  "videonow",
  "adwile",
  "solta",
  "clickscloud",
  "roxot",
  "dcvideo",
];
