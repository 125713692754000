import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

import styles from "./index.module.scss";

import { http } from "../../helpers";

import AppButton from "../button/Button";

const AppImportFile = ({ url, fileNameRegex, fileTypeError }) => {
  const { t } = useTranslation();

  const [file, setFile] = useState(null);
  const [fileName, setFaleName] = useState("");

  const handleImport = async () => {
    const formData = new FormData();
    formData.append("file", file);

    await http
      .post(url, formData)
      .then((response) => {
        toast.success(`${t("import.message.success")}`);
      })
      .catch((err) => {
        toast.error(`${t("import.message.error")}`);
      });
  };

  const handleFileChange = (event) => {
    const uploadedFile = event.target.files[0];
    if (fileNameRegex.test(uploadedFile.name)) {
      setFile(uploadedFile);
      setFaleName(uploadedFile.name);
    } else {
      toast.error(`${fileTypeError}`);
    }
  };

  return (
    <form>
      <div className="d-flex flex-row">
        <div className={`col-8 pl-0 ${styles.fileUpload}`}>
          <div className={`${styles.fileSelect}`}>
            <div className={`${styles.fileSelectButton}`} id="fileName">
              Choose File
            </div>
            <div className={`${styles.fileSelectName}`} id="noFile">
              {fileName || "No file chosen..."}
            </div>
            <input type="file" name="chooseFile" onChange={handleFileChange} />
          </div>
        </div>

        <AppButton onClick={handleImport} icon="import">
          {t("import.label.import")}
        </AppButton>
      </div>
    </form>
  );
};

export default AppImportFile;
