import React, { memo, useCallback, useState } from "react";
import * as Yup from "yup";
import { ErrorMessage, Field, FieldArray, Form, Formik } from "formik";
import AppButton from "../../../../components/button/Button";
import { useTranslation } from "react-i18next";
import { zoneSize } from "../../../../helpers";
import ReconfirmModal from "../../../../components/modals/reconfirm";

const ZonesFormList = ({ zones, id, updateWebsiteZones, deleteZone }) => {
  const [reconfirmModal, setReconfirmModal] = useState({
    show: false,
    title: null,
    onHide: null,
    onSubmit: null,
    theme: "",
  });
  const { t } = useTranslation();
  const handleConfigZoneSubmit = useCallback(
    (zones) => {
      const newZones = JSON.parse(JSON.stringify(zones));
      newZones.map((zone) => {
        const zoneSizeList = zoneSize.find(
          (item) => item.id === Number(zone.size)
        );
        if (zoneSizeList) {
          zone.size = zoneSizeList;
        }
        return zone;
      });
      updateWebsiteZones(id, newZones);
    },
    [id, updateWebsiteZones]
  );

  const zoneSizeList = zoneSize.map((item) => {
    return (
      <option key={item.id} label={item.name} value={item.id}>
        {item.name}
      </option>
    );
  });
  const handleDeleteZone = useCallback(
    (zoneID, zoneName) => {
      setReconfirmModal({
        title: t("zone.deleting") + " " + zoneName,
        show: true,
        onSubmit: () => {
          deleteZone(zoneID);
          handleCloseReconfirmModal();
        },
        onHide: () => handleCloseReconfirmModal(),
        theme: "danger",
      });
    },
    [deleteZone, t]
  );
  const handleCloseReconfirmModal = () => {
    setReconfirmModal({
      title: "",
      show: false,
      onSubmit: () => {},
      onHide: () => {},
    });
  };
  const configFormikZone = {
    enableReinitialize: true,
    initialValues: {
      zones:
        zones.map((zone) => {
          const { id, name, size_id, script = "", status, details = [] } = zone;
          return {
            id,
            name,
            size: Number(size_id),
            script: script ?? "",
            status,
            details,
          };
        }) ?? [],
    },
    validationSchema: Yup.object({
      zones: Yup.array().of(
        Yup.object().shape({
          name: Yup.string().required(t("zone.validations.name")),
          size: Yup.string().required(t("zone.validations.size")),
          script: Yup.string().nullable(true),
          status: Yup.string(),
        })
      ),
    }),
  };
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={configFormikZone.initialValues}
        validationSchema={configFormikZone.validationSchema}
        onSubmit={({ zones }) => handleConfigZoneSubmit(zones)}
      >
        {({ errors, values, touched, setValues }) => {
          return (
            <Form>
              <div className="form-body">
                <FieldArray name="zones">
                  {() =>
                    values.zones.map((zone, i) => {
                      const zonesErrors =
                        errors.zones?.length && errors.zones[i]
                          ? errors.zones[i]
                          : {};
                      const zonesTouched =
                        touched.zones?.length && touched.zones[i]
                          ? touched.zones[i]
                          : {};
                      return (
                        <div className="row" key={zone.id}>
                          <div className="form-group col-md-2">
                            <label htmlFor={`zoneName-${zone.id}`}>
                              {t("zone.name")}
                            </label>
                            <Field
                              id={`zoneName-${zone.id}`}
                              name={`zones.${i}.name`}
                              type="text"
                              className={
                                "form-control" +
                                (zonesErrors.name && zonesTouched.name
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name={`zones.${i}.name`}
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="form-group col-md-2">
                            <label htmlFor={`zoneSize-${zone.size}`}>
                              {t("zone.size")}
                            </label>
                            <Field name="zones-size">
                              {({ field }) => {
                                return (
                                  <select
                                    {...field}
                                    id={`zoneSize-${zone.size}`}
                                    name={`zones.${i}.size`}
                                    type="text"
                                    className={
                                      "form-control" +
                                      (zonesErrors.size && zonesTouched.size
                                        ? " is-invalid"
                                        : "")
                                    }
                                    value={
                                      zoneSize.find(
                                        (item) => item.id === zone.size
                                      )?.id
                                    }
                                  >
                                    <option value=""></option>
                                    {zoneSizeList}
                                  </select>
                                );
                              }}
                            </Field>
                            <ErrorMessage
                              name={`zones.${i}.size`}
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="form-group col-md-4">
                            <label htmlFor={`zoneScript-${zone.id}`}>
                              {t("zone.script")}
                            </label>
                            <Field
                              id={`zoneScript-${zone.id}`}
                              component="textarea"
                              rows={8}
                              name={`zones.${i}.script`}
                              type="text"
                              className={
                                "form-control" +
                                (zonesErrors.script && zonesTouched.script
                                  ? " is-invalid"
                                  : "")
                              }
                            />
                            <ErrorMessage
                              name={`zones.${i}.script`}
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>
                          <div className="form-group col-md-2">
                            <label>{t("zone.status")}</label>
                            <div className="form-check">
                              <Field
                                name={`zones.${i}.status`}
                                id={`approved-${zone.id}`}
                                className="form-check-input"
                                type="radio"
                                value="approved"
                              />

                              <label
                                htmlFor={`approved-${zone.id}`}
                                className="form-check-label"
                              >
                                {t("zone.statuses.approved")}
                              </label>
                            </div>
                            <div className="form-check">
                              <Field
                                name={`zones.${i}.status`}
                                type="radio"
                                id={`pending-${zone.id}`}
                                className="form-check-input"
                                value="pending"
                              />
                              <label
                                htmlFor={`pending-${zone.id}`}
                                className="form-check-label"
                              >
                                {t("zone.statuses.pending")}
                              </label>
                            </div>
                            <div className="form-check">
                              <Field
                                name={`zones.${i}.status`}
                                type="radio"
                                id={`canceled-${zone.id}`}
                                className="form-check-input"
                                value="canceled"
                              />
                              <label
                                htmlFor={`canceled-${zone.id}`}
                                className="form-check-label"
                              >
                                {t("zone.statuses.canceled")}
                              </label>
                            </div>
                          </div>

                          <div className="form-group col-md-1">
                            <AppButton
                              type="button"
                              className="btn btn-danger"
                              onClick={() =>
                                handleDeleteZone(zone.id, zone.name)
                              }
                            >
                              {t("zone.delete")}
                            </AppButton>
                          </div>
                        </div>
                      );
                    })
                  }
                </FieldArray>
              </div>
              <div>
                <AppButton type="submit" className="btn btn-primary">
                  {t("website.save")}
                </AppButton>
              </div>
            </Form>
          );
        }}
      </Formik>
      {reconfirmModal.show && (
        <ReconfirmModal
          title={reconfirmModal.title}
          show={reconfirmModal.show}
          onHide={reconfirmModal.onHide}
          onSubmit={reconfirmModal.onSubmit}
          theme={reconfirmModal.theme}
        />
      )}
    </>
  );
};

export default memo(ZonesFormList);
