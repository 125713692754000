import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { ThemeContext } from "../../contexts/ThemeContext";
import AppButton from "../button/Button";
import ManagerList from "../manager-list";

const ZoneListFilter = ({
  filterName,
  filterEmail,
  filterWebsite,
  filterStatus,
  filterManager,
  filterUser,
  setFilterName,
  setFilterEmail,
  setFilterWebsite,
  setFilterStatus,
  setFilterManager,
  setFilterUser,
  handleApply,
  isLoading,
}) => {
  const [filterShow, setFilterShow] = useState(true);
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  const { width } = theme;
  let mobile = Math.floor(width) < 528;
  let caret = Math.floor(width) < 1168 ? "caretDown" : "caretRight";
  return (
    <div className="website-list-filter">
      <div className="row">
        <div
          className={`col-12 d-flex ${
            mobile ? "direction-column" : "direction-row"
          }`}
        >
          <AppButton
            icon={filterShow ? caret : "filter"}
            theme="default"
            className="filterButton mb-3"
            onClick={() => setFilterShow(!filterShow)}
          >
            {t("websites.button.filter")}
          </AppButton>
          {filterShow && (
            <ul
              className={`other-date-options list-unstyled d-flex mb-0 direction-`}
            >
              <li>
                <div className="input-group mb-3">
                  <input
                    value={filterName}
                    className="form-control"
                    placeholder={t("zone.name")}
                    onChange={(e) => setFilterName(e.target.value)}
                  />
                </div>
              </li>
              <li>
                <div className="input-group mb-3">
                  <input
                    value={filterWebsite}
                    className="form-control"
                    placeholder={t("common.website")}
                    onChange={(e) => setFilterWebsite(e.target.value)}
                  />
                </div>
              </li>
              <li>
                <div className="input-group mb-3">
                  <input
                    value={filterEmail}
                    className="form-control"
                    placeholder={t("common.email")}
                    onChange={(e) => setFilterEmail(e.target.value)}
                  />
                </div>
              </li>
              <li>
                <div className="input-group mb-3">
                  <select
                    className="custom-select"
                    id="entityGroupSelect"
                    onChange={(e) => setFilterStatus(e.target.value)}
                    value={filterStatus}
                  >
                    <option value="">{`${t("common.choose")} ${t(
                      "common.status"
                    )}`}</option>
                    <option value="approved">{t("website.approved")}</option>
                    <option value="pending">{t("website.pending")}</option>
                    <option value="canceled">{t("website.canceled")}</option>
                  </select>
                </div>
              </li>
              <li>
                <div className="input-group mb-3">
                  <ManagerList
                    className="custom-select"
                    onChange={setFilterManager}
                  />
                </div>
              </li>
              <li>
                <div>
                  <AppButton
                    icon="filter"
                    isLoading={isLoading}
                    theme="primary"
                    onClick={() => {
                      handleApply(
                        1,
                        filterName,
                        filterEmail,
                        filterWebsite,
                        filterStatus,
                        filterManager
                      );
                    }}
                  >
                    {t("websites.button.filter")}
                  </AppButton>
                </div>
              </li>
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};
export default ZoneListFilter;
