import actionTypes from "../../action-types";

const initialState = {
    darkMode: localStorage.getItem('darkMode') === 'true' ? true : false,
    isMenuSidebarCollapsed: window.innerWidth >= 992 ? false : true,
    width:  window.innerWidth
};

const themeReducer = (state = initialState, action) => {
    const { type } = action;
    switch (type) {
        case actionTypes.TOGGLE_DARK_MODE:
            localStorage.setItem('darkMode', !state.darkMode);
            return {
                ...state,
                darkMode: !state.darkMode
            }
        case actionTypes.TOGGLE_MENU_SIDEBAR:
            return {
                ...state,
                isMenuSidebarCollapsed: !state.isMenuSidebarCollapsed
            }
        default:
            return {
                ...state
            }
    }
}

export default themeReducer;
