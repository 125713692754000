import React, { memo, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import AppButton from "../../../components/button/Button";
import { printFormError, zoneSize } from "../../../helpers";
import {
  AdminUpdateZoneAction,
  AdminZoneSingleAction,
} from "../../../store/actions/admin/zones";
import PageLoading from "../../../components/page-loading/PageLoading";

const EditZone = ({ match, isLoading, zone, getZone, editZone }) => {
  const { id } = match.params;
  const [t] = useTranslation();

  useEffect(() => {
    getZone(id);
  }, [id, getZone]);

  const zoneSizeList = zoneSize.map((item) => {
    return (
      <option key={item.id} label={item.name} value={item.id}>
        {item.name}
      </option>
    );
  });

  const configFormik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: zone.name,
      status: zone.status,
      sizeid: Number(zone.size_id),
      script: zone.script,
    },
    validationSchema: Yup.object({
      name: Yup.string().required(t("zone.validations.name")),
      sizeid: Yup.string().required(t("zone.validations.sizeid")),
      script: Yup.string().nullable(true),
      status: Yup.mixed().oneOf(["approved", "pending", "canceled"]),
    }),
    onSubmit: (data) => {
      let sizeName = Object.values(zoneSize).find(
        (item) => item.id === Number(data.sizeid)
      );
      data["size"] = sizeName.name;
      editZone(id, data);
    },
  });

  if (isLoading) {
    return <PageLoading />;
  } else {
    return (
      <div className="container-fluid">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>{t("zone.editZones")}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/admin">{t("header.label.home")}</Link>
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/admin/zones">{t("common.zones")}</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {t("zone.editZones")}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <div className="container-fluid ml-0">
          <div className="card m-3">
            <div className="card-body">
              <form onSubmit={configFormik.handleSubmit}>
                <div className="list-group list-group-flush">
                  <div
                    className={`row justify-content-between mb-2 zone-block`}
                  >
                    <div className="form-group col-md-2 col-sm-12">
                      <label htmlFor={`zoneName-${zone.id}`}>
                        {t("zone.name")}
                      </label>
                      <input
                        id={`zoneName-${zone.id}`}
                        name={`zone.name`}
                        placeholder={t("zone.name")}
                        type="text"
                        {...configFormik.getFieldProps("name")}
                        className={"form-control"}
                      />
                      {printFormError(configFormik, "name")}
                    </div>
                    <div className="form-group col-md-2 col-sm-12">
                      <label htmlFor={`zoneSize-${zone.size}`}>
                        {t("zone.size")}
                      </label>
                      <select
                        id={`zoneSize-${zone.size}`}
                        name={`zone.size`}
                        defaultValue={zone.size_id}
                        {...configFormik.getFieldProps("sizeid")}
                        value={
                          zoneSize.find((item) => item.id === zone.size_id)?.id
                        }
                        type="text"
                        className={"form-control"}
                      >
                        <option value=""></option>
                        {zoneSizeList}
                      </select>
                      {printFormError(configFormik, "sizeid")}
                    </div>
                    <div className="col-md-3 col-sm-12 form-group">
                      <label>{t("zone.script")}</label>
                      <textarea
                        name={`zone.script`}
                        as="textarea"
                        placeholder={t("zone.script")}
                        {...configFormik.getFieldProps("script")}
                        className={"form-control"}
                      ></textarea>
                      {printFormError(configFormik, "script")}
                    </div>
                    <div className="col-md-3 col-sm-12 form-group">
                      <div className="form-group">
                        <label className="form-check-label text-bold">
                          {t("website.label.status")}
                        </label>
                        <div className="form-check col-md-2">
                          <input
                            id="approved"
                            type="radio"
                            name="status"
                            className="form-check-input"
                            checked={configFormik.values.status === "approved"}
                            {...configFormik.getFieldProps("status")}
                            value="approved"
                          />
                          <label
                            htmlFor="approved"
                            className="form-check-label"
                          >
                            {t("website.approved")}
                          </label>
                        </div>

                        <div className="form-check">
                          <input
                            id="pending"
                            type="radio"
                            name="status"
                            className="form-check-input"
                            {...configFormik.getFieldProps("status")}
                            checked={configFormik.values.status === "pending"}
                            value="pending"
                          />
                          <label htmlFor="pending" className="form-check-label">
                            {t("website.pending")}
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            id="canceled"
                            type="radio"
                            name="status"
                            className="form-check-input"
                            {...configFormik.getFieldProps("status")}
                            checked={configFormik.values.status === "canceled"}
                            value="canceled"
                          />
                          <label
                            htmlFor="canceled"
                            className="form-check-label"
                          >
                            {t("website.canceled")}
                          </label>
                        </div>
                        {printFormError(configFormik, "status")}
                      </div>
                    </div>
                  </div>
                  <div className="form-group text-right">
                    <div className="col p-0">
                      <AppButton type="submit" theme="primary">
                        {t("zone.save")}
                      </AppButton>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

const mapStateToProps = (state) => {
  const { isLoading, zone } = state.adminSingleZone;
  return { isLoading, zone };
};

const mapDispatchToProps = (dispatch) => ({
  editZone: (id, data) => dispatch(AdminUpdateZoneAction(id, data)),
  getZone: (id) => dispatch(AdminZoneSingleAction(id)),
});
export default connect(mapStateToProps, mapDispatchToProps)(memo(EditZone));
