import actionTypes from "../../action-types";
const initialState = {
  isLoading: false,
  bonus: null,
  variants: [],
  showModal: false,
};
const userSingleReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_BONUS:
      return {
        ...state,
        bonus: payload.bonus,
        variants: payload.variants,
      };
    case actionTypes.SHOW_CONGRATULATIONS_MODAL:
      return {
        ...state,
        showModal: payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default userSingleReducer;
