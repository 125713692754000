import actionTypes from "../../action-types";

const initialState = {
  isLoading: false,
  allBonuses: [{ name: "", type: "" }],
  interval: null,
};

const adminBonusReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_ADMIN_BONUS:
      return {
        ...state,
        allBonuses: payload.bonuses.length
          ? [...payload.bonuses]
          : [...state.allBonuses],
        interval: payload.interval,
      };
    case actionTypes.SET_ADMIN_BONUS_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    default:
      return {
        ...state,
      };
  }
};

export default adminBonusReducer;
