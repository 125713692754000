import React, { memo, useCallback, useContext, useState } from "react";
import { connect } from "react-redux";
import { NavLink, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import styles from "./index.module.css";
import { ThemeContext } from "../../../contexts/ThemeContext";
import { useLocation } from "react-router-dom";
import routes from "../../../routes/routes";
import useHover from "../../../hooks/useHover";
import { getUserPath } from "../../../helpers";
// const steps = [
//   {
//     element: "#owner.nav-link",
//     intro: "test 1",
//     position: "right",
//     tooltipClass: "myTooltipClass",
//     highlightClass: "myHighlightClass",
//   },
//   {
//     element: "#ownerwebsites.nav-link",
//     intro: "test 2",
//     position: "right",
//   },
//   {
//     element: "#ownerwebsitesreports.nav-link",
//     intro: "test 3",
//     position: "right",
//   },
//   {
//     element: ".flag-icon",
//     intro: "test 3",
//     position: "right",
//   },
// ];

const MenuSidebar = ({ user }) => {
  const location = useLocation();
  const { pathname } = location;
  const [t] = useTranslation();
  const theme = useContext(ThemeContext);
  const { darkMode, toggleMenuSidebar, isMenuSidebarCollapsed, width } = theme;
  const [showSubMenu, setShowWebMenu] = useState(new Set());
  const [hoverRef, isHovered] = useHover();

  const toggleSubMenu = useCallback(
    (e, index) => {
      e.preventDefault();
      let subMenues = new Set(showSubMenu);
      if (subMenues.has(index)) {
        subMenues.delete(index);
      } else {
        subMenues.add(index);
      }
      setShowWebMenu(subMenues);
    },
    [showSubMenu]
  );

  return (
    <aside
      ref={hoverRef}
      className={`main-sidebar sidebar-${
        darkMode ? "dark" : "light"
      }-orange elevation-4`}
    >
      <Link to="/" className="brand-link">
        <img
          src={`/img/logo${darkMode ? "-dark" : ""}${
            isMenuSidebarCollapsed && !isHovered ? "-collapsed" : ""
          }.png`}
          alt="Digital Caramel Logo"
          className={styles.logo}
        />
      </Link>
      <div className="sidebar">
        <div className="user-panel mt-3 pb-3 mb-3 d-flex">
          <div className="image">
            <img
              src={"/img/default-profile.png"}
              className="img-circle elevation-2"
              alt="User"
            />
          </div>
          <div className="info">
            <Link to={getUserPath(user.role_id, "profile")} className="d-block">
              {user.fullname}
            </Link>
          </div>
        </div>
        <nav className="mt-2 ">
          <ul
            className="nav nav-pills nav-sidebar flex-column nav-legacy"
            data-widget="treeview"
            role="menu"
            data-accordion="false"
          >
            {
              // TODO make <Nav /> component
            }
            {routes
              .filter((item) => item.roles.includes(user.role))
              .filter((item) => item.inSidebar)
              .map((item, index) => {
                let { children } = item;
                return (
                  <li
                    className={`nav-item ${
                      showSubMenu.has(index) ||
                      children?.filter((child) => child.path === pathname) // TODO: fix this
                        .length > 0
                        ? "menu-open"
                        : ""
                    }`}
                    key={item.path}
                  >
                    <NavLink
                      id={item.path.replaceAll("/", "")}
                      to={{
                        pathname: item.path,
                      }}
                      target={item.external ? "_blank" : "_self"}
                      exact
                      className="nav-link"
                      activeClassName={`${
                        children?.length ? "" : styles.active
                      }`}
                      onClick={
                        children?.length
                          ? (e) => toggleSubMenu(e, index)
                          : width <= 992
                          ? toggleMenuSidebar
                          : undefined
                      }
                    >
                      <i className={`nav-icon fas ${item.icon}`} />
                      <p className="">
                        {t(item.name)}
                        {children?.length && (
                          <i className="fas fa-angle-left right"></i>
                        )}
                        {item.badge && (
                          <span className="badge badge-info top position-relative">
                            {item.badge}
                          </span>
                        )}
                      </p>
                    </NavLink>

                    {children?.length && (
                      <ul className="nav nav-treeview">
                        {children.map((subMenu) => {
                          return (
                            <li className="nav-item" key={subMenu.path}>
                              <NavLink
                                key={subMenu.path}
                                to={subMenu.path}
                                exact
                                className="nav-link"
                                activeClassName={`${styles.active}`}
                                onClick={
                                  width <= 992 ? toggleMenuSidebar : undefined
                                }
                              >
                                <i
                                  className={`fas ${subMenu.icon} nav-icon`}
                                ></i>
                                <p>{t(subMenu.name)}</p>
                              </NavLink>
                            </li>
                          );
                        })}
                      </ul>
                    )}
                  </li>
                );
              })}
            {!!user.bonus && (
              <li className={`nav-item`}>
                <NavLink
                  id="/userbonus"
                  to={{
                    pathname: "/user/bonus",
                  }}
                  target={"_self"}
                  exact
                  className="nav-link"
                  activeClassName={`${styles.active}`}
                >
                  <i className="nav-icon fas fa-star"></i>
                  <p className="">
                    {t("menusidebar.label.bonus")}
                    <span className="badge badge-info top position-relative">
                      New
                    </span>
                  </p>
                </NavLink>
              </li>
            )}
          </ul>
        </nav>
      </div>
      {/* {user.role === "owner" && (
        <Steps
          enabled
          steps={steps}
          initialStep={0}
          onExit={(step) => {
            if (step === steps.length - 1) {
            }
          }}
          options={{
            nextLabel: t("common.next"),
            prevLabel: t("common.previous"),
            finishLabel: t("common.finish"),
            finishButtonClasses: "btn btn-success",
            nextButtonClasses: "btn btn-primary",
          }}
          onBeforeChange={(step) => {
          }}
        />
      )} */}
    </aside>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.currentUser,
});

export default connect(mapStateToProps, null)(memo(MenuSidebar));
