import actionTypes from "../../action-types";
import { http } from "../../../helpers";
import Cookies from "js-cookie";
import i18n from "i18next";
import { toast } from "react-toastify";
const activateUser = (token) => (dispatch) => {
  http
    .get(`/confirmed/${token}`)
    .then((response) => {
      dispatch({ type: actionTypes.USER_ACTIVATE, activate: true });
      Cookies.remove("confirm-token");
    })
    .catch((err) => {
      toast.error(`${i18n.t("confirm.error.incorrect-token")}`);
    });
};

export default activateUser;
