import React, { useRef, useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { connect } from "react-redux";
//import {DateTime} from 'luxon';
import { useTranslation } from "react-i18next";

import * as ActionTypes from "../../../../store/actions";
import { getUserPath } from "../../../../helpers";

const UserDropdown = ({ user, onUserLogout }) => {
  const dropdownRef = useRef(null);
  const history = useHistory();
  const [t] = useTranslation();

  const [dropdownState, updateDropdownState] = useState({
    isDropdownOpen: false,
  });

  const toggleDropdown = () => {
    updateDropdownState({ isDropdownOpen: !dropdownState.isDropdownOpen });
  };

  const handleClickOutside = (event) => {
    if (
      dropdownRef &&
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target)
    ) {
      updateDropdownState({ isDropdownOpen: false });
    }
  };

  const logOut = (event) => {
    toggleDropdown();
    event.preventDefault();
    onUserLogout();
    history.push("/login");
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside, false);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside, false);
    };
  });

  let className = "dropdown-menu dropdown-menu-lg dropdown-menu-right";

  if (dropdownState.isDropdownOpen) {
    className += " show";
  }

  return (
    <li ref={dropdownRef} className="nav-item dropdown user-menu">
      <button
        onClick={toggleDropdown}
        type="button"
        className="nav-link dropdown-toggle"
        data-toggle="dropdown"
      >
        <img
          src={"/img/default-profile.png"}
          className="user-image img-circle elevation-2"
          alt="User"
        />
        {/* <span className="d-none d-md-inline">{email}</span> */}
      </button>
      <ul className={className}>
        <li className="user-header">
          <img
            src={"/img/default-profile.png"}
            className="img-circle elevation-2"
            alt="User"
          />
          <p>
            {user.fullname}
            <small>
              <span>{user.email}</span>
            </small>
          </p>
        </li>
        <li className="user-footer">
          <Link
            to={getUserPath(user.role_id, "profile")}
            onClick={toggleDropdown}
            className="btn btn-default btn-flat"
          >
            <i className="fas fa-user-cog mr-2" />
            {t("header.user.profile")}
          </Link>
          <button
            type="button"
            className="btn btn-default btn-flat float-right"
            onClick={logOut}
          >
            <i className="fas fa-sign-out-alt mr-2" />
            {t("login.button.signOut")}
          </button>
        </li>
      </ul>
    </li>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  onUserLogout: () => dispatch({ type: ActionTypes.LOGOUT_USER }),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserDropdown);
