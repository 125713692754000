import actionTypes from "../../action-types";

const initialState = {
    current_page: 1,
    data: [],
    first_page_url: "",
    from: null,
    last_page: null,
    last_page_url: "",
    next_page_url: "",
    path: "",
    per_page: null,
    prev_page_url: null,
    to: null,
    total: null,
    isLoading: false
};

const reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case actionTypes.SET_WEBSITES:
            return {
                ...state,
                ...payload
            };

        case actionTypes.WEBSITES_LOADING:
            return {
                ...state,
                isLoading: payload
            };
        default:
            return {
                ...state
            };
    }
}
export default reducer;