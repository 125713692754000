import React, { useContext } from "react";
import Notifications from "./notifications-dropdown/NotificationsDropdown";
import Languages from "./languages-dropdown/LanguagesDropdown";
import User from "./user-dropdown/UserDropdown";
import { ThemeContext } from "../../../contexts/ThemeContext";

const Header = () => {
  const theme = useContext(ThemeContext);
  const { darkMode, toggleDarkMode, toggleMenuSidebar } = theme;
  return (
    <nav
      className={`main-header navbar navbar-expand navbar-${
        darkMode ? "dark" : "light"
      }`}
    >
      {/* Left navbar links */}
      <ul className="navbar-nav">
        <li className="nav-item">
          <button
            onClick={toggleMenuSidebar}
            type="button"
            className="nav-link"
            data-widget="pushmenu"
            href="#"
          >
            <i className="fas fa-bars" />
          </button>
        </li>
      </ul>
      <ul className="navbar-nav ml-auto">
        <li className="nav-item d-sm-inline-block dropdown">
          <div className="custom-control custom-switch nav-link">
            <input
              checked={darkMode}
              onChange={toggleDarkMode}
              type="checkbox"
              className="custom-control-input"
              id="customSwitch1"
            />
            <label className="custom-control-label" htmlFor="customSwitch1">
              <i className="far fa-moon"></i>
            </label>
          </div>
        </li>
        <Notifications />
        <Languages />
        <User />
        {/* <li className="nav-item">
          <button
            className="nav-link"
            data-widget="control-sidebar"
            data-slide="true"
            type="button"
          >
            <i className="fas fa-th-large" />
          </button>
        </li> */}
      </ul>
    </nav>
  );
};

export default Header;
