import Dashboard from "../Dashboard";
import Websites from "./websites";
import WebsitesReport from "./reports/websites";
import Referral from "./referral";
import Faq from "../common/Faq";
import Bonus from "../common/Bonus";
import Profile from "../profile/Profile";
import AddWebsite from "./websites/addWebsite";
import SingleWebsite from "./websites/single";
import AddZone from "./websites/zones/add";
import SingleReport from "./reports/websites/single";
import ZonesReport from "./reports/websites/zones";

const routes = [
  {
    path: "/user",
    name: "menusidebar.label.dashboard",
    icon: "fa-chart-line",
    roles: ["owner", "user"],
    permissions: "",
    component: Dashboard,
    inSidebar: true,
  },
  {
    path: "/user/websites",
    name: "menusidebar.label.websites",
    icon: "fa-globe",
    roles: ["owner", "user"],
    permissions: "",
    component: Websites,
    inSidebar: true,
  },
  {
    path: "/user/websites/reports",
    name: "menusidebar.label.reports",
    icon: "fa-chart-bar",
    roles: ["owner", "user"],
    permissions: "",
    component: WebsitesReport,
    inSidebar: true,
  },
  {
    path: "/user/referral",
    name: "menusidebar.label.referral",
    icon: "fa-comment-dollar",
    roles: ["owner", "user"],
    permissions: "",
    component: Referral,
    inSidebar: true,
  },
  {
    path: "/user/faq",
    name: "menusidebar.label.faq",
    icon: "fa-comment-dots",
    roles: ["owner", "user"],
    permissions: "",
    component: Faq,
    inSidebar: true,
  },
  {
    path: "/user/bonus",
    name: "Bonus",
    roles: ["owner", "user"],
    permissions: "",
    component: Bonus,
    inSidebar: false,
  },
  {
    path: "https://digitalcaramel.com/formats/",
    external: true,
    name: "menusidebar.label.formats",
    icon: "fa-newspaper",
    roles: ["owner", "user"],
    permissions: "",
    component: null,
    inSidebar: true,
    badge: "New",
  },
  {
    path: "/user/profile",
    name: "menusidebar.label.profile",
    icon: "fa-user",
    roles: ["owner", "user"],
    permissions: "",
    component: Profile,
    inSidebar: true,
  },
  {
    path: "/user/websites/new",
    name: "",
    icon: "",
    roles: ["owner", "user"],
    permissions: "",
    component: AddWebsite,
    inSidebar: false,
  },
  {
    path: "/user/websites/:id",
    name: "",
    icon: "",
    roles: ["owner", "user"],
    permissions: "",
    component: SingleWebsite,
    inSidebar: false,
  },
  {
    path: "/user/websites/:id/zones/add",
    name: "",
    icon: "",
    roles: ["owner", "user"],
    permissions: "",
    component: AddZone,
    inSidebar: false,
  },
  {
    path: "/user/websites/:id/reports",
    name: "",
    icon: "",
    roles: ["owner", "user"],
    permissions: "",
    component: SingleReport,
    inSidebar: false,
  },
  {
    path: "/user/websites/:id/zones/reports",
    name: "",
    icon: "",
    roles: ["owner", "user"],
    permissions: "",
    component: ZonesReport,
    inSidebar: false,
  },
];

export default routes;
