import { toast } from "react-toastify";
import i18n from "i18next";
import { http } from "../../../helpers";
import actionTypes from "../../action-types";

export const NotifyUsers = (messageEn, messageRu) => {
  return async (dispatch) => {
    try {
      const params = {
        messageEn,
        messageRu,
      };
      await http.post("/notifications/create", params);
      toast.success(`${i18n.t("notification.notified")}`);
    } catch (error) {
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const AdminGetBonuses = () => {
  return async (dispatch) => {
    dispatch({ type: actionTypes.SET_ADMIN_BONUS_LOADING, payload: true });
    try {
      const { data } = await http.get("/settings/bonus");
      dispatch({ type: actionTypes.SET_ADMIN_BONUS, payload: data.data });
      dispatch({ type: actionTypes.SET_ADMIN_BONUS_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: actionTypes.SET_ADMIN_BONUS_LOADING, payload: false });
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const AdminSetSettings = (fields) => {
  return async () => {
    try {
      const params = {
        bonuses: fields.bonuses,
        dateInterval: fields.dateInterval,
      };
      await http.post("/settings/create", params);
      toast.success(`${i18n.t("settings.submitted")}`);
    } catch (error) {
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};
