import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import LineChart from "../../../components/chart/LineChart";
import DateFilter from "../../../components/filter/dateFilter";
import NoData from "../../../components/noData";
import { impressionFormat, showAmountOfNumber } from "../../../helpers";
import echo from "../../../helpers/echo";
import {
  AdminZenBySourceAction,
  AdminZenBySourceGetAction,
} from "../../../store/actions/admin/zen";

const AdminZenBySource = ({
  getZenBySource,
  userId,
  darkMode,
  zenBySources,
  loadZenBySource,
}) => {
  const { t } = useTranslation();
  const [collapse, setCollapse] = useState(true);
  useEffect(() => {
    echo(localStorage.getItem("dc-token"))
      .private(`ZenBySources.Loaded.${userId}`)
      .listen("ZenBySourcesLoaded", ({ data }) => {
        const { start, end } = data;
        getZenBySource(start, end);
      });
    return () => {
      echo(localStorage.getItem("dc-token")).leave(`Zen.Loaded.${userId}`);
    };
  }, [getZenBySource, userId]);
  const { sourcesDataChart, range, totalSourceData } = zenBySources;
  return (
    <>
      <div className="container-fluid">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>{t("menusidebar.label.zenBySources")}</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/admin">{t("header.label.home")}</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    {t("menusidebar.label.zen")}
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <DateFilter handleApply={loadZenBySource} />
        {!sourcesDataChart.categories.length && <NoData />}
        {sourcesDataChart.categories.length > 0 && (
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <div className="d-flex justify-content-between">
                    <h3 className="card-title">
                      {t("menusidebar.label.zenBySources")}{" "}
                      {range.start ? (
                        <span>{`${range.start} - ${range.end}`}</span>
                      ) : null}
                    </h3>
                    <div className="collapse-icon-background">
                      <i
                        role="button"
                        className={
                          collapse ? "fas fa-arrow-up" : "fas fa-arrow-down"
                        }
                        onClick={() => setCollapse(!collapse)}
                      ></i>
                    </div>
                  </div>
                  <div className="total"></div>
                </div>
                {collapse && (
                  <div className="card-body px-4 mx-4">
                    <LineChart
                      series={sourcesDataChart.series}
                      categories={sourcesDataChart.categories}
                      darkMode={darkMode}
                    />
                  </div>
                )}
              </div>
            </div>
            {totalSourceData.length > 0 && (
              <div className="col-md-12">
                <div className="card">
                  <div className="card-header">
                    <h3 className="card-title">
                      {t("menusidebar.label.sources")}
                    </h3>
                  </div>
                  <div className="card-body table-responsive p-0">
                    <table className="table table-striped table-valign-middle">
                      <thead>
                        <tr>
                          <th>{t("sources.type")}</th>
                          <th>{t("sources.profitability")}</th>
                          <th>{t("sources.impressions")}</th>
                          <th>{t("sources.profit")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {totalSourceData
                          .sort(
                            (a, b) =>
                              b.sum / b.impressions - a.sum / a.impressions
                          )
                          .map((item, index) => {
                            return (
                              <tr key={index}>
                                <td>{item.name}</td>
                                <td>
                                  {showAmountOfNumber(
                                    (item.sum / item.impressions) * 100
                                  )}
                                  {" %"}
                                </td>
                                <td>{impressionFormat(item.impressions)}</td>
                                <td>{showAmountOfNumber(item.sum)}</td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    userId: state.auth.currentUser.id,
    zenBySources: state.adminZen.zenBySources,
    darkMode: state.theme.darkMode,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    loadZenBySource: (period) => dispatch(AdminZenBySourceAction(period)),
    getZenBySource: (startDate, endDate) =>
      dispatch(AdminZenBySourceGetAction(startDate, endDate)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminZenBySource);
