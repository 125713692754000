import actionTypes from "../../action-types/";

const initialState = {
  id: "",
  url: "",
  cname: "",
  bonus: false,
  show_zone_st: false,
  dc_website_id: null,
  comission: 0,
  description: null,
  mirrors: [],
  color: "",
  status: false,
  main_user_id: "",
  zones: [],
  users: [],
  user_ids: [],
  sources: [],
  source_ids: [],
  commission_history: [],
  attached_users: [],
  isLoading: false,
  filterShow: false,
};

const websiteReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_WEBSITE:
      return {
        ...state,
        ...payload,
      };
    case actionTypes.SET_WEBSITE_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    case actionTypes.SET_WEBSITE_ZONES:
      return {
        ...state,
        ...payload,
      };
    case actionTypes.UPDATE_ZONE_DETAILS:
      const { id, sources } = payload;
      const newZones = state.zones.map((zone) => {
        if (zone.id === id) {
          return {
            ...zone,
            details: {
              ...zone.details,
              ...sources,
            },
          };
        }
        return zone;
      });
      return {
        ...state,
        zones: newZones,
      };
    case actionTypes.WEBSITE_ZONE_DELETE:
      const zoneID = payload;
      let zones = [...state.zones];
      zones = zones.filter((zone) => {
        return zone.id !== zoneID;
      });
      return {
        ...state,
        zones,
      };
    default:
      return {
        ...state,
      };
  }
};
export default websiteReducer;
