import actionTypes from "../../action-types";

const initialState = {
    list: [],
    period: 'month',
    isLoading: false,
    filterShow: false,
    sum: 0
};

const referralReducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case actionTypes.REFERRAL_LOADING:
            return {
                ...state,
                isLoading: payload
            }
        case actionTypes.SET_REFERRALS:
            return {
                ...state,
                ...payload
            }
        case actionTypes.SET_REFERRAL_PERIOD:
            return {
                ...state,
                period: payload
            }
        default:
            return {
                ...state
            }
    }
}

export default referralReducer;
