import React, { useEffect } from "react";
import { connect } from "react-redux";
import { loginWithSocial } from "../../store/actions/auth/loginActions";
import { history } from "../../helpers";
import actionTypes from "../../store/action-types/index";

const SocialLogin = ({ onUserLogin, setToast }) => {
  useEffect(() => {
    let token_str = history.location.search;
    if (token_str.includes("?dc-token=")) {
      let token_with_origin = token_str.split("?dc-token=")[1];
      let token = token_with_origin.split("&origin=")[0];
      let origin = token_with_origin.split("&origin=")[1];
      onUserLogin(token, origin);
    }
    if (token_str.includes("?error=")) {
      let origin = token_str.split("&origin=")[0];
      setToast();
      history.push(`/${origin}`);
    }
  }, [onUserLogin, setToast]);
  return <div className="login-box"></div>;
};

const mapDispatchToProps = (dispatch) => ({
  setToast: () => {
    dispatch({
      type: actionTypes.SET_TOAST,
      text: "error.somethingWrong",
      toastType: "error",
    });
  },
  onUserLogin: (token, origin) => dispatch(loginWithSocial(token, origin)),
});
export default connect(null, mapDispatchToProps)(SocialLogin);
