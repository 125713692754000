import React from "react";
import styles from "./index.module.scss";

const Spinner = () => {
  return (
    <div className={styles["spinner-container"]}>
      <div className="spinner-grow" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </div>
  );
};

export default Spinner;
