import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import AppButton from "../button/Button";
import ReconfirmModal from "../modals/reconfirm";
import { AdminCurrencyRefresh } from "../../store/actions/admin/sources";
import AppDateRangePicker from "../daterangepicker";

const Currency = ({ isActive, refreshCurrency }) => {
  const { t } = useTranslation();
  const [startDate, setStartDate] = useState(null);

  const [reconfirmModal, setReconfirmModal] = useState({
    show: false,
    title: null,
    onHide: null,
    onSubmit: null,
    theme: "",
  });

  const handleCloseReconfirmModal = () => {
    setReconfirmModal({
      title: "",
      show: false,
      onSubmit: () => {},
      onHide: () => {},
    });
  };

  const handleUpdate = useCallback(
    (e) => {
      e.stopPropagation();
      setReconfirmModal({
        title: t("common.refresh"),
        show: true,
        onSubmit: () => {
          refreshCurrency(startDate);
          handleCloseReconfirmModal();
        },
        onHide: () => handleCloseReconfirmModal(),
        theme: "success",
      });
    },
    [startDate, refreshCurrency, t]
  );

  return (
    <div className={`tab-pane ${isActive ? "active" : ""}`}>
      <AppDateRangePicker
        className="col-12 col-md-3"
        handlePickerDate={setStartDate}
        singleDatePicker
      />
      <div className="row">
        <div className="col-xl-12">
          <div className="col-xl-6 col-12 pl-0">
            <AppButton
              onClick={(e) => handleUpdate(e)}
              type="btn"
              className="btn btn-primary"
            >
              {t("common.refresh")}
            </AppButton>
          </div>
        </div>
      </div>
      {reconfirmModal.show && (
        <ReconfirmModal
          title={reconfirmModal.title}
          show={reconfirmModal.show}
          onHide={reconfirmModal.onHide}
          onSubmit={reconfirmModal.onSubmit}
          theme={reconfirmModal.theme}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    refreshCurrency: (startDate) => dispatch(AdminCurrencyRefresh(startDate)),
  };
};

export default connect(null, mapDispatchToProps)(Currency);
