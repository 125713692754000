import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { GetManagerListAction } from "../../store/actions/admin/users";

const ManagerList = ({ getManagerList, onChange, ...props }) => {
  const { t } = useTranslation();
  const [managerList, setManagerList] = useState([]);
  useEffect(() => {
    getManagerList().then((res) => {
      if (res) {
        setManagerList(res.data ?? []);
      }
    });
    return () => {
      setManagerList([]);
    };
  }, [getManagerList]);
  return (
    <select
      {...props}
      onChange={(e) => {
        onChange(e.target.value);
      }}
    >
      <option value="">{t("common.selectManager")}</option>
      {managerList.map((item) => (
        <option key={item.id} value={item.id}>
          {item.fullname}
        </option>
      ))}
    </select>
  );
};

const mapDispatchToProps = (dispatch) => {
  return {
    getManagerList: () => dispatch(GetManagerListAction()),
  };
};
export default connect(null, mapDispatchToProps)(ManagerList);
