import React, { useState, useEffect, memo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Wheel } from "react-custom-roulette";
import { Button } from "react-bootstrap";
import {
  GetUserBonusAction,
  SetUserBonusAction,
} from "../../../store/actions/users";
import { connect } from "react-redux";
import { history } from "../../../helpers";
import PageLoading from "../../../components/page-loading/PageLoading";

const Bonus = ({
  getBonus,
  variants,
  userBonus,
  setUserBonus,
  userHasBonus,
}) => {
  const [t] = useTranslation();
  variants.forEach((element) => {
    element["option"] = t("bonus.variants-" + element.name + ".name");
  });
  const wheelData = variants;
  const [win, setWin] = useState(null);
  const [mustSpin, setMustSpin] = useState(false);
  const [bonusNumber, setBonusNumber] = useState(0);

  useEffect(() => {
    if (!userHasBonus) {
      history.push("/404");
    }
    getBonus();
  }, [userHasBonus, getBonus]);

  useEffect(() => {
    if (userBonus && userBonus.name) {
      setWin(t("bonus.variants-" + userBonus.name + ".win"));
      setMustSpin(false);
      wheelData.sort((a) => (a.name !== userBonus.name ? -1 : 1));
    }
  }, [t, userBonus, wheelData]);

  const handleSpinClick = () => {
    let newBonusNumber = Math.floor(Math.random() * wheelData.length);
    if (newBonusNumber === 3) {
      newBonusNumber = Math.floor(Math.random() * 2);
    }
    setBonusNumber(newBonusNumber);
    setMustSpin(true);
  };

  const handleWinBonus = () => {
    setMustSpin(false);
    setWin(t("bonus.variants-" + wheelData[bonusNumber].name + ".win"));
    setUserBonus(
      wheelData[bonusNumber].id,
      wheelData[bonusNumber].name === "5" || wheelData[bonusNumber].name === "6"
    );
  };

  return wheelData.length > 0 ? (
    <div className="container-fluid">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6 offset-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/user">{t("header.label.home")}</Link>
                </li>
                <li className="breadcrumb-item active">
                  {t("menusidebar.label.bonus")}
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-sm-8 col-lg-6">
            <div
              className="section_heading text-center wow fadeInUp"
              data-wow-delay="0.2s"
            >
              <h3>{t("bonus.title")}</h3>
              <div className="line mb-3"></div>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-sm-10 col-lg-10">
            <div align="center">
              <h3>{win ?? ""}</h3>
              {wheelData.length > 0 && (
                <Wheel
                  mustStartSpinning={mustSpin}
                  prizeNumber={bonusNumber}
                  data={wheelData}
                  outerBorderColor={["#fffffff"]}
                  outerBorderWidth={[1]}
                  innerBorderColor={["#9ee5f0"]}
                  radiusLineColor={["#ffffff"]}
                  radiusLineWidth={[1]}
                  textColors={["#ffffff"]}
                  fontSize={[23]}
                  perpendicularText={[true]}
                  backgroundColors={[
                    "#DC382E",
                    "#FFBA54",
                    "#49E0D5",
                    "#1D3163",
                  ]}
                  onStopSpinning={handleWinBonus}
                />
              )}
              <Button
                onClick={handleSpinClick}
                disabled={mustSpin || win}
                className="mr-2"
              >
                {t("bonus.spin")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <PageLoading />
  );
};
const mapStateToProps = (state) => ({
  userHasBonus: state.auth.currentUser.bonus,
  variants: state.userSingle.variants,
  userBonus: state.userSingle.bonus,
});
const mapDispatchToProps = (dispatch) => ({
  getBonus: () => dispatch(GetUserBonusAction()),
  setUserBonus: (bonus, userBonusPercent) =>
    dispatch(SetUserBonusAction(bonus, userBonusPercent)),
});
export default connect(mapStateToProps, mapDispatchToProps)(memo(Bonus));
