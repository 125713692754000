import actionTypes from "../../action-types";
const initialState = {
  isLoggedIn: !!localStorage.getItem("dc-token"),
  isActivated: false,
  isAuthLoading: false,
  isYandexAuthLoading: false,
  isVkAuthLoading: false,
  isFacebookAuthLoading: false,
  currentUser: null,
  message: {
    type: "",
    text: "",
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.LOGIN_USER:
      if (action.token) {
        localStorage.setItem("dc-token", action.token);
      }
      return {
        ...state,
        isLoggedIn: true,
        currentUser: action.currentUser,
      };
    case actionTypes.LOGOUT_USER:
      localStorage.removeItem("dc-token");
      return {
        ...state,
        isLoggedIn: false,
        currentUser: null,
      };
    case actionTypes.LOAD_USER:
      return {
        ...state,
        currentUser: action.currentUser,
      };

    case actionTypes.CHANGE_USER_PASSWORD_UPDATED:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          password_updated: [
            state.currentUser.password_updated,
            action.payload,
          ],
        },
      };
    case actionTypes.AUTH_LOADING:
      return {
        ...state,
        isAuthLoading: action.loading,
      };
    case actionTypes.AUTH_VK_LOADING:
      return {
        ...state,
        isVkAuthLoading: action.loading,
      };
    case actionTypes.AUTH_FB_LOADING:
      return {
        ...state,
        isFacebookAuthLoading: action.loading,
      };
    case actionTypes.AUTH_YANDEX_LOADING:
      return {
        ...state,
        isYandexAuthLoading: action.loading,
      };
    case actionTypes.USER_ACTIVATE:
      return {
        ...state,
        isActivated: action.activate,
      };
    case actionTypes.MARK_AS_NOTIFY:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          notifications: state.currentUser.notifications.map((notification) => {
            if (notification.id === action.payload || action.payload === null) {
              return {
                ...notification,
                read_at: new Date(),
              };
            }
            return notification;
          }),
        },
      };
    case actionTypes.SET_USER_NOTIFICATIONS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          notifications: [...state.currentUser.notifications, action.payload],
        },
      };
    case actionTypes.USER_BONUS:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          bonus: [state.currentUser.bonus, action.payload],
        },
      };
    case actionTypes.USER_BONUS_PERCENT:
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          userBonus: action.payload,
        },
      };
    default:
      return {
        ...state,
      };
  }
};

export default reducer;
