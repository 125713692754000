import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import {
  checkIsAdmin,
  countries,
  phoneRegExp,
  printFormError,
} from "../../helpers";
import { useFormik } from "formik";
import * as Yup from "yup";
import AppButton from "../button/Button";
import ManagerList from "../manager-list";

const PrimaryTab = ({ isActive, user, isLoading, updateUser, editable }) => {
  const [t] = useTranslation();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fullName: user.fullname ?? "",
      country: user.country ?? "",
      email: user.email ?? "",
      phone: user.phone ?? "",
      telegram: user.telegram ?? "",
      skype: user.skype ?? "",
      whatsapp: user.whatsapp ?? "",
      vk: user.vk ?? "",
      managerId: user.manager?.id ?? "",
      bitrix_id: user.bitrix_id ?? "",
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required(t("users.single.validation.required")),
      email: Yup.string()
        .email(t("users.single.validation.email"))
        .required(t("users.single.validation.required")),
      country: Yup.string()
        .nullable(true)
        .required(t("users.single.validation.required")),
      phone: Yup.string()
        .nullable(true)
        .matches(phoneRegExp, t("users.single.validation.phoneNumber")),
      telegram: Yup.string().nullable(true),
      skype: Yup.string().nullable(true),
      whatsapp: Yup.string().nullable(true),
      vk: Yup.string().nullable(true),
      managerId: Yup.string().nullable(true),
      bitrix_id: Yup.number().nullable(true),
    }),
    onSubmit: (values) => {
      updateUser(user.id, values);
    },
  });

  return (
    <div className={`tab-pane ${isActive ? "active" : ""}`}>
      <form className="horizontal" onSubmit={formik.handleSubmit}>
        <div className="row py-5">
          {editable && (
            <div className="col-xl-12">
              <div className="col-xl-6 pl-0 pb-5">
                <label htmlFor="fullName" className="labels">
                  {t("users.single.setManager")}
                </label>
                <ManagerList
                  {...formik.getFieldProps("managerId")}
                  className="custom-select"
                  onChange={(managerId) => {
                    formik.setFieldValue("managerId", managerId);
                  }}
                />

                {printFormError(formik, "managerId")}
              </div>
            </div>
          )}
          <div className="col-xl-6">
            <label htmlFor="fullName" className="labels">
              {t("users.single.fullName")}
            </label>
            <input
              id="fullName"
              type="text"
              className="form-control"
              placeholder={t("users.single.fullName")}
              {...formik.getFieldProps("fullName")}
            />
            {printFormError(formik, "fullName")}
          </div>
          <div className="col-xl-6">
            <label htmlFor="email" className="labels">
              {t("users.single.email")}
            </label>
            <input
              id="email"
              className="form-control"
              placeholder={t("users.single.email")}
              {...formik.getFieldProps("email")}
              disabled={!editable}
            />
            {printFormError(formik, "email")}
          </div>
          <div className="col-xl-6">
            <label htmlFor="country" className="labels">
              {t("users.single.country")}
            </label>
            <select
              id="country"
              className="custom-select"
              {...formik.getFieldProps("country")}
            >
              <option value=""></option>
              {countries.map((item) => {
                return (
                  <option key={item.name} value={item.name}>
                    {item.full_name}
                  </option>
                );
              })}
            </select>
            {printFormError(formik, "country")}
          </div>
          <div className="col-xl-6">
            <label htmlFor="phone" className="labels">
              {t("users.single.phone")}
            </label>
            <input
              id="phone"
              className="form-control"
              placeholder={t("users.single.phone")}
              {...formik.getFieldProps("phone")}
            />
            {printFormError(formik, "phone")}
          </div>
          <div className="col-xl-6">
            <label htmlFor="telegram" className="labels">
              {t("users.single.telegram")}
            </label>
            <input
              id="telegram"
              className="form-control"
              placeholder={t("users.single.telegram")}
              {...formik.getFieldProps("telegram")}
            />
            {printFormError(formik, "telegram")}
          </div>
          <div className="col-xl-6">
            <label htmlFor="skype" className="labels">
              {t("users.single.skype")}
            </label>
            <input
              id="skype"
              className="form-control"
              placeholder={t("users.single.skype")}
              {...formik.getFieldProps("skype")}
            />
            {printFormError(formik, "skype")}
          </div>
          <div className="col-xl-6">
            <label htmlFor="whatsapp" className="labels">
              {t("users.single.whatsapp")}
            </label>
            <input
              id="whatsapp"
              className="form-control"
              placeholder={t("users.single.whatsapp")}
              {...formik.getFieldProps("whatsapp")}
            />
            {printFormError(formik, "whatsapp")}
          </div>
          <div className="col-xl-6">
            <label htmlFor="vk" className="labels">
              {t("users.single.vk")}
            </label>
            <input
              id="vk"
              className="form-control"
              placeholder={t("users.single.vk")}
              {...formik.getFieldProps("vk")}
            />
            {printFormError(formik, "vk")}
          </div>
          {checkIsAdmin(user.role_id) && (
            <div className="col-xl-6">
              <label htmlFor="bitrix_id" className="labels">
                {t("users.single.bitrixId")}
              </label>
              <input
                id="bitrix_id"
                className="form-control"
                placeholder={t("users.single.bitrixId")}
                {...formik.getFieldProps("bitrix_id")}
              />
              {printFormError(formik, "bitrix_id")}
            </div>
          )}
        </div>
        <div className="mt-5 text-right">
          <AppButton
            isLoading={isLoading}
            className="btn btn-primary profile-button"
            type="submit"
          >
            {t("users.single.save")}
          </AppButton>
        </div>
      </form>
    </div>
  );
};

export default memo(PrimaryTab);
