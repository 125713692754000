import actionTypes from "../../action-types";

const initialState = {
  yesterdaySum: 0,
  monthEarnings: 0,
  monthAverage: 0,
  yesterdayWebsitesCount: 0,
  topWebsites: [],
  isLoading: false,
};

const adminDashboardReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.SET_ADMIN_DASHBOARD:
      return {
        ...state,
        ...payload,
      };
    case actionTypes.ADMIN_DASHBOARD_LOADING:
      return {
        ...state,
        isLoading: payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default adminDashboardReducer;
