import React, { useContext, useState } from "react";
import DateRangePicker from "react-bootstrap-daterangepicker";
import moment from "moment";
import { filterList } from "../../helpers";
import AppButton from "../button/Button";
import { ThemeContext } from "../../contexts/ThemeContext";
import styles from "./index.module.scss";
import { useTranslation } from "react-i18next";

const Filter = ({ children, isLoading, period, setPeriod, handleApply }) => {
  const [filterShow, setFilterShow] = useState(true);
  const { t } = useTranslation();
  const theme = useContext(ThemeContext);
  const { width } = theme;
  let mobile = Math.floor(width) < 528;
  let caret = Math.floor(width) < 1168 ? "caretDown" : "caretRight";

  return (
    <div className={`website-list-filter`}>
      <div className="row">
        <div
          className={`col-12 d-flex ${
            mobile ? "direction-column" : "direction-row"
          }`}
        >
          <AppButton
            icon={filterShow ? caret : "filter"}
            theme="default"
            className={styles.filterButton}
            onClick={() => setFilterShow(!filterShow)}
          >
            {t("websites.button.filter")}
          </AppButton>
          {filterShow && (
            <ul
              className={`other-date-options list-unstyled d-flex mb-0 direction-`}
            >
              {filterList.map((item) => {
                return (
                  <li key={item.value}>
                    <div className="radio">
                      <input
                        type="radio"
                        name="range"
                        id={item.value}
                        value={item.value}
                        checked={period === item.value}
                        disabled={isLoading}
                        onChange={() => setPeriod(item.value)}
                      />
                      <label htmlFor={item.value}>{t(item.text)}</label>
                    </div>
                  </li>
                );
              })}
              <li>
                <DateRangePicker
                  onApply={handleApply}
                  initialSettings={{
                    alwaysShowCalendars: true,
                    maxDate: moment().toDate(),
                    locale: {
                      format: "YYYY-MM-DD",
                    },
                  }}
                >
                  <div id="daterange" className={`${styles.selectbox}`}>
                    <i className="far fa-calendar-alt mr-1"></i>
                    <span>{t("ranges.selectRange")}</span>{" "}
                    <b className="caret"></b>
                  </div>
                </DateRangePicker>
              </li>
            </ul>
          )}
          {children}
        </div>
      </div>
    </div>
  );
};

export default Filter;
