export const accounts = [
  {
    id: 8,
    name: "Digital Caramel 2",
    script: `
    var adfoxBiddersMap = {
        "betweenDigital": "957893",
        "otm": "1536131",
        "getintent": "1048899",
        "mytarget": "952961",
        "bidvol": "1445725",
        "buzzoola": "957129",
        "adriver": "930088",
        "sape": "1672697",
        "hybrid": "1879759",
        "kadam": "1993146",
        "relap": "928689",
        "alfasense":"1056746",
        "solta": "2482258"
    };
    
    var adUnits = [
        {
            "code": "",
            "bids": [
                {
                    "bidder": "betweenDigital",
                    "params": {
                        "placementId": ""
                    }
                },
                {
                    "bidder": "otm",
                    "params": {
                        "placementId": ""
                    }
                },
                {
                    "bidder": "getintent",
                    "params": {
                        "placementId": "66_240x400_1_alfadart"
                    }
                },
                {
                    "bidder": "mytarget",
                    "params": {
                        "placementId": ""
                    }
                },
                {
                    "bidder": "bidvol",
                    "params": {
                        "placementId": ""
                    }
                },
                {
                    "bidder": "sape",
                    "params": {
                        "placementId": ""
                    }
                },
                {
                    "bidder": "buzzoola",
                    "params": {
                        "placementId": ""
                    }
                },
                {
                    "bidder": "hybrid",
                    "params": {
                        "placementId": ""
                    }
                },
                {
                    "bidder": "kadam",
                    "params": {
                        "placementId": ""
                    }
                },
                {
                    "bidder": "adriver",
                    "params": {
                        "placementId": "57:"
                    }
                },
                {
                    "bidder": "solta",
                    "params": {
                        "placementId": ""
                    }
                },
                {
                    "bidder": "alfasense",
                    "params": {
                        "placementId": ""
                    }
                }
            ],
            "sizes": [
                [
                    240,
                    400
                ]
            ]
        }
    ];
    var userTimeout = 1500;
    window.YaHeaderBiddingSettings = {
        biddersMap: adfoxBiddersMap,
        adUnits: adUnits,
        timeout: userTimeout
    };
    `,
  },
  {
    id: 38,
    name: "Monetize",
    script: `
    var adfoxBiddersMap = {
        "betweenDigital": "1366074",
        "otm": "1536143",
        "getintent": "1366078",
        "mytarget": "1366072",
        "bidvol": "1445727",
        "buzzoola": "1394119",
        "adriver": "1366076",
        "sape": "1672699",
        "hybrid": "1879763",
        "kadam": "1998609",
        "relap": "1671199",
        "alfasense":"1366075",
        "solta": "2468261"
    };
    var adUnits = [
        {
            "code": "",
            "bids": [
                {
                    "bidder": "betweenDigital",
                    "params": {
                        "placementId": ""
                    }
                },
                {
                    "bidder": "otm",
                    "params": {
                        "placementId": ""
                    }
                },
                {
                    "bidder": "getintent",
                    "params": {
                        "placementId": "66_240x400_1_alfadart"
                    }
                },
                {
                    "bidder": "mytarget",
                    "params": {
                        "placementId": ""
                    }
                },
                {
                    "bidder": "bidvol",
                    "params": {
                        "placementId": ""
                    }
                },
                    {
                    "bidder": "sape",
                    "params": {
                        "placementId": ""
                    }
                },
                {
                    "bidder": "buzzoola",
                    "params": {
                        "placementId": ""
                    }
                },
                {
                    "bidder": "hybrid",
                    "params": {
                        "placementId": ""
                    }
                },
                {
                    "bidder": "kadam",
                    "params": {
                        "placementId": ""
                    }
                },
                {
                    "bidder": "adriver",
                    "params": {
                        "placementId": "57:"
                    }
                },
                {
                    "bidder": "solta",
                    "params": {
                        "placementId": ""
                    }
                },
                {
                    "bidder": "alfasense",
                    "params": {
                        "placementId": ""
                    }
                }
            ],
            "sizes": [
                [
                    240,
                    400
                ]
            ]
        }
    ];
    var userTimeout = 1500;
    window.YaHeaderBiddingSettings = {
        biddersMap: adfoxBiddersMap,
        adUnits: adUnits,
        timeout: userTimeout
    };
    `,
  },
];
