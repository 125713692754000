import React from "react";

const DateRangeCard = ({ title, percentage, value, icon }) => {
  return (
    <div className="col-xl-3 col-md-6">
      <div className="card card-animate">
        <div className="card-body">
          <div className="d-flex align-items-center">
            <div className="flex-grow-1 overflow-hidden">
              <p className="text-uppercase fw-medium text-muted text-truncate mb-0">
                {title}
              </p>
            </div>
            <div className="flex-shrink-0">
              {/* <select className="b-none">
                <option value="1">Monthly</option>
                <option value="2">2</option>
                <option value="3">3</option>
              </select> 
              {percentage && (
                <h5
                  className={`${
                    Number(percentage) > 0 ? "text-success" : "text-danger"
                  }  fs-14 mb-0`}
                >
                  <i className="ri-arrow-right-up-line fs-13 align-middle"></i>
                  {`${Number(percentage) > 0 ? "+" : "-"} ${Math.abs(
                    percentage
                  )}%`}
                </h5>
              )} */}
            </div>
          </div>
          <div className="d-flex align-items-end justify-content-between mt-4">
            <div>
              <h4 className="fs-22 fw-semibold ff-secondary mb-4">
                <span className="counter-value">{value}</span>
              </h4>
            </div>
            <div className="avatar-sm flex-shrink-0">
              <span className="avatar-title bg-soft-success rounded fs-25">
                <i className={`${icon} text-success`}></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DateRangeCard;
