export const sumArrays = (...arrays) => {
  const n = arrays.reduce((max, xs) => Math.max(max, xs.length), 0);
  const result = Array.from({ length: n });
  return result.map((_, i) =>
    arrays.map((xs) => xs[i] || 0).reduce((sum, x) => sum + x, 0)
  );
};

export const cpmArrays = (...arrays) => {
  const n = arrays.reduce((max, xs) => Math.max(max, xs.length), 0);
  const result = Array.from({ length: n });
  return result.map((_, i) =>
    arrays.map((xs) => xs[i]).reduce((sum, x) => (x ? (sum / x) * 1000 : 0))
  );
};

export const showAmountOfNumber = (number) => {
  if (typeof number !== "number") {
    return "N/A";
  }
  return number.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
export const selectText = (containerid, copy = false, fncCopied) => {
  if (document.selection) {
    // IE
    let range = document.body.createTextRange();
    range.moveToElementText(document.getElementById(containerid));
    range.select();
  } else if (window.getSelection) {
    let range = document.createRange();
    range.selectNode(document.getElementById(containerid));
    window.getSelection().removeAllRanges();
    window.getSelection().addRange(range);
  }
  if (copy) {
    document.execCommand("copy", true);
    fncCopied();
  }
};

export const appSearch = (items, q, property) => {
  return items.filter((item) => {
    if (q) {
      return (
        item[property]?.toString().toLowerCase().indexOf(q.toLowerCase()) > -1
      );
    } else {
      return item;
    }
  });
};

export const printFormError = (formik, key) => {
  if (formik.touched[key] && formik.errors[key]) {
    return <div className="text-danger">{formik.errors[key]}</div>;
  }
  return null;
};

export const phoneRegExp = /^\+?\d{7,16}$/;
export const wmrRegExp = /^\d{12}$/; // /^R\d{12}$/;
export const wmzRegExp = /^Z\d{12}$/;
export const bitcoinRegExp =
  /^[13][a-km-zA-HJ-NP-Z1-9]{25,34}$|^[(bc1q)|(bc1p)][0-9A-Za-z]{37,62}$/;
export const cardRegExp = {
  visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
  mastercard: /^5[1-5][0-9]{14}$/,
  electron: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
  maestro: /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
  dankort: /^(5019)\d+$/,
  interpayment: /^(636)\d+$/,
  unionpay: /^(62|88)\d+$/,
  amex: /^3[47][0-9]{13}$/,
  diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
  discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
  jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
};

export const validateCreditCardNumber = (cardNumber) => {
  cardNumber = cardNumber.split(" ").join("");
  if (
    parseInt(cardNumber) <= 0 ||
    !/\d{15,16}(~\W[a-zA-Z])*$/.test(cardNumber) ||
    cardNumber.length > 16
  ) {
    return false;
  }
  let carray = [];
  for (let i = 0; i < cardNumber.length; i++) {
    carray[carray.length] = cardNumber.charCodeAt(i) - 48;
  }
  carray.reverse();
  let sum = 0;
  for (let i = 0; i < carray.length; i++) {
    let tmp = carray[i];
    if (i % 2 !== 0) {
      tmp *= 2;
      if (tmp > 9) {
        tmp -= 9;
      }
    }
    sum += tmp;
  }
  return sum % 10 === 0;
};

export const validateYandexWallet = (value) => {
  let minLength = 11;
  let maxLength = 26;

  if (value === undefined || value.length === 0) {
    return true;
  }

  let length = value.length,
    N = value.charAt(0).toString(),
    X = value.substr(1, N),
    Z = value.substr(length - 2, 2).toString(),
    Y = value.substr((N + X).length, length - (N + X + Z).length);

  let xArray = X.split("").reverse();
  let yArray = Y.split("").reverse();
  let result = 0;
  let a = 70;
  for (let index = 0; index < 20; index++) {
    let yValue = yArray[index];
    let tValue =
      yValue === undefined || yValue === 0 || yValue === "0" ? 10 : yValue;
    result = (result + ((tValue * a) % 99)) % 99;
    a = (13 * a) % 99;
  }

  for (let index = 0; index < 10; index++) {
    let xValue = xArray[index];
    let tValue =
      xValue === undefined || xValue === 0 || xValue === "0" ? 10 : xValue;
    result = (result + ((tValue * a) % 99)) % 99;
    a = (13 * a) % 99;
  }

  result += 1;

  result = result < 10 ? "0" + result : result;
  if (
    length < minLength ||
    length > maxLength ||
    N === "0" ||
    Z === "00" ||
    result.toString() !== Z ||
    Y.length > 20
  ) {
    return false;
  }

  return true;
};
export const cardType = (cardNumber) => {
  // returns card type; should not rely on this for checking if a card is valid
  cardNumber = cardNumber.split(" ").join("");
  let o = {
    electron: /^(4026|417500|4405|4508|4844|4913|4917)\d+$/,
    maestro:
      /^(5018|5020|5038|5612|5893|6304|6759|6761|6762|6763|0604|6390)\d+$/,
    dankort: /^(5019)\d+$/,
    interpayment: /^(636)\d+$/,
    unionpay: /^(62|88)\d+$/,
    visa: /^4[0-9]{12}(?:[0-9]{3})?$/,
    mastercard: /^5[1-5][0-9]{14}$/,
    amex: /^3[47][0-9]{13}$/,
    diners: /^3(?:0[0-5]|[68][0-9])[0-9]{11}$/,
    discover: /^6(?:011|5[0-9]{2})[0-9]{12}$/,
    jcb: /^(?:2131|1800|35\d{3})\d{11}$/,
  };
  for (let k in o) {
    if (o[k].test(cardNumber)) {
      return k;
    }
  }
  return null;
};

export const checkpaymentType = (user) => {
  let res = "";
  if (user.entity && user.entity === "physical") {
    if (user.webmoney_wmr_wallet_number) {
      res = "wmr";
    } else if (user.webmoney_wmz_wallet_number) {
      res = "wmz";
    } else if (user.ruble_card_number) {
      res = "card";
    } else if (user.yandex_wallet_number) {
      res = "yandex";
    }
  }
  return res;
};

export const checkIsAdmin = (roleId) => {
  return roleId < 3;
};

export const cssLink = (id, comment) => {
  return `${comment ? "\n/*start-css loader*/\n" : ""}
    const headID = document.getElementsByTagName('head')[0];
    const link = document.createElement('link');
    link.type = 'text/css';
    link.rel = 'stylesheet';
    link.href = 'https://ads.digitalcaramel.com/css/${id}.css';
    headID.appendChild(link);
    ${comment ? "\n/*end-css loader*/" : ""}`;
};

export const validateDomain = (domain) => {
  const domainRules = [patterns.domain, patterns.tMeDomain, patterns.gameName];
  return domainRules.some((regex) => regex.test(domain));
};

export const generateAutoUpdateScript = (timer, ids, comment) => {
  const updateScripts = `window.addEventListener('DOMContentLoaded', () => {
        const dcAds = [${ids}];
        dcAds.forEach((elem) => {
            const dcAdElem = document.getElementById(elem);
            if(dcAdElem) {
                dcAdElem.mouseIsOver = false;
                dcAdElem.onmouseover = function()   {
                    this.mouseIsOver = true;
                };
                dcAdElem.onmouseout = function()   {
                    this.mouseIsOver = false;
                }
                setInterval(function(){
                    if (
                        document.visibilityState=='visible' && 
                        dcIsInViewport(dcAdElem) && 
                        !dcAdElem.mouseIsOver
                    ) {
                    window.Ya.adfoxCode.reload(elem);
                    console.info('show is id', elem);
                    }       
                }, ${timer * 1000});
            }
        });
        const dcIsInViewport = function(e){
            const elementTop=e.getBoundingClientRect().top + window.scrollY;
            const elementBottom = elementTop + e.scrollHeight;
            const viewportTop = window.scrollY;
            const viewportBottom = viewportTop + window.innerHeight;
            return elementBottom > viewportTop && elementTop < viewportBottom
        }    
    });`;

  return `
    ${comment ? "\n/*start Ad refresh*/\n" : ""}
    ${updateScripts}
    ${comment ? "\n/*end Ad refresh*/" : ""}`;
};

export const firstCharToUpperCaseAndReplaceUnderscore = (str) => {
  return str
    .split("_")
    .map((elem) => elem.charAt(0).toUpperCase() + elem.slice(1))
    .join(" ");
};

export const fuzzyTime = (sec, lang = "en") => {
  const time = {
    en: {
      second: "second",
      seconds: "seconds",
      minute: "minute",
      minutes: "minutes",
      hour: "hour",
      hours: "hours",
      day: "day",
      days: "days",
      week: "week",
      weeks: "weeks",
      month: "month",
      months: "months",
      year: "year",
      years: "years",
      now: "now",
      ago: "ago",
    },
    ru: {
      second: "сек.",
      seconds: "секунды",
      minute: "мин.",
      minutes: "минуты",
      hour: "ч.",
      hours: "часа",
      day: "д.",
      days: "дня",
      week: "нед.",
      weeks: "недели",
      month: "мес.",
      months: "месяца",
      year: "г.",
      years: "года",
      now: "только что",
      ago: "назад",
    },
  };

  let res = "";

  const t_second = 1;
  const t_minute = t_second * 60;
  const t_hour = t_minute * 60;
  const t_day = t_hour * 24;
  const t_week = t_day * 7;
  const t_month = Math.floor(t_day * 30.4);
  const t_year = t_month * 12;

  const now = Math.floor(Date.now() / 1000);
  const dif = now - sec;

  const fuzzyString = (timeRef, timeStr) => {
    const fuzzy = Math.floor(dif / timeRef);
    if (lang === "en") {
      res += `${fuzzy} ${timeStr}${fuzzy > 1 ? "s" : ""}`;
    } else {
      res += `${fuzzy} ${timeStr}`;
    }
    res += ` ${time[lang]["ago"]}`; //назад // TODO translate i18nnext
  };

  if (dif >= t_year)
    fuzzyString(t_year, time[lang]["year"]); // год - года // 4+ лет // г.
  else if (dif >= t_month)
    fuzzyString(t_month, time[lang]["month"]); //месяц - ев // мес.
  else if (dif >= t_week)
    fuzzyString(t_week, time[lang]["week"]); // неделя - недель // нед.
  else if (dif >= t_day)
    fuzzyString(t_day, time[lang]["day"]); // день - дни // д.
  else if (dif >= t_hour)
    fuzzyString(t_hour, time[lang]["hour"]); // час - часов // ч.
  else if (dif >= t_minute)
    fuzzyString(t_minute, time[lang]["minute"]); // минута - минут // мин.
  else if (dif >= t_second)
    fuzzyString(t_second, time[lang]["second"]); // секунда - секунд // сек.
  else res = time[lang]["now"];

  return res;
};

// export const validateEmail = (email) => {
//   const re =
//     /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
//   return re.test(String(email).toLowerCase());
// };

export const getUserPath = (userRoleId, url = "") => {
  const roleUrl = userRoleId === 1 || userRoleId === 2 ? "/admin" : "/user";
  if (url?.includes(roleUrl)) return url;
  else return `${roleUrl}/${url}`;
};

export const impressionFormat = (impression = 0, local = "ru") => {
  return new Intl.NumberFormat(local).format(impression);
};

export const isFirstDayOfMonth = (date = new Date()) => {
  const firstDayCurrentMonth = new Date();
  firstDayCurrentMonth.setDate(1);

  return firstDayCurrentMonth.toDateString() === date.toDateString();
};

export const calculateCPM = (impression, sum) => {
  return showAmountOfNumber(impression > 0 ? (sum / impression) * 1000 : 0);
};

export const isMaintanence = () => {
  if (
    process.env.REACT_APP_SITE_MODE === "MAINTENANCE" &&
    localStorage.getItem("dc-dev") !== "yes"
  ) {
    return true;
  }

  return false;
};

export const createHash = function (string) {
  let hash = 0;
  for (let i = 0; i < string.length; i++) {
    hash = (hash << 5) - hash + string.charCodeAt(i);
    hash |= 0;
  }
  return hash;
};

export const isDate = function (date, single = true, operator = " - ") {
  if (!date) return false;
  return single
    ? !isNaN(Date.parse(date))
    : !isNaN(
        Date.parse(date.split(operator) ? date.split(operator)[0] : NaN)
      ) &&
        !isNaN(
          Date.parse(date.split(operator) ? date.split(operator)[1] : NaN)
        );
};

export const StrengthChecker = function (password) {
  const strongPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*\W).{8,}$/;
  const mediumPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*(\d|\W)).{8,}$/;

  let level = "weak";
  if (strongPassword.test(password)) {
    level = "strong";
  } else if (mediumPassword.test(password)) {
    level = "medium";
  } else {
    level = "weak";
  }
  return level;
};

const patterns = {
  domain:
    /^(?!:\/\/)(([а-яА-Я0-9-_]+\.)*[а-яА-Я0-9][а-яА-Я0-9-_]+\.[а-яА-Я]{2,11}?|([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?)$/,
  tMeDomain: /^t\.me\/[a-zA-Z0-9-_]+$/,
  gameName: /^[\wа-яА-Я\s=,*/\-_:]+$/,
};

const isDomainNameContains = function (domain) {
  return (
    domain.startsWith("http://") ||
    domain.startsWith("https://") ||
    domain.startsWith("www.") ||
    domain.endsWith("/")
  );
};

export const isValidDomainName = function (value) {
  switch (true) {
    case !value || value.trim() === "":
      return false;
    case isDomainNameContains(value):
      return false;
    case value.includes("t.me"):
      return patterns.tMeDomain.test(value);
    case value.includes("."):
      return patterns.domain.test(value);
    default:
      return patterns.gameName.test(value);
  }
};

export const generateRandomString = function () {
  const specials = "!@#$%^&*()_+{}:\"<>?|[];',./`~";
  const lowercase = "abcdefghijklmnopqrstuvwxyz";
  const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  const numbers = "0123456789";
  const all = specials + lowercase + uppercase + numbers;

  const pickChars = (str, num) => {
    let chars = "";
    for (let i = 0; i < num; i++) {
      chars += str.charAt(Math.floor(Math.random() * str.length));
    }
    return chars;
  };

  const shuffleString = (str) => {
    const array = str.split("");
    let currentIndex = array.length;
    let temporaryValue, randomIndex;

    while (currentIndex !== 0) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex--;

      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array.join("");
  };

  const maxLength = Math.floor(Math.random() * (15 - 8 + 1)) + 8;
  let generatedPassword =
    pickChars(specials, 1) +
    pickChars(lowercase, 1) +
    pickChars(uppercase, 1) +
    pickChars(numbers, 1) +
    pickChars(all, maxLength - 4);

  if (generatedPassword.length > maxLength) {
    generatedPassword = generatedPassword.slice(0, maxLength);
  }

  return shuffleString(generatedPassword);
};
