import NotFound from "../404";

const routes = [
  {
    path: "/404",
    name: "",
    icon: "fa-user",
    roles: ["admin", "manager", "user", "owner"],
    permissions: "",
    inSidebar: false,
    component: NotFound,
  },
];

export default routes;
