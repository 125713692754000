import * as Yup from "yup";
import { isValidDomainName } from "./functions";

Yup.addMethod(Yup.string, "domain", function test(name, message) {
  return this.test({
    name,
    message,
    test: (value) => isValidDomainName(value),
  });
});

export default Yup;
