import React, { memo } from "react";
import ReactApexChart from "react-apexcharts";
import styles from "./index.module.css";
const ApexChartRadialBar = (props) => {
  const { title, series, colors, labels, darkMode } = props;
  const settings = {
    series: series,
    options: {
      chart: {
        height: 390,
        type: "radialBar",
      },
      title: {
        text: title,
        offsetX: 5,
        offsetY: 5,
        fontSize: "25px",
        style: {
          fontSize: "14px",
        },
      },
      theme: {
        mode: darkMode ? "dark" : "light",
        palette: "palette1",
        monochrome: {
          enabled: false,
          color: "#255aee",
          shadeTo: "light",
          shadeIntensity: 0.65,
        },
      },
      plotOptions: {
        radialBar: {
          offsetY: -15,
          offsetX: 100,
          startAngle: 0,
          endAngle: 360,
          hollow: {
            margin: 5,
            size: "10%",
            background: "transparent",
            image: undefined,
          },
          dataLabels: {
            name: {
              show: false,
              color: "#111",
            },
            value: {
              show: false,
              formatter: function (val) {
                if (Number(val) === val && val % 1 !== 0) {
                  return val.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
                }
                return val.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
              },
            },
          },
        },
      },
      colors: colors,
      labels: labels,
      legend: {
        show: true,
        floating: true,
        fontSize: "16px",
        position: "left",
        //fontFamily: 'Source Sans Pro",-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol',
        offsetX: 0,
        offsetY: 15,
        labels: {
          useSeriesColors: false,
        },
        markers: {
          size: 0,
        },
        formatter: function (seriesName, opts) {
          let n = opts.w.globals.series[opts.seriesIndex];
          if (Number(n) === n && n % 1 !== 0) {
            return (
              seriesName +
              ":  " +
              n.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            );
          }
          return (
            seriesName +
            ":  " +
            n.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
          );
        },
        itemMargin: {
          vertical: 5,
        },
      },
      grid: {
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
        },
      },
      responsive: [
        {
          breakpoint: 1200,
          options: {
            legend: {
              show: true,
            },

            plotOptions: {
              radialBar: {
                show: false,
              },
            },
          },
        },
        {
          breakpoint: 1600,
          options: {
            grid: {
              padding: {
                top: 0,
                right: 15,
                bottom: 0,
                left: 15,
              },
            },
          },
        },
      ],
    },
  };
  return (
    <div className="col-md-6 col-12 col-xs-12 col-xl-3 col-lg-6 mb-2">
      <div className={styles.box}>
        <div id="chart-spark1">
          <ReactApexChart
            options={settings.options}
            series={settings.series}
            type="radialBar"
            height={173}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(ApexChartRadialBar);
