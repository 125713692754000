import React from "react";
const Image = ({ name, ...otherProps }) => {
  try {
    // Import image on demand
    const image = require(`../../assets/img/sources/${name}`);

    // If the image doesn't exist. return null
    if (!image) return null;
    return <img {...otherProps} src={image.default} alt={name} />;
  } catch (error) {
    console.error(`Image with name "${name}" does not exist`);
    return null;
  }
};

export default Image;
