import actionTypes from '../../action-types/';
import {http} from '../../../helpers';
import Cookies from 'js-cookie';
import i18n from 'i18next';
import { toast } from 'react-toastify';
const confirmEmail = (token) => (dispatch) => {
  dispatch({ type: actionTypes.AUTH_LOADING, loading: true });
  http
    .post("/confirm/resend", {
      confirmToken: token
    })
    .then(response => {
      dispatch({ type: actionTypes.AUTH_LOADING, loading: false });
      if (response.data.token) {
        Cookies.set('confirm-token', response.data.token, { expires: 1 });
        toast.success(`${i18n.t('confirm.reconfirm-text')}`);
      }
    })
    .catch(err => {
      toast.error(`${i18n.t('confirm.error.incorrect-token')}`);
      dispatch({ type: actionTypes.AUTH_LOADING, loading: false });
    })

}

export default confirmEmail;