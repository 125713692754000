import { toast } from "react-toastify";
import { http } from "../../../helpers";
import i18n from "i18next";
import actionTypes from "../../action-types";

export const ExportXlsxAction = (exportType, date) => {
  let url;
  switch (exportType) {
    case "referral":
      url = "export/referral";
      break;
    case "sources":
      url = "export/sources";
      break;
    case "activeUsers":
      url = "export/active-users";
      break;
    case "user":
      url = "export/user";
      break;
    case "pendingZones":
      url = "export/pending-zones";
      break;
    case "websiteSources":
      url = "export/website-sources";
      break;
    case "userAnswers":
      url = "export/user-answers";
      break;
    case "legalUsers":
      url = "export/legal-users";
      break;
    case "legalUsersEarnings":
      url = "export/legal-users-earnings";
      break;
    case "zones":
      url = "export/zones";
      break;
    case "yandexReferred":
      url = "export/yandex-referred";
      break;
    case "netTotal":
      url = "export/websites-net-total";
      break;
    case "agencyNetTotal":
      url = "export/agency-net-total";
      break;
    default:
      url = "export/agency";
  }
  return async (dispatch) => {
    try {
      const params = {
        isAgency: exportType === "agency",
        period: date,
      };
      await http.post(`/reports/${url}`, params);
      toast.success(`${i18n.t("common.requestSendWaitNotify")}`, {
        autoClose: 5000,
      });
    } catch (error) {
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const ExportWebsiteXlsxAction = (id, date) => {
  return async () => {
    try {
      const params = {
        id,
        period: date,
      };
      await http.post(`/reports/export/website-sources`, params);
      toast.success(`${i18n.t("common.requestSendWaitNotify")}`, {
        autoClose: 5000,
      });
    } catch (error) {
      toast.error(`${i18n.t("error.somethingWrong")}`);
    }
  };
};

export const SetExportLink = (payload) => {
  return {
    type: actionTypes.SET_EXPORT_LINK,
    payload,
  };
};
