import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import Button from "../../components/button/Button";
import logo from "../../assets/img/login-logo.png";
import recoverPassworAction from "../../store/actions/auth/recoverPasswordAction";
import { printFormError } from "../../helpers";
const RecoverPassword = (props) => {
  const { token } = props.match.params;
  const { isAuthLoading, message, recoverPass } = props;
  const [t] = useTranslation();
  useEffect(() => {
    message.type && toast[message.type](`${t(message.text)}`);
  }, [message, t]);
  const formik = useFormik({
    initialValues: {
      password: "",
      passwordRetype: "",
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(8, t("register.validation.passMin"))
        .max(30, t("register.validation.passMax"))
        .required(t("register.validation.required")),
      passwordRetype: Yup.string()
        .min(8, t("register.validation.passMin"))
        .max(30, t("register.validation.passMax"))
        .required(t("register.validation.required"))
        .when("password", {
          is: (val) => !!(val && val.length > 0),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            t("register.validation.passRetype")
          ),
        }),
    }),
    onSubmit: (values) => {
      recoverPass(token, values.password);
      formik.resetForm();
    },
  });
  document.getElementById("root").classList = "hold-transition login-page";

  return (
    <div className="login-box">
      <div className="card card-outline card-primary">
        <div className="card-header text-center">
          <Link to="/" className="h1">
            <img src={logo} alt="Logo" className="w-100" />
          </Link>
        </div>
        <div className="card-body">
          <p className="login-box-msg">{t("recover.oneStepAway")}</p>
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-3">
              <div className="input-group">
                <input
                  type="password"
                  className="form-control"
                  placeholder={t("register.placeholder.password")}
                  {...formik.getFieldProps("password")}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock" />
                  </div>
                </div>
              </div>
              {printFormError(formik, "password")}
            </div>
            <div className="mb-3">
              <div className="input-group">
                <input
                  type="password"
                  className="form-control"
                  placeholder={t("register.placeholder.passwordRetype")}
                  {...formik.getFieldProps("passwordRetype")}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-lock" />
                  </div>
                </div>
              </div>
              {printFormError(formik, "passwordRetype")}
            </div>
            <div className="row">
              <div className="col-12">
                <Button block type="submit" isLoading={isAuthLoading}>
                  {t("recover.changePassword")}
                </Button>
              </div>
            </div>
          </form>
          <p className="mt-3 mb-1">
            <Link to="/login">{t("login.button.signIn.label")}</Link>
          </p>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  const { isAuthLoading, message } = state.auth;
  return {
    isAuthLoading,
    message,
  };
};
const mapDispatchToProps = (dispatch) => ({
  recoverPass: (token, pass) => dispatch(recoverPassworAction(token, pass)),
});
export default connect(mapStateToProps, mapDispatchToProps)(RecoverPassword);
