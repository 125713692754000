import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import forgotPass from "../../store/actions/auth/forgotPasswordAction";
import Button from "../../components/button/Button";
import logo from "../../assets/img/login-logo.png";
import { printFormError } from "../../helpers";
const ForgotPassword = (props) => {
  const { isAuthLoading, forgotPass } = props;
  const [t] = useTranslation();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email(t("login.validation.email"))
        .required(t("login.validation.required")),
    }),
    onSubmit: (values) => {
      forgotPass(values.email);
    },
  });
  document.getElementById("root").classList = "hold-transition login-page";

  return (
    <div className="login-box">
      <div className="card card-outline card-primary">
        <div className="card-header text-center">
          <Link to="/" className="h1">
            <img src={logo} alt="Logo" className="w-100" />
          </Link>
        </div>
        <div className="card-body">
          <p className="login-box-msg">{t("recover.forgotYourPassword")}</p>
          <form onSubmit={formik.handleSubmit}>
            <div className="mb-3">
              <div className="input-group">
                <input
                  type="email"
                  className="form-control"
                  placeholder={t("login.placeholder.email")}
                  {...formik.getFieldProps("email")}
                />
                <div className="input-group-append">
                  <div className="input-group-text">
                    <span className="fas fa-envelope" />
                  </div>
                </div>
              </div>
              {printFormError(formik, "email")}
            </div>
            <div className="row">
              <div className="col-12">
                <Button block type="submit" isLoading={isAuthLoading}>
                  {t("recover.requestNewPassword")}
                </Button>
              </div>
            </div>
          </form>
          <p className="mt-3 mb-1">
            <Link to="/login">{t("login.button.signIn.label")}</Link>
          </p>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  const { isAuthLoading } = state.auth;
  return {
    isAuthLoading,
  };
};
const mapDispatchToProps = (dispatch) => ({
  forgotPass: (email) => dispatch(forgotPass(email)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);
