import React from "react";
import { Redirect, Route } from "react-router-dom";
import { connect } from "react-redux";
//import routes from "./routes";
//import { useTranslation } from "react-i18next";

const PrivateRoute = ({
  component: Component,
  roles,
  isLoggedIn,
  currentUser,
  ...rest
}) => {
  //const { t } = useTranslation();
  return (
    <Route
      {...rest}
      render={(props) => {
        // TODO Breadcrumbs
        /** 
          const crumbs = routes
            // Get all routes that contain the current one.
            .filter(({ path }) => props.match.path.includes(path))
            // Swap out any dynamic routes with their param values.
            // E.g. "/pizza/:pizzaId" will become "/pizza/1"
            .map(({ path, ...rest }) => ({
              path: Object.keys(props.match.params).length
                ? Object.keys(props.match.params).reduce(
                    (path, param) =>
                      path.replace(`:${param}`, props.match.params[param]),
                    path
                  )
                : path,
              ...rest,
            }));
          console.log(`Generated crumbs for ${props.match.path}`);
          crumbs.map(({ name, path }) => console.log({ name: t(name), path }));
        */
        if (!isLoggedIn) {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: { from: props.location },
              }}
            />
          );
        }
        if (roles && !roles.includes(currentUser.role)) {
          return <Redirect to="/404" />;
        }
        return <Component {...props} />;
      }}
    />
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  currentUser: state.auth.currentUser,
});

export default connect(mapStateToProps)(PrivateRoute);
