import React, { memo } from "react";
import { useTranslation } from "react-i18next";

const Answers = ({ userAnswers, isActive }) => {
  const [t, i18n] = useTranslation();
  const lang = i18n.language;

  const answersList = userAnswers
    ? Object.keys(userAnswers).map((key) => {
        const userAnswer = userAnswers[key];
        return (
          <tr key={key}>
            <td>
              <span>{userAnswer.id}</span>
            </td>
            <td>
              <span>{userAnswer.text[lang] || ""}</span>
            </td>
            <td>
              {userAnswer.user_answers.map((answers, index) => {
                return (
                  <span className="align-text-top" key={index}>
                    {userAnswer.type === "text"
                      ? answers.text
                      : answers.answer?.text[lang] || ""}
                    {userAnswer.user_answers.length > 1 ? "," : ""}
                    <br />
                  </span>
                );
              })}
            </td>
          </tr>
        );
      })
    : null;

  return (
    <div className={`tab-pane ${isActive ? "active" : ""}`}>
      <div className="card">
        <div className="card-body table-responsive p-0">
          <table className="table table-striped table-valign-middle table-sm">
            <thead>
              <tr>
                <th>ID</th>
                <th>{t("questions.question")}</th>
                <th>{t("questions.answer")}</th>
              </tr>
            </thead>
            <tbody>{answersList}</tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default memo(Answers);
