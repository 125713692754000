import React, { memo } from "react";
import ReactApexChart from "react-apexcharts";
import styles from "./index.module.css";
const ApexChartSpark = (props) => {
  const { sparklineData, labels, darkMode, colors, total, subtitle } = props;
  const settings = {
    series: [
      {
        name: "",
        data: sparklineData,
      },
    ],
    options: {
      theme: {
        mode: darkMode ? "dark" : "light",
        palette: "palette1",
        monochrome: {
          enabled: false,
          color: "#255aee",
          shadeTo: darkMode ? "dark" : "light",
          shadeIntensity: 0.65,
        },
      },
      chart: {
        type: "area",
        height: 160,
        sparkline: {
          enabled: true,
        },
      },
      stroke: {
        curve: "smooth",
      },
      fill: {
        opacity: 0.3,
      },
      labels: labels,
      yaxis: {
        show: false,
        min: 0,
        labels: {
          /**
           * Allows users to apply a custom formatter function to yaxis labels.
           *
           * @param { String } value - The generated value of the y-axis tick
           * @param { index } index of the tick / currently executing iteration in yaxis labels array
           */
          formatter: function (val, index) {
            return val.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          },
        },
      },
      colors: colors,
      title: {
        text: total,
        offsetX: 0,
        style: {
          fontSize: "24px",
        },
      },
      subtitle: {
        text: subtitle,
        offsetX: 0,
        style: {
          fontSize: "14px",
        },
      },
      grid: {
        padding: {
          top: 0,
          right: 2,
          bottom: 0,
          left: 0,
        },
      },
      responsive: [
        {
          breakpoint: 1200,
          options: {
            title: {
              style: {
                fontSize: "20px",
              },
            },
          },
        },
      ],
    },
  };
  return (
    <div className="col-md-6 col-12 col-xs-12 col-xl-3 col-lg-6">
      <div className={styles.box}>
        <div id="chart-spark1">
          <ReactApexChart
            options={settings.options}
            series={settings.series}
            type="area"
            height={160}
          />
        </div>
      </div>
    </div>
  );
};

export default memo(ApexChartSpark);
