import React, { memo } from "react";
import ReactApexChart from "react-apexcharts";
import Spinner from "../spinner";

const LineChart = (props) => {
  const { series, categories, darkMode, loading = false } = props;
  const settings = {
    series,
    options: {
      chart: {
        height: 500,
        type: "area",
      },
      theme: {
        mode: darkMode ? "dark" : "light",
        palette: "palette1",
        monochrome: {
          enabled: false,
          color: "#255aee",
          shadeTo: darkMode ? "dark" : "light",
          shadeIntensity: 0.65,
        },
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "date",
        categories: Array.from(categories),
      },

      yaxis: {
        tickAmount: 5,
        labels: {
          /**
           * Allows users to apply a custom formatter function to yaxis labels.
           *
           * @param { String } value - The generated value of the y-axis tick
           * @param { index } index of the tick / currently executing iteration in yaxis labels array
           */
          formatter: function (val) {
            //if (props.currency === 'usd') {
            //val = val / 75;
            // }
            return isFinite(val)
              ? val.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              : val;
          },
        },
      },
      noData: {
        text: loading ? "Loading..." : "",
      },
      tooltip: {
        style: {
          zIndex: "9999999999999999999",
        },
        x: {
          format: "dd/MM/yy",
        },
      },
    },
  };
  return (
    <div className="chart-line">
      {loading && <Spinner />}

      <ReactApexChart
        options={settings.options}
        series={settings.series}
        type="area"
        height={500}
      />
    </div>
  );
};
export default memo(LineChart);
